import React from "react"
import { Tooltip } from "antd"
import { TruncateText, defaultSort, urlToSchool } from "../../../utilities"

export const columnsGaugeStudentSel = [
  {
    title: "School Name",
    dataIndex: "program_matrix",
    render: (text, _) => (
      <Tooltip
        style={{ borderRadius: "8px" }}
        placement="top"
        title={text.name}
      >
        <a
          className="styling-name-matrix"
          onClick={() => {
            urlToSchool(_.program_matrix.school_id)
          }}
        >
          {TruncateText(text.name, 11)}
        </a>
      </Tooltip>
    ),
    width: 50,
    sorter: {
      compare: (a, b) => defaultSort(a.program_matrix.name, b.program_matrix.name),
    },
  },
  {
    title: "Extracurricular Activities",
    dataIndex: "matrix_hash",
    render: (hash) => (
      <a>{hash.activity_counts["Extracurricular Activities"]}</a>
    ),
    width: 90,
  },
  {
    title: "Social Emotional Supports",
    dataIndex: "matrix_hash",
    render: (hash) => (
      <a>{hash.activity_counts["Social Emotional Supports"]}</a>
    ),
    width: 90,
  },
  {
    title: "Instructional Practices",
    dataIndex: "matrix_hash",
    render: (hash) => <a>{hash.activity_counts["Instructional Practices"]}</a>,
    width: 80,
  },
  {
    title: "Behavior Supports",
    dataIndex: "matrix_hash",
    render: (hash) => <a>{hash.activity_counts["Behavior Supports"]}</a>,
    width: 80,
  },
  {
    title: "Adult SEL",
    dataIndex: "matrix_hash",
    render: (hash) => <a>{hash.activity_counts["Adult SEL"]}</a>,
    width: 40,
  },
]

export const columnsGaugeAdultSel = [
  {
    title: "School Name",
    dataIndex: "program_matrix",
    render: (text, _) => (
      <Tooltip
        style={{ borderRadius: "8px" }}
        placement="top"
        title={text.name}
      >
        <a
          className="styling-name-matrix"
          onClick={() => {
            urlToSchool(_.program_matrix.school_id)
          }}
        >
          {TruncateText(text.name, 11)}
        </a>
      </Tooltip>
    ),
    width: 70,
    sorter: {
      compare: (a, b) => defaultSort(a.program_matrix.name, b.program_matrix.name),
    },
  },
  {
    title: "Adult SEL",
    dataIndex: "matrix_hash",
    render: (value) => <a>{value.activity_counts["Adult SEL"]}</a>,
    width: 70,
  },
]
