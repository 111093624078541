const CommonColors = {
  UA_TEAL: "#3CDCDC",
  UA_RED: "#A6192E",
  UA_BOLD_BLACK: "#565656",
  UA_GREY: "#e5e5e5",
  UA_WHITE: "#d9d9d9",
  UA_BLUE_DARK: "#3e4d60",
  UA_GRAPH_BACKGROUND: "#0ca4a5",
}

export default CommonColors
