import React from "react"
import _ from "underscore"
import pluralize from "pluralize"
// import BarChart from "../charts/BarChart"
import { infoAlert } from "../../utilities"
// import { uaGray, uaTeal } from "../../enums"
import BarChart from "../charts/OldBarChart"
// import BarChart from "../charts/BarChart"

function ImplementationReport({ rubric }) {
  const generatePDF = () => {
    window.open(`/implementation_rubrics/${rubric.id}/report.pdf`)
  }

  const labels = _.map(rubric.report, (stage) => stage.label)
  const submissionDates = rubric.chart_data.map((d) => Object.keys(d)[0])
  const hasDupes = submissionDates.length > 1
    && _.uniq(submissionDates).length !== submissionDates.length
  const datasets = _.map(rubric.chart_data, (data, index) => {
    const label = hasDupes
      ? `${submissionDates[index]} #${index + 1}`
      : submissionDates[index]
    return {
      label,
      data: _.values(_.values(data)[0]),
      backgroundColor:
        rubric.chart_data.length === 1 || index === 1
          ? ["#A6192E", "#33475C", "#E8A923", "#0CA6A6", "#7D7B7C"]
          : `rgba(${uaGray}, 0.2)`,
      borderColor:
        rubric.chart_data.length === 1 || index === 1
          ? ["#A6192E", "#33475C", "#E8A923", "#0CA6A6", "#7D7B7C"]
          : "rgba(184, 184, 184, 1)",
      borderWidth: 1,
    }
  })

  const overallBarClickText = () => {
    const score = rubric.report.find(
      (stage) => stage.label === "Overall",
    ).completion
    return score >= 90
      ? "Congratulations, you met the 90% goal!"
      : `You’re only ${90 - score} ${pluralize(
        "points",
        90 - score,
      )} away from the 90% goal!`
  }

  const onBarChartClick = (barIndex) => {
    if (barIndex === 4) {
      infoAlert({ title: overallBarClickText() })
    } else {
      const element = document.getElementById(`report-section-${barIndex}`)
      if (element) {
        element.scrollIntoView()
      }
    }
  }

  return (
    <div>
      <i className="fa fa-file-pdf-o" onClick={generatePDF} />
      <div className="report p-4 mb-4">
        <BarChart
          title="Program Implementation"
          labels={labels}
          datasets={datasets}
          max={100}
          mb={5}
          onClick={onBarChartClick}
          formatPercentage
        />
        {_.first(rubric.report, 4).map((stage, index) => (
          <div
            key={index}
            className="narrative mb-5"
            id={`report-section-${index}`}
          >
            <h4 className="mb-1">
              {stage.label} ({stage.completion}%)
            </h4>
            <h5 className="text-uppercase">{stage.rank}</h5>
            <p>{stage.narrative}</p>

            {stage.areas_of_strength.length > 0 && (
              <div>
                <h5>
                  <i>Areas of strength:</i>
                </h5>
                <ul>
                  {stage.areas_of_strength.map((area, i) => (
                    <li key={i} dangerouslySetInnerHTML={area} />
                  ))}
                </ul>
              </div>
            )}

            {stage.areas_of_need.length > 0 && (
              <div>
                <h5>
                  <i>Areas of need:</i>
                </h5>
                <ul>
                  {stage.areas_of_need.map((area, i) => (
                    <li key={i} dangerouslySetInnerHTML={area} />
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImplementationReport
