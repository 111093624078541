import React, { useState } from "react"
import { Row, Col } from "antd"
import _ from "underscore"
import commonStrings from "../../commonStrings"

function ProgramMatrixButtons({
  Next,
  handleNextStep,
  handlePrevStep,
  step,
  form,
  submitClicked,
  fieldsValues,
  setIsButtonSubmitted,
  setFieldsValues,
}) {
  const urlParams = new URLSearchParams(window.location.search)
  const [myParam] = useState(urlParams.get("activityId"))

  const validationFunction = () => {
    if (myParam) {
      APIClient.get(
        `/program_matrix/program_matrix_items/${myParam}`,
        (response) => {
          setFieldsValues(response)
          if (response && response.category === "Adult SEL") {
            handleNextStep()
          } else if (
            response
            && !_.isNull(response.students_being_supported)
            && response.students_being_supported !== 0
            && !_.isNull(response.students_can_supported)
            && response.students_can_supported !== 0
          ) {
            handleNextStep()
          } else {
            setIsButtonSubmitted(true)
          }
        },
      )
    }
  }
  const goBack = () => {
    handlePrevStep()
  }

  const goNext = () => {
    form.validateFields().then(() => {
      if (fieldsValues.tier_of_support === "Universal") {
        handleNextStep()
      } else {
        validationFunction()
      }
    })
  }
  return (
    <>
      <Row justify="center" align="center">
        <Col xs={20} sm={20} md={10} lg={10}>
          <Row align="center" justify="center">
            <span>Page {step} of 2</span>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between">
        {step > 1 && (
          <button
            className="btn btn-outline-secondary ml-2 btnWidth"
            onClick={() => goBack()}
          >
            {commonStrings.back}
          </button>
        )}
        <div className="buttons">
          {step === 2 && (
            <button
              className="btn btn-outline-secondary ml-2 btnWidth"
              onClick={submitClicked}
            >
              {commonStrings.finish}
            </button>
          )}
        </div>
      </Row>
      <Row justify="end">
        {Next && step === 1 && (
          <button
            className="btn btn-outline-secondary ml-2 btnWidth"
            onClick={() => goNext()}
          >
            {commonStrings.next}
          </button>
        )}
      </Row>
    </>
  )
}

export default ProgramMatrixButtons
