import React from "react"
import { Table, Card } from "antd"
import { CheckCircleOutlined, ExclamationOutlined } from "@ant-design/icons"
import { formatDate } from "../../utilities"
import commonStrings from "../../commonStrings"

const RubricsListing = ({ rubricsList, rubricType }) => {
  const tableColumns = [
    {
      title: "Report",
      dataIndex: "id",
      render: (id, _, index) => (
        <div className="implAnchor">
          <a href={id}>{`${rubricType} Rubric #${index + 1}`}</a>
        </div>
      ),
      width: 120,
      align: "center",
    },
    {
      title: "Date Last Updated",
      dataIndex: "updated_at",

      render: (timestamp, record) => (
        <div>
          <span className="rubricListing">
            {record.created_at === record.updated_at
              ? "N/A"
              : record.workflow_state === "draft"
                ? formatDate(record.updated_at)
                : formatDate(timestamp)}
          </span>
        </div>
      ),
      width: 120,
      align: "center",
    },
    {
      title: "Report Status",
      dataIndex: "workflow_state",
      render: (state) => (
        <div className="d-flex justify-content-center">
          {state === "draft" ? (
            <>
              <div className="width35 rubricListing">
                {commonStrings.notSubmitted}
              </div>
              <div className="mx-2 d-flex align-items-center">
                <ExclamationOutlined
                  style={{ fontSize: "17px", color: "red" }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="width35 rubricListing">
                {commonStrings.submitted}
              </div>
              <div className="mx-2 d-flex align-items-center">
                <CheckCircleOutlined
                  style={{ fontSize: "17px", color: "green" }}
                />
              </div>
            </>
          )}
        </div>
      ),
      width: 120,
      align: "center",
      cursor: "default",
    },
  ]
  return (
    <div className="submitted-rubrics mt-4">
      <Card>
        <Table
          className="table-design table-striped-rows drawer-table"
          columns={tableColumns}
          dataSource={rubricsList}
          size="middle"
          scroll={{ y: 530 }}
          bordered={false}
          pagination={false}
        />
      </Card>
    </div>
  )
}

export default RubricsListing
