import React, { useState } from "react"
import _ from "underscore"
import { useMount } from "ahooks"
import ImplementationPlan from "./Plan"
import ImplementationReport from "./Report"
import ImplementationRubricTabs from "./Tabs"
import RubricHeader from "../rubrics/Header"
import ImplementationRubricStage from "./Stage"
import RubricFooterButtons from "../rubrics/FooterButtons"

function ImplementationRubric({ rubric }) {
  const [step, setStep] = useState(rubric.workflow_state === "submitted" ? 5 : 1)

  useMount(() => {
    $("[data-toggle=\"tooltip\"]").tooltip({ container: "body", html: true })
  })

  const indicatorsFor = (stage) => _.filter(rubric.indicators, (indicator) => indicator.stage === stage)

  const tabContent = () => (
    <div className="tab-content">
      {rubric.template.stages.map((stage, index) => (
        <div key={index} className={`tab-pane initial ${step === (index + 1) ? "active" : null}`}>
          <ImplementationRubricStage stage={stage} firstStage={index === 0} indicators={indicatorsFor(stage.name)} disabled={rubric.workflow_state === "submitted"} />
        </div>
      ))}
      {rubric.workflow_state === "submitted"
        && (
        <div className={`tab-pane ${step === 5 ? "active" : null}`} id="report">
          <ImplementationReport rubric={rubric} />
        </div>
        )}
      {rubric.workflow_state === "submitted"
        && (
        <div className={`tab-pane ${step === 6 ? "active" : null}`} id="plan">
          <ImplementationPlan rubric={rubric} />
        </div>
        )}
    </div>
  )

  return (
    <div>
      <RubricHeader
        rubric={rubric}
        type="Implementation"
        instructions="Directions: Read each indicator and determine at what level your community has a plan for that indicator AND has executed that plan. Use your reflections from the Matrix to inform your answers. Update the rubric and click “Generate Report” at the beginning, middle, and end of the year as your plan is executed. Keep updating the same rubric until it is time to plan for the next year, then “Create New Implementation Rubric”."
      />
      <div className={`rubric implementation ${rubric.workflow_state}`}>
        <ImplementationRubricTabs rubric={rubric} step={step} setStep={setStep} />
        {tabContent()}
        <RubricFooterButtons
          rubric={rubric}
          type="Implementation"
        />
      </div>
    </div>
  )
}

export default ImplementationRubric
