import React from "react"
import _ from "underscore"
import moment from "moment"
import MetricChart from "./MetricChart"
import { areYouSure } from "../utilities"

export default class Metric extends React.Component {
  static withBlankValue(metric) {
    if (metric.metric_values.length === 0 || !_.isUndefined(_.last(metric.metric_values).id)) {
      metric.metric_values.push({ date: "", value: "", editing: true })
    }
    return metric
  }

  constructor(props) {
    super(props)
    this.state = { metric: Metric.withBlankValue(props.metric) }
  }

  handleOnChange(index, e) {
    const { metric_values: metricValues } = this.state.metric
    metricValues[index][e.target.name] = e.target.value
    this.setState({ metricValues })
  }

  loadMetric() {
    APIClient.get(`/metrics/${this.props.metric.id}`, (response) => {
      this.setState({ metric: Metric.withBlankValue(response) })
    })
  }

  destroyMetric(e) {
    e.preventDefault()
    areYouSure("warning", () => {
      APIClient.delete(`/metrics/${this.props.metric.id}`, () => {
        window.location.href = "/metrics"
      })
    })
  }

  editMetricValue(index, value) {
    const newState = this.state
    if (value) {
      const oldValue = _.clone(this.state.metric.metric_values[index])
      newState.metric.metric_values[index].editing = value
      newState[`oldValue${index}`] = oldValue
    } else {
      newState.metric.metric_values[index] = newState[`oldValue${index}`]
    }
    this.setState(newState)
  }

  saveMetricValue(index, e) {
    e.preventDefault()
    const metricValue = this.state.metric.metric_values[index]

    if (metricValue.date === "" || metricValue.value === "") {
      return
    }

    if (metricValue.id) {
      APIClient.put(`/metrics/${this.props.metric.id}/metric_values/${metricValue.id}`, {
        date: metricValue.date,
        value: metricValue.value,
      }, () => {
        this.loadMetric()
      })
    } else {
      APIClient.post(
        `/metrics/${this.props.metric.id}/metric_values`,
        { metric_value: metricValue },
        () => {
          this.loadMetric()
        },
      )
    }
  }

  destroyMetricValue(id, e) {
    e.preventDefault()
    areYouSure("warning", () => {
      APIClient.delete(`/metrics/${this.props.metric.id}/metric_values/${id}`, () => {
        this.loadMetric()
      })
    })
  }

  renderDataTable() {
    const { metric_values: metricValues } = this.state.metric
    return (
      <table className="table table-striped table-sm metric-values mb-5">
        <tbody>
          {metricValues.map((metricValue, index) => (
            <tr key={index}>
              <td>
                {!metricValue.editing && moment(metricValue.date).format("MMM D, YYYY")}
                {metricValue.editing
                && (
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date"
                  name="date"
                  value={metricValue.date}
                  onChange={this.handleOnChange.bind(this, index)}
                />
                )}
              </td>
              <td>
                {!metricValue.editing && metricValue.value}
                {metricValue.editing
                && (
                <input
                  type="number"
                  className="form-control"
                  placeholder="Value"
                  name="value"
                  value={metricValue.value}
                  onChange={this.handleOnChange.bind(this, index)}
                />
                )}
              </td>
              <td className="text-right">
                {metricValue.editing
                && (
                <span>
                  {metricValue.id
                  && (
                  <button
                    className="btn btn-default btn-sm mr-2"
                    onClick={this.editMetricValue.bind(this, index, false)}
                  >
                    Cancel
                  </button>
                  )}
                  <button
                    className="btn btn-primary btn-sm mr-2"
                    onClick={this.saveMetricValue.bind(this, index)}
                  >Save
                  </button>
                </span>
                )}
                {!metricValue.editing
                && (
                <span>
                  <i
                    className="fa fa-pencil mr-2"
                    onClick={this.editMetricValue.bind(this, index, true)}
                  />
                  <i
                    className="fa fa-trash-o mr-2"
                    onClick={this.destroyMetricValue.bind(this, metricValue.id)}
                  />
                </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  render() {
    const { metric } = this.state
    return (
      <div>
        <div className="mb-3">
          <MetricChart metric={metric} showTitle={false} />
        </div>
        {this.renderDataTable()}
        <div className="mb-4 text-right">
          <button className="btn btn-danger btn-sm" onClick={this.destroyMetric.bind(this)}>
            <i className="fa fa-trash-o mr-1" /> Delete Metric
          </button>
        </div>
      </div>
    )
  }
}
