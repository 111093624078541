import React from "react"
import commonStrings from "../../commonStrings"

function TypeToggle({ type, setType }) {
  const isAdult = type === "adult"

  const toggle = () => {
    setType(isAdult ? "student" : "adult")
  }
  const sliderStyle = isAdult
    ? { backgroundColor: "#33485D" }
    : { backgroundColor: "#0CA4A5" }
  const adultLabelStyle = isAdult ? { color: "#33485D" } : {}

  return (
    <div className="text-center">
      <span className={`label ${!isAdult ? "active-label" : ""}`}>
        {commonStrings.studentSELQuality}
      </span>
      <div className="toggle-switch" onClick={toggle}>
        <div
          className={`slider ${isAdult ? "active" : ""}`}
          style={sliderStyle}
        />
      </div>
      <span
        className={`label ${isAdult ? "active-label" : ""}`}
        style={adultLabelStyle}
      >
        {commonStrings.adultSELQuality}
      </span>
    </div>
  )
}

export default TypeToggle
