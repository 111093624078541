import React, { useState, useEffect } from "react"
import { Row, Col, Space } from "antd"
// import { template } from "underscore"
import commonStrings from "../../commonStrings"
import RubricHeaderButtons from "../rubrics/HeaderButtons"
import RubricFooterButtons from "../rubrics/FooterButtons"
import SustainabilityReport from "./Report"
import NestedCollapsibleforRubric from "./NestedCollapsibleforRubric"
import RubricsListing from "../rubrics/RubricsListing"
import { sustainabilityMainHeaders } from "./Components/SustainabilityObjects"
import { formatTimestamp } from "../../utilities"
import Loader from "../CommonComponents/Loader"

const SustainabilityRubric = ({
  rubric,
  canCreate,
  rubricsList,
  currentSchool,
  rubricsAssociatedSchool,
  currentSchoolLists,
}) => {
  const [dropDownIconRotate, setDropDownIconRotate] = useState(false)
  const [showListingSubmitted, setListingSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const reportDate = new Date(rubric.updated_at).toLocaleDateString("en-US")

  function processObjects(objectsArray, listSchool) {
    const currentUrl = window.location.href

    if (currentUrl.includes("/sustainability_rubrics/")) {
      const urlParts = currentUrl.split("/sustainability_rubrics/")
      const idFromUrl = parseInt(urlParts[1], 10)
      const matchingObject = objectsArray.find((obj) => obj.id === idFromUrl)
      const SchoolbyId = matchingObject.school_id
      const matchingSchool = listSchool.find((obj) => obj.id === SchoolbyId)

      if (!matchingSchool) {
        window.location.href = "/"
      } else {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    processObjects(rubricsAssociatedSchool, currentSchoolLists)
  }, [rubricsAssociatedSchool, currentSchoolLists])
  return isLoading ? (
    <Loader />
  ) : (
    <Row style={{ fontFamily: "Archivo Narrow" }}>
      <Row className="width50">
        <Col xs={24} sm={24} md={15} lg={15} className="px-1">
          <span className="font-weight-bold heading-color heading-size">
            {showListingSubmitted
              ? "Sustainability Rubrics"
              : `Sustainability Rubric #${rubric.number}`}
          </span>
        </Col>

        {!showListingSubmitted && (
          <Col className="status-block" xs={24} sm={24} md={4} lg={4}>
            <span className="status-color">{rubric.workflow_state}</span>
          </Col>
        )}
      </Row>

      <Row className="width50">
        <Col xs={24} sm={24} md={24} lg={24}>
          <RubricHeaderButtons
            canCreate={canCreate}
            rubric={rubric}
            type="sustainability"
            setListingSubmitted={setListingSubmitted}
            dropDownIconRotate={dropDownIconRotate}
            setDropDownIconRotate={setDropDownIconRotate}
            showListingSubmitted={showListingSubmitted}
          />
        </Col>
      </Row>
      <Row>
        {!showListingSubmitted && (
          <Col xs={24} sm={24} md={24} lg={24} className="pt-4 pb-2">
            {rubric.workflow_state === "draft" && (
              <span className="paragraph">
                {commonStrings.sustainabilityRubric}
              </span>
            )}
          </Col>
        )}
      </Row>
      {showListingSubmitted && (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} className="mt-4">
            <RubricsListing
              rubricsList={rubricsList}
              rubricType="Sustainability"
            />
          </Col>
        </Row>
      )}
      {rubric.workflow_state === "draft" && !showListingSubmitted && (
        <Col xs={24} sm={24} md={24} lg={24} className="mb-4 mt-4">
          <Space direction="vertical" size={20} style={{ display: "flex" }}>
            {sustainabilityMainHeaders.map((props, index) => (
              <NestedCollapsibleforRubric
                title={props.title}
                description={props.description}
                color={props.color}
                rubric={rubric}
                parentIndex={index}
              />
            ))}
          </Space>
        </Col>
      )}

      {rubric.workflow_state === "submitted" && !showListingSubmitted && (
        <SustainabilityReport
          rubric={rubric}
          currentSchoolLogo={rubric.logo_url}
          currentSchoolName={currentSchool.name}
          dateForReport={formatTimestamp(rubric.updated_at)}
          rubricNumberDate={`Sustainability Rubric Report #${rubric.number} (${reportDate})`}
        />
      )}
      <Row style={{ width: "100%" }}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <RubricFooterButtons
            rubric={rubric}
            type="sustainability"
            showListingSubmitted={showListingSubmitted}
          />
        </Col>
      </Row>
    </Row>
  )
}

export default SustainabilityRubric
