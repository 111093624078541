import React from "react"

function BackToTop() {
  return (
    <button
      className="btn btn-link"
      onClick={() => window.scrollTo(0, 0)}
    >Back to Top
      <i className="fa fa-arrow-up ml-2" />
    </button>
  )
}

export default BackToTop
