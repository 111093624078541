import React from "react"
import CommonColors from "../../commonColors"

const Loader = (props) => {
  const { generalColor, size } = props

  return (
    <div className="text-center">
      <div
        style={{ color: generalColor || CommonColors.UA_TEAL, width: size, height: size }}
        className="spinner-border"
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default Loader
