export const sustainabilityIndicators = {
  not_started: 0,
  initiating: 0,
  developing: 0,
  proficient: 0,
  well_developed: 0,
}

export const implementationIndicators = {
  Commitment_Stage_I: 0,
  Commitment_Stage_II: 0,
  Implementation_Stage_I: 0,
  Implementation_Stage_II: 0,
}

export const scoreMap = {
  0: "initiating",
  33: "developing",
  66: "proficient",
  100: "well_developed",
}
