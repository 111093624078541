import React from "react"
import { Steps, Space } from "antd"
import _ from "underscore"
import { PDFDownloadLink } from "@react-pdf/renderer"
import commonStrings from "../../commonStrings"
import pdfSvg from "../../../assets/images/pdf-file.svg"
import Loader from "../CommonComponents/Loader"
import SustainabilityReportpdf from "./SustainabilityReportpdf"
import { sustainabilityMainHeaders } from "./Components/SustainabilityObjects"
import NestedCollapsibleforReport from "./NestedCollapsibleforReport"

const { Step } = Steps
const customDot = () => <div className="custom-dot " />
function gradedAreasCount(report) {
  const count = _.filter(report, (area) => area.rating).length
  return (
    <div>
      <span>
        {count} of {report.length} areas graded
      </span>
    </div>
  )
}
function PdfReport({
  rubric,
  currentSchoolName,
  dateForReport,
  currentSchoolLogo,
  rubricNumberDate,
}) {
  return (
    <div className="pdf-icon-container">
      <PDFDownloadLink
        document={(
          <SustainabilityReportpdf
            rubric={rubric}
            currentSchoolLogo={currentSchoolLogo}
            rubricNumberDate={rubricNumberDate}
          />
        )}
        fileName={`${
          currentSchoolName || "school_name"
        }_report(${dateForReport})`}
      >
        {({ loading }) => (loading ? <Loader /> : <img style={{ width: "40px" }} src={pdfSvg} />)}
      </PDFDownloadLink>
    </div>
  )
}
function SustainabilityReport({
  rubric,
  currentSchoolLogo,
  currentSchoolName,
  dateForReport,
  rubricNumberDate,
}) {
  const stepTitles = ["INITIATING", "DEVELOPING", "PROFICIENT", "WELL DEVELOPED"]

  const determineCurrentStep = (rating) => {
    if (!_.isNull(rating)) {
      const ratingMap = {
        initiating: 0,
        developing: 1,
        proficient: 2,
        "well developed": 3,
      }

      return ratingMap[rating.toLowerCase()] || 0
    }
    return null
  }

  const currentStep = determineCurrentStep(rubric.rating)
  const ratingClassName = _.isNull(rubric.rating)
    ? ""
    : rubric.rating.toLowerCase().replace(" ", "-")

  const style = {
    display: "flex",
    alignItems: "baseline",
  }
  const Multistepper = {
    display: "flex",
    justifyContent: "space-evenly",
    marginLeft: "20px",
  }
  const overAllRatingStyles = {
    fontSize: "24px",
  }
  const gradedUngradedMarks = {
    fontSize: "21px",
    color: "#33485D",
    border: "3px solid #33485D",
  }
  return (
    <div className="main">
      <div className="main-title">
        <span> {commonStrings.newSustainabilityReportContent}</span>
      </div>
      <div className="sustainabilityDownload mb-4">
        <div className="overall-score" style={style}>
          <span style={overAllRatingStyles}> Overall Score:</span>
          <div className="rating" style={style}>
            <span
              style={gradedUngradedMarks}
              className={`rating-element ${ratingClassName} `}
            >
              {_.isNull(rubric.rating) ? "Ungraded" : rubric.rating}
            </span>
            <div className="graded-rating" style={style}>
              <span style={style} className="graded outOfRating">
                {gradedAreasCount(rubric.report)}
              </span>
            </div>
          </div>
        </div>
        <div>
          <PdfReport
            rubric={rubric}
            currentSchoolName={currentSchoolName}
            dateForReport={dateForReport}
            currentSchoolLogo={currentSchoolLogo}
            rubricNumberDate={rubricNumberDate}
          />
        </div>
      </div>

      {!_.isNull(currentStep) && (
        <div className="stepper-container">
          <div className="stepper-titles" style={style}>
            {stepTitles.map((title, index) => (
              <div
                span={title && title.toLowerCase() === "well developed" ? 4 : 3}
                style={{
                  ...Multistepper,
                  fontSize:
                    title && title.toLowerCase() === "well developed"
                      ? "16px"
                      : "18px",
                }}
                className={`step-title ${index <= currentStep ? "active" : ""}`}
                key={index}
              >
                {title}
              </div>
            ))}
          </div>

          <div className="Multi-stepper">
            <div>
              <Steps current={currentStep} progressDot={customDot}>
                {stepTitles.map((stepIndex) => (
                  <div>
                    <Step key={stepIndex} />
                  </div>
                ))}
              </Steps>
            </div>
          </div>
        </div>
      )}

      <Space direction="vertical" size={20} style={{ display: "flex" }}>
        {sustainabilityMainHeaders.map((props, index) => (
          <NestedCollapsibleforReport
            title={props.title}
            description={props.description}
            color={props.color}
            rubric={rubric}
            parentIndex={index}
          />
        ))}
      </Space>
    </div>
  )
}
export default SustainabilityReport
