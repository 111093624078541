import React, { useEffect, useState } from "react"
import {
  Col, Row, Form, Alert,
} from "antd"
import _ from "underscore"
import Strings from "../../commonStrings"
import rules from "../../validations"
import FormItemSelectCell from "./FormItemSelectCell"
import FormItemInputCell from "./FormItemInputCellforActivity"
import FormItemInputCellNoCard from "./FormItemInputCellNoCard"

function ProgramMatrixTabs({
  category,
  routeURL,
  setCategory,
  handleOnChange,
  handleOnChangeSelect,
  form,
  item,
  activityMode,
  saveItem,
  studentPopulation,
  updateStudentsCount,

  fieldsValues,
  handleGradeLevelsChange,
  matrix,
  setItem,
  selectedGradeLevels,
  setLoading,
}) {
  const [canBeSupportedError, setCanBeSupportedError] = useState({
    visible: false,
    grade: "",
    message: "",
    opacity: 1,
  })

  const [beingSupportedError, setBeingSupportedError] = useState({
    visible: false,
    grade: "",
    message: "",
    opacity: 1,
  })

  // const [api, contextHolder] = notification.useNotification()

  // const [InternalSelectedGrades, setInternalSelectedGrades] = useState([]);
  const initialGrades = (Array.isArray(item.internal_selected_grades)
    ? item.internal_selected_grades.length > 0
    : false) || item.is_user_edited
    ? item.internal_selected_grades || []
    : matrix.selected_grades || []

  const [InternalSelectedGrades, setInternalSelectedGrades] = useState(initialGrades)

  const initializeGrades = () => {
    const existingGrades = Array.isArray(item.internal_selected_grades)
      ? item.internal_selected_grades
      : matrix.selected_grades || []

    const existingGradesMap = new Map(existingGrades.map((g) => [g.grade, g]))

    const initializedGrades = Array.isArray(matrix.selected_grades)
      ? matrix.selected_grades.map((gradeObj) => {
        const existingGrade = existingGradesMap.get(gradeObj.grade)

        return {
          grade: gradeObj.grade,
          students_can_be_supported:
              existingGrade && existingGrade.students_can_be_supported
                ? existingGrade.students_can_be_supported
                : null,
          students_being_supported:
              existingGrade && existingGrade.students_being_supported
                ? existingGrade.students_being_supported
                : null,
        }
      })
      : []

    setInternalSelectedGrades(initializedGrades)
  }

  useEffect(() => {
    if (item && matrix) {
      initializeGrades()
    }
  }, [
    item.internal_selected_grades,
    matrix.selected_grades,
    item.tier_of_support,
    item,
    matrix,
  ])

  const showApiError = (grade, title, message, errorType) => {
    if (errorType === "canBeSupported") {
      setCanBeSupportedError({
        visible: true,
        grade,
        message,
        opacity: 1,
      })
    } else if (errorType === "beingSupported") {
      setBeingSupportedError({
        visible: true,
        grade,
        message,
        opacity: 1,
      })
    }
  }

  const initializeGradesforother = () => {
    const sourceGrades = (Array.isArray(item.internal_selected_grades)
      ? item.internal_selected_grades.length > 0
      : false) || item.is_user_edited
      ? item.internal_selected_grades
      : matrix.selected_grades

    if (
      !(
        (Array.isArray(item.internal_selected_grades)
          ? item.internal_selected_grades.length > 0
          : false) || item.is_user_edited
      )
      && Array.isArray(item.internal_selected_grades)
      && item.internal_selected_grades.length === 0
    ) {
      const updatedGrades = sourceGrades.map(
        ({ student_population: _, ...gradeObj }) => ({
          ...gradeObj,
          students_can_be_supported: null,
          students_being_supported: null,
        }),
      )

      setItem((prevItem) => ({
        ...prevItem,
        internal_selected_grades: updatedGrades,
      }))

      setInternalSelectedGrades(updatedGrades)
    }
  }

  useEffect(() => {
    if (
      !(
        (Array.isArray(item.internal_selected_grades)
          ? item.internal_selected_grades.length > 0
          : false) || item.is_user_edited
      )
      && Array.isArray(item.internal_selected_grades)
      && item.internal_selected_grades.length === 0
      && item.category !== "Adult SEL"
      && item.tier_of_support !== null
    ) {
      initializeGradesforother()
    }
  }, [item.tier_of_support])
  const handleGradesUpdate = (grade, field, value) => {
    setInternalSelectedGrades((prevGrades) => {
      const parsedValue = typeof value === "string" ? value.trim() : value
      const newValue = parsedValue != null && parsedValue !== "" && parsedValue !== "0"
        ? Math.max(parseInt(parsedValue, 10), 1)
        : null

      const updatedGrades = prevGrades.map((g) => {
        if (g.grade === grade) {
          const updatedGrade = { ...g, [field]: newValue }

          const selectedGradeObj = matrix.selected_grades.find(
            (gradeObj) => gradeObj.grade === grade,
          )
          const studentPopulation = selectedGradeObj && selectedGradeObj.student_population
            ? selectedGradeObj.student_population
            : null

          if (field === "students_being_supported") {
            const gradeObj = prevGrades.find(
              (gradeObj) => gradeObj.grade === grade,
            )
            const studentsCanBeSupported = gradeObj && gradeObj.students_can_be_supported
              ? gradeObj.students_can_be_supported
              : null

            if (studentsCanBeSupported === null) {
              updatedGrade.students_being_supported = null

              form.resetFields([`students_being_supported_${gradeObj.grade}`])
              form.setFieldsValue({
                [`students_being_supported_${grade}`]: null,
              })
            } else if (newValue > studentsCanBeSupported) {
              showApiError(
                grade,
                "Invalid Input",
                Strings.studentsBeingerror,
                "beingSupported",
              )
            } else {
              setBeingSupportedError((prevState) => ({
                ...prevState,
                visible: false,
              }))
            }
          } else if (field === "students_can_be_supported") {
            if (newValue === null) {
              updatedGrade.students_can_be_supported = null
              updatedGrade.students_being_supported = null

              form.resetFields([`students_being_supported_${grade}`])
              form.setFieldsValue({
                [`students_being_supported_${grade}`]: null,
              })
            } else if (newValue > studentPopulation) {
              showApiError(
                grade,
                "Invalid Input",
                `Students can be supported field cannot be greater than student population(${studentPopulation}).`,
                "canBeSupported",
              )
            } else {
              setCanBeSupportedError((prevState) => ({
                ...prevState,
                visible: false,
              }))
              if (updatedGrade.students_being_supported > newValue) {
                showApiError(
                  grade,
                  "Invalid Input",
                  Strings.studentsBeingerror,
                  "beingSupported",
                )
              } else {
                form.validateFields([`students_being_supported_${grade}`])
                setBeingSupportedError((prevState) => ({
                  ...prevState,
                  visible: false,
                }))
              }
            }
          } else {
            updatedGrade.students_being_supported = g.students_being_supported
          }

          return updatedGrade
        }

        return g
      })

      setItem((prevItem) => {
        const updatedItem = {
          ...prevItem,
          internal_selected_grades: updatedGrades,
        }
        return updatedItem
      })

      return updatedGrades
    })
  }

  useEffect(() => {
    $("[data-toggle=\"popover\"]").each(function () {
      const $this = $(this)
      $this.popover({
        html: true,
        trigger: "hover",
        placement: "top",
        container: $this,
      })
    })
  }, [])

  useEffect(() => {
    setCategory(routeURL)
  }, [routeURL])

  const onSubmit = () => {}
  const tierOfSupport = [
    {
      detail:
        "Universal: The support is designed for all students to have access",
      value: "Universal",
    },
    {
      detail:
        "Targeted: For students who are selected or otherwise demonstrate a risk factor to gain access to the support",
      value: "Targeted",
    },
    {
      detail:
        "Tertiary: High risk students have access to individualized supports",
      value: "Tertiary",
    },
  ]

  useEffect(() => {
    if (activityMode === "edit") {
      setTimeout(() => {
        form.validateFields()
      }, 1000)
    }
  }, [window.location])

  useEffect(() => {
    setCategory(routeURL)
  }, [routeURL])

  const [updatedGradelevels, setUpdatedGrades] = useState(
    matrix.selected_grades || [],
  )

  useEffect(() => {
    if (item && Array.isArray(item.internal_selected_grades)) {
      if (
        (Array.isArray(item.internal_selected_grades)
          ? item.internal_selected_grades.length > 0
          : false)
        || item.is_user_edited
      ) {
        setInternalSelectedGrades(item.internal_selected_grades)
      } else {
        initializeGrades()
      }
    } else {
      initializeGrades()
    }
  }, [item, matrix.selected_grades, item.tier_of_support])

  const gradeData = (Array.isArray(item.internal_selected_grades)
    ? item.internal_selected_grades.length > 0
    : false) || item.is_user_edited
    ? item.internal_selected_grades || []
    : matrix.selected_grades || []

  const totalStudentPopulation = gradeData.reduce((total, gradeInfo) => {
    const matchedGrade = matrix.selected_grades.find(
      (grade) => grade.grade === gradeInfo.grade,
    )
    return matchedGrade
      ? total + (matchedGrade.student_population || 0)
      : total
  }, 0)

  const updateItemWithStudentPopulation = () => {
    const sourceGrades = (Array.isArray(item.internal_selected_grades)
        && item.internal_selected_grades.length > 0)
      || item.is_user_edited
      ? item.internal_selected_grades
      : matrix.selected_grades

    const updatedGrades = sourceGrades.map((gradeObj) => {
      const matchingMatrixGrade = matrix.selected_grades.find(
        (matrixGrade) => matrixGrade.grade === gradeObj.grade,
      )

      const studentPopulation = matchingMatrixGrade
        ? matchingMatrixGrade.student_population
        : null

      return {
        grade: gradeObj.grade,
        students_can_be_supported: studentPopulation,
        students_being_supported: studentPopulation,
      }
    })

    setItem((prevItem) => ({
      ...prevItem,
      internal_selected_grades: updatedGrades,
    }))
    setInternalSelectedGrades(updatedGrades)
  }

  const handleTierChange = (newTier) => {
    setItem((prevItem) => ({
      ...prevItem,
      tier_of_support: newTier,
    }))

    if (newTier === "Universal" && item.category !== "Adult SEL") {
      updateItemWithStudentPopulation()
    }
  }

  useEffect(() => {
    if (item.category === "Adult SEL") {
      setItem((prevItem) => ({
        ...prevItem,
        internal_selected_grades: [],
      }))
      setInternalSelectedGrades([])
    } else if (
      item.tier_of_support === "Universal"
      && item.category !== "Adult SEL"
    ) {
      handleTierChange(item.tier_of_support)
    }
  }, [item.category, item.tier_of_support, selectedGradeLevels])
  const getGradeDetails = (grades, grade) => (grades ? grades.find((g) => g.grade === grade) : null)

  return (
    <Row>
      {item && (
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Col xs={24} sm={24} md={14} lg={14}>
              <FormItemSelectCell
                colDimensions={{ md: 16, lg: 16 }}
                name="categories"
                keys={`dropdown:${category}`}
                defaultValues={category}
                values={window.gon.CATEGORIES}
                handleOnChangeSelect={saveItem}
              />
            </Col>

            <Col Col xs={24} sm={24} md={24} lg={24}>
              <FormItemInputCell
                colDimensions={{ md: 8, lg: 8 }}
                name="activity"
                label={Strings.activity}
                keys={`activity:${item.activity}`}
                type="text"
                defaultValues={item && item.activity}
                rules={rules.inputRequired}
                handleChange={(value) => handleOnChange({ target: { name: "activity", value } })}
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              className={
                item.tier_of_support === "Targeted"
                || item.tier_of_support === "Tertiary"
                  ? ""
                  : "d-flex flex-column"
              }
            >
              <FormItemSelectCell
                colDimensions={{ md: 12, lg: 12 }}
                name="tier_of_support"
                keys={`tier_of_support:${item.tier_of_support}`}
                defaultValues={item && item.tier_of_support}
                values={tierOfSupport}
                showCard
                handleOnChangeSelect={(e, val) => {
                  setLoading(true)
                  handleOnChangeSelect(e, val)

                  const newValues = {
                    students_being_supported: null,
                    students_can_be_supported: null,
                  }

                  form.setFieldsValue(newValues)

                  if (val.value === "Targeted" || val.value === "Tertiary") {
                    const fieldValues = {}
                    item.internal_selected_grades.forEach((grade) => {
                      fieldValues[`students_can_be_supported_${grade.grade}`] = null
                      fieldValues[`students_being_supported_${grade.grade}`] = null
                    })

                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      ...fieldValues,
                    })

                    const updatedGrades = item.internal_selected_grades.map(
                      (gradeObj) => ({
                        ...gradeObj,
                        students_can_be_supported: null,
                        students_being_supported: null,
                      }),
                    )

                    setItem((prevItem) => ({
                      ...prevItem,
                      internal_selected_grades: updatedGrades,
                    }))
                  } else if (val.value === "Universal") {
                    updateStudentsCount(
                      "update_students_can_supported",
                      studentPopulation,
                    )
                    updateStudentsCount(
                      "update_students_being_supported",
                      studentPopulation,
                    )
                  }
                  setTimeout(() => {
                    setLoading(false)
                  }, 500)
                }}
                rules={rules.selectRequired}
                label={Strings.support}
                isDetail
              />

              {!(fieldsValues.category === "Adult SEL")
                && item.category !== "Adult SEL"
                && item.tier_of_support
                && (item.tier_of_support === "Targeted"
                  || item.tier_of_support === "Tertiary") && (
                  <>
                    <div className="activity-form-select">
                      {item.tier_of_support !== "Universal" && (
                        <FormItemSelectCell
                          colDimensions={{ md: 12, lg: 12 }}
                          name="internal_selected_grades"
                          mode="multiple"
                          allowClear
                          defaultValues={
                            (Array.isArray(item.internal_selected_grades)
                              ? item.internal_selected_grades.length > 0
                              : false) || item.is_user_edited
                              ? (item.internal_selected_grades || []).map(
                                (gradeObj) => gradeObj.grade,
                              )
                              : (matrix.selected_grades || []).map(
                                (gradeObj) => gradeObj.grade,
                              )
                          }
                          values={matrix.selected_grades.map(
                            (gradeObj) => gradeObj.grade,
                          )}
                          handleOnChangeSelect={(selectedValues) => {
                            setInternalSelectedGrades(
                              (prevInternalSelectedGrades) => {
                                const updatedGrades = selectedValues.map(
                                  (grade) => {
                                    const existingGrade = prevInternalSelectedGrades.find(
                                      (gradeObj) => gradeObj.grade === grade,
                                    )

                                    return (
                                      existingGrade || {
                                        grade,
                                      }
                                    )
                                  },
                                )

                                form.setFieldsValue(
                                  updatedGrades.reduce((acc, gradeObj) => {
                                    acc[
                                      `students_can_be_supported_${gradeObj.grade}`
                                    ] = gradeObj.students_can_be_supported
                                    acc[
                                      `students_being_supported_${gradeObj.grade}`
                                    ] = gradeObj.students_being_supported
                                    return acc
                                  }, {}),
                                )

                                setItem((prevItem) => ({
                                  ...prevItem,
                                  internal_selected_grades: updatedGrades.map(
                                    (gradeObj) => ({
                                      ...gradeObj,
                                      students_being_supported:
                                        gradeObj.students_being_supported
                                        || null,
                                      students_can_be_supported:
                                        gradeObj.students_can_be_supported
                                        || null,
                                    }),
                                  ),
                                  is_user_edited: true,
                                }))

                                setUpdatedGrades(updatedGrades)

                                return updatedGradelevels
                              },
                            )
                          }}
                          label={Strings.gradeLevelTitle}
                          rules={rules.selectRequiredforGradelevels}
                        />
                      )}
                      {InternalSelectedGrades
                        && InternalSelectedGrades.length > 0 && (
                          <Col
                            Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            className="error-modal"
                          >
                            {canBeSupportedError.visible && (
                              <Alert
                                message={(
                                  <span className="alert-message">
                                    {`Invalid Input for Grade ${canBeSupportedError.grade}`}
                                  </span>
                                )}
                                description={canBeSupportedError.message}
                                type="error"
                                showIcon
                                closable
                                className={`custom-alert ${
                                  canBeSupportedError.opacity === 1
                                    ? "visible"
                                    : ""
                                }`}
                              />
                            )}
                            {beingSupportedError.visible && (
                              <Alert
                                message={(
                                  <span className="alert-message">
                                    {`Invalid Input for Grade ${beingSupportedError.grade}`}
                                  </span>
                                )}
                                description={beingSupportedError.message}
                                type="error"
                                showIcon
                                closable
                                className={`custom-alert ${
                                  beingSupportedError.opacity === 1
                                    ? "visible"
                                    : ""
                                }`}
                              />
                            )}

                            {InternalSelectedGrades.map((gradeObj) => {
                              const gradeDetails = getGradeDetails(
                                item.internal_selected_grades,
                                gradeObj.grade,
                              )

                              const studentsCanBeSupported = gradeDetails
                                && gradeDetails.students_can_be_supported
                                ? gradeDetails.students_can_be_supported
                                : gradeObj.students_can_be_supported || null

                              const isDisabled = studentsCanBeSupported <= 0

                              const defaultStudentsCanBeSupported = gradeDetails
                                && gradeDetails.students_can_be_supported
                                ? gradeDetails.students_can_be_supported
                                : null

                              const defaultStudentsBeingSupported = gradeDetails
                                && gradeDetails.students_being_supported
                                ? gradeDetails.students_being_supported
                                : null

                              return (
                                <div
                                  key={gradeObj.grade}
                                  className="grade-object"
                                >
                                  <div className="gradeContainer">
                                    <div style={{ width: "60%" }}>
                                      <span className="selected-grades">
                                        {Strings.selectedGrades}
                                      </span>

                                      <div className="main-studentsSupportinTabs">
                                        {gradeObj.grade}
                                      </div>
                                    </div>
                                    <Col xs={24} sm={24} md={5} lg={5}>
                                      <div className="studentsSupportinTabs">
                                        <FormItemInputCellNoCard
                                          min={1}
                                          colDimensions={{ md: 5, lg: 5 }}
                                          name={`students_can_be_supported_${gradeObj.grade}`}
                                          keys={`students_can_be_supported_${gradeObj.grade}`}
                                          defaultValues={
                                            defaultStudentsCanBeSupported
                                          }
                                          handleChange={(value) => handleGradesUpdate(
                                            gradeObj.grade,
                                            "students_can_be_supported",
                                            value,
                                          )}
                                          label={Strings.studentsCanBeSupported}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter the value",
                                            },
                                            {
                                              validator: (_, value) => {
                                                const foundGrade = matrix.selected_grades.find(
                                                  (grade) => grade.grade
                                                      === gradeObj.grade,
                                                )

                                                const max = foundGrade
                                                  ? foundGrade.student_population
                                                  : 0

                                                if (value > max) {
                                                  return Promise.reject(
                                                    new Error(
                                                      `Value must not exceed ${max}`,
                                                    ),
                                                  )
                                                }
                                                return Promise.resolve()
                                              },
                                            },
                                          ]}
                                        />
                                        <></>
                                      </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={5} lg={5}>
                                      <div className="studentsSupportinTabs">
                                        <FormItemInputCellNoCard
                                          disabled={isDisabled}
                                          min={1}
                                          type="number"
                                          colDimensions={{ md: 5, lg: 5 }}
                                          name={`students_being_supported_${gradeObj.grade}`}
                                          keys={`students_being_supported_${gradeObj.grade}`}
                                          defaultValues={
                                            defaultStudentsBeingSupported
                                          }
                                          handleChange={(value) => handleGradesUpdate(
                                            gradeObj.grade,
                                            "students_being_supported",
                                            value,
                                          )}
                                          label={(
                                            <div className="d-flex">
                                              <div className="px-1">
                                                {
                                                  Strings.studentsAreBeingSupported
                                                }
                                              </div>
                                            </div>
                                          )}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter the value",
                                            },
                                            {
                                              validator: (_, value) => {
                                                const max = gradeObj.students_can_be_supported
                                                  || 0

                                                if (value > max) {
                                                  return Promise.reject(
                                                    new Error(
                                                      `Value must not exceed ${max}.`,
                                                    ),
                                                  )
                                                }
                                                return Promise.resolve()
                                              },
                                            },
                                          ]}
                                        />
                                        <></>
                                      </div>
                                    </Col>
                                  </div>
                                </div>
                              )
                            })}
                          </Col>
                      )}
                    </div>
                  </>
              )}
              {/* </Col> */}

              {item.tier_of_support === "Universal"
                && item.category !== "Adult SEL" && (
                  <Col xs={24} sm={24} md={24} lg={24} className="">
                    <div className="main-container-universal">
                      <div className="inner-main-container">
                        <FormItemSelectCell
                          colDimensions={{ md: 12, lg: 12 }}
                          name="internal_selected_grades"
                          mode="multiple"
                          allowClear
                          defaultValues={
                            (Array.isArray(item.internal_selected_grades)
                              ? item.internal_selected_grades.length > 0
                              : false) || item.is_user_edited
                              ? (item.internal_selected_grades || []).map(
                                (gradeObj) => gradeObj.grade,
                              )
                              : (matrix.selected_grades || []).map(
                                (gradeObj) => gradeObj.grade,
                              )
                          }
                          values={matrix.selected_grades.map(
                            (gradeObj) => gradeObj.grade,
                          )}
                          handleOnChangeSelect={handleGradeLevelsChange}
                          label={Strings.gradeLevelTitle}
                          rules={rules.selectRequiredforGradelevels}
                        />
                        <div className="main-population">
                          <div className="population-canbe">
                            <span className="labels-population-being">
                              {"students can be supported: "}
                            </span>
                            <span className="value-totalStudentPopulation">
                              {_.isNull(totalStudentPopulation)
                                ? "No data found"
                                : totalStudentPopulation}
                            </span>
                          </div>
                          <div className="population-being">
                            <span className="labels-population-being">
                              {"students are being supported: "}
                            </span>

                            <span className="value-totalStudentPopulation">
                              {_.isNull(totalStudentPopulation)
                                ? "No data found"
                                : totalStudentPopulation}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
              )}
            </Col>
            <Col Col xs={24} sm={24} md={24} lg={24}>
              <FormItemSelectCell
                colDimensions={{ md: 12, lg: 12 }}
                name="length_of_time"
                keys={`length_of_time:${item.length_of_time}`}
                defaultValues={item && item.length_of_time}
                values={window.gon.LENGTHS_OF_TIME}
                handleOnChangeSelect={handleOnChangeSelect}
                label={Strings.timeSpan}
                rules={rules.selectRequired}
                showCard
              />
            </Col>

            <Col Col xs={24} sm={24} md={24} lg={24}>
              <FormItemInputCell
                colDimensions={{ md: 8, lg: 8 }}
                name="description"
                keys={`description:${item.description}`}
                label={Strings.description}
                defaultValues={item && item.description}
                type="text"
                rules={rules.inputRequired}
                value={item.description}
                handleChange={(value) => handleOnChange({ target: { name: "description", value } })}
              />
            </Col>
            <Col Col xs={24} sm={24} md={24} lg={24}>
              <FormItemInputCell
                colDimensions={{ md: 8, lg: 8 }}
                name="data_collection"
                keys={`data_collection:${item.data_collection}`}
                label={Strings.dataCollection}
                defaultValues={item && item.data_collection}
                type="text"
                rules={rules.inputNonRequired}
                value={item.data_collection}
                handleChange={(value) => handleOnChange({ target: { name: "data_collection", value } })}
              />
            </Col>
          </Form>
        </Col>
      )}
    </Row>
  )
}

export default ProgramMatrixTabs
