import React, { useEffect, useState } from "react"
import _ from "underscore"
import { Col, Row } from "antd"
import componentStyles from "../../styles"
import SELIndicator from "./SELIndicator"
import commonStrings from "../../commonStrings"

function ProgramMatrixSecondForm({
  form,
  item,
  handleOnChange,
  handleOnChangeSelect,
  deleteItem,
  removeStandard,
  setIndicator,
  indicator,
}) {
  const [disableButton, setDisableButton] = useState(true)
  useEffect(() => {
    $("[data-toggle=\"popover\"]").each(function () {
      const $this = $(this)
      $this.popover({
        html: true,
        trigger: "hover",
        placement: "top",
        container: $this,
      })
    })
  }, [])

  useEffect(() => {
    if (indicator) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [indicator])
  const standards = (key) => {
    const hash = {}
    _.pluck(window.gon.SEL_STANDARDS, "standards").forEach((array) => {
      _.extend(hash, array)
    })
    return key ? hash[key][item.adult ? "adult_indicator_language" : "student_indicator_language"] : hash
  }
  const selStandardsOptions = () => _.without(_.keys(standards()), ..._.keys(item.sel_standards)).map((key) => (
    <option key={key} value={key}>
      {key}. {standards(key)}
    </option>
  ))
  const handleSubmit = (event) => {
    event.preventDefault()
    handleOnChange(indicator)
    event.target.reset()
  }
  return (
    <Row>
      <>
        <Col xs={24} sm={24} md={14} lg={14}>
          {Object.keys(item.sel_standards).length >= 0 && (
            <span className="d-flex flex-column">
              <span style={componentStyles.indicatorAlert}>
                {commonStrings.selIndicators}
              </span>{" "}
              <span className="font-italic mb-2">
                {commonStrings.ComprehensiveSels}
              </span>
            </span>
          )}
        </Col>
        <form onSubmit={handleSubmit} className="selectWidthFixed">
          <Row>
            <Col xs={24} sm={24} md={18} lg={18}>
              <select
                className="form-control mb-3"
                name="sel_standards"
                value={indicator ? indicator.target.value : ""}
                style={componentStyles.selectStyleSystemAdmins}
                onChange={(e) => setIndicator(e)}
                required
              >

                {selStandardsOptions().length === 0 ? (
                  <option value="" disabled>{commonStrings.noData}</option>
                ) : (
                  <>
                    <option value="" disabled>{commonStrings.SelectSelIndicator}</option>
                    {selStandardsOptions()}
                  </>
                )}
              </select>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={5}
              lg={5}
              className="d-flex justify-content-center align-items-baseline"
            >
              <button
                disabled={disableButton}
                className={`btn btn-outline-secondary ml-2 btnAddIndicator ${disableButton && "notAllowed"
                }`}
                type="submit"
              >
                Add Indicator
              </button>
            </Col>
          </Row>
        </form>
      </>

      {Object.keys(item.sel_standards).map((value) => (
        <SELIndicator
          form={form}
          item={item}
          handleOnChange={handleOnChange}
          value={value}
          deleteItem={deleteItem}
          removeStandard={removeStandard}
          handleOnChangeSelect={handleOnChangeSelect}
        />
      ))}
    </Row>
  )
}

export default ProgramMatrixSecondForm
