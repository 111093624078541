import { Col, Form, Select } from "antd"
import React from "react"
import PropTypes from "prop-types"
import componentStyles from "../../styles"

const ruleShape = PropTypes.shape({
  required: PropTypes.bool,
  message: PropTypes.string,
})

const optionShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
})

function SystemAdminMultiSelect({
  colDimensions,
  label,
  name,
  rules,
  keys,
  defaultValues,
  disabled,
  placeholder,
  handleChange,
  options,
}) {
  return (
    <Col
      xs={24}
      sm={24}
      md={colDimensions && colDimensions.md ? colDimensions.md : 8}
      lg={colDimensions && colDimensions.lg ? colDimensions.lg : 8}
    >
      <Form.Item
        label={label}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={name}
        rules={rules}
        key={keys}
      >
        <Select
          defaultValue={defaultValues}
          showArrow
          mode="multiple"
          id={name}
          name={name}
          disabled={disabled}
          style={componentStyles.inputStyleNewMultiSelect}
          bordered={false}
          placeholder={placeholder}
          optionFilterProp="label"
          onChange={(value) => handleChange(value)}
          options={options}
          showSearch
        />
      </Form.Item>
    </Col>
  )
}

SystemAdminMultiSelect.propTypes = {
  colDimensions: PropTypes.shape({
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(ruleShape),
  keys: PropTypes.string,
  defaultValues: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(optionShape).isRequired,
}

SystemAdminMultiSelect.defaultProps = {
  colDimensions: { md: 8, lg: 8 },
  rules: [],
  keys: "",
  defaultValues: [],
  disabled: false,
  placeholder: "",
}

export default SystemAdminMultiSelect
