import React from "react"
import _ from "underscore"
import { CloseCircleOutlined } from "@ant-design/icons"
import commonStrings from "../../commonStrings"

export default class EvidenceListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      description: props.evidence.description,
      shared: props.evidence.shared,
    }
    this.saveEvidence = _.debounce(this.saveEvidence, 250)
  }

  descriptionChanged(e) {
    this.setState({ description: e.target.value }, () => {
      this.saveEvidence()
    })
  }

  shareResponse(e) {
    this.setState({ shared: e.target.checked }, () => {
      this.saveEvidence()
    })
  }

  saveEvidence() {
    showSaving()
    APIClient.put(
      `/sustainability_rubrics/${this.props.rubricId}/sustainability_rubric_indicators/${this.props.indicatorId}/evidence/${this.props.evidence.id}`,
      {
        description: this.state.description,
        shared: this.state.shared,
      },
      () => {
        hideSaving()
      },
    )
  }

  render() {
    const {
      evidence, disabled, deleteEvidence, color,
    } = this.props
    return (
      <div className="my-4">
        <div className="mb-1 d-flex">
          <a
            style={{ color }}
            className="uploaded-links"
            href={evidence.url}
            target="_blank"
            rel="noreferrer"
          >
            {evidence.filename}
          </a>
          {!disabled && (
            // <i
            //   className="fa fa-trash-o mx-2"
            //   onClick={() => deleteEvidence(evidence.id)}
            // />
            <div
              className="mx-2 cursorPointer antd-logo-size-16 dflex-alignItems-center"
              onClick={() => deleteEvidence(evidence.id)}
            >
              <CloseCircleOutlined />
            </div>
          )}
        </div>
        <input
          type="text"
          placeholder="Please provide a description of the uploaded file..."
          value={this.state.description || ""}
          disabled={disabled}
          onChange={this.descriptionChanged.bind(this)}
          className="form-control mb-2"
        />
        <div className="form-check d-flex align-items-center">
          <input
            id={`shared-${evidence.id}`}
            className="form-check-input"
            type="checkbox"
            checked={this.state.shared}
            onChange={this.shareResponse.bind(this)}
          />

          <label
            className="form-check-label small new-styling-label uploaded-links"
            htmlFor={`shared-${evidence.id}`}
          >
            {commonStrings.evidenceCheckbox}
          </label>
        </div>
      </div>
    )
  }
}
