import React from "react"
import { Button } from "antd"

const HeaderButtons = (props) => {
  const {
    textString, onClick, backgroundColor, color, id, disabled,
  } = props
  return (
    <div className="mx-2 actionImplementation" key={id}>
      <Button
        disabled={disabled}
        style={{
          backgroundColor,
          color,
          borderRadius: "3px",
          fontWeight: "600",
        }}
        onClick={onClick}
      >
        {textString}
      </Button>
    </div>
  )
}

export default HeaderButtons
