import React from "react"
import { Pie } from "react-chartjs-2"
import {
  uaDarkBlue, uaGold, uaRed, uaTeal,
} from "../../enums"

function PieChart({
  labels,
  values,
  onClick,
  colors = [uaTeal, uaGold, uaDarkBlue, uaRed],
  legend = false,
  tooltipSuffix = "%",
}) {
  const data = {
    labels: labels || [],
    datasets: [{
      data: values.map((d) => parseInt(d, 10)),
      backgroundColor: [
        `rgba(${colors[0]}, 0.75)`,
        `rgba(${colors[1]}, 0.75)`,
        `rgba(${colors[2]}, 0.75)`,
        `rgba(${colors[3]}, 0.75)`,
      ],
      borderWidth: [0, 0, 0],
    }],
  }

  const options = {
    onClick: (event, element) => {
      if (element.length && onClick) {
        onClick(element[0].index)
      }
    },
    onHover: (event, element) => {
      if (onClick) {
        event.native.target.style.cursor = element[0] ? "pointer" : "default"
      }
    },
    plugins: {
      legend: {
        display: legend,
        onClick: () => {},
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => ` ${tooltipItem.label}: ${tooltipItem.formattedValue}${tooltipSuffix}`,
        },
      },
    },
  }

  return (
    <div className="mb-3">
      <Pie data={data} options={options} />
    </div>
  )
}

export default PieChart
