import React from "react"
import { Tooltip } from "antd"

function ProgressBar({
  progress, color, onClick, tooltip,
  // tooltipPlacement = "top",
}) {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e.target.className === "progress" ? 1 : 0)
    }
  }

  return (
    <Tooltip title={tooltip}>
      <div
        className="progress"
      // title={tooltip}
      // data-toggle="tooltip"
      // data-placement={tooltipPlacement}
        onClick={handleClick}
        style={{ cursor: onClick ? "pointer" : "initial" }}
      >
        <div
          className="progress-bar"
          style={{ maxWidth: `${progress}%`, backgroundColor: `rgb(${color})` }}
        />
      </div>
    </Tooltip>
  )
}

export default ProgressBar
