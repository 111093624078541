import React from "react"
import _ from "underscore"
import GenericTreeSelect from "../CommonComponents/Forms/GenericTreeSelect"
import CommonConstants from "../../CommonConstants"

function ProgramMatrixFilters({
  filters,
  setFilters,
  activities,
  indicators,
  isDessaReport,
  onDessaFilterChange,
}) {
  const handleChange = (name, value, dessaOption) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters }

      if (name === "dessa_competencies") {
        newFilters[name] = value.map((str) => str.split(",").map((item) => item.trim()))
        if (onDessaFilterChange) {
          onDessaFilterChange(dessaOption)
        }
      } else {
        newFilters[name] = value.map((object) => object)
      }

      return newFilters
    })
  }

  function convertValuesIntoFilters(arr) {
    const result = []
    if (result.length === 0 || result.includes(result)) {
      arr.forEach((item) => {
        const label = item.name
        const value = Object.keys(item.standards).join(", ")
        result.push({ label, value })
      })
    }
    return result
  }

  const categories = () => (
    <div>
      <GenericTreeSelect
        name="categories"
        onChange={(option) => handleChange("categories", option)}
        placeholder="Domains"
        treeData={window.gon.CATEGORIES.map((category) => ({
          label: category,
          value: category,
        }))}
        maxTagCount={0}
      />
    </div>
  )

  const tiersOfSupport = () => (
    <div>
      <GenericTreeSelect
        name="tiers_of_support"
        onChange={(option) => handleChange("tiers_of_support", option)}
        placeholder="Tiers of support"
        treeData={window.gon.TIERS_OF_SUPPORT.map((tier) => ({
          label: tier,
          value: tier,
        }))}
        maxTagCount={0}
      />
    </div>
  )

  const activitiesSelect = () => {
    const stringItems = _.uniq(
      _.reject(activities, (activity) => _.isEmpty(activity)),
    )

    return (
      <div>
        <GenericTreeSelect
          name="activities"
          onChange={(option) => handleChange("activities", option)}
          placeholder="Activities"
          treeData={stringItems.map((item) => ({ label: item, value: item }))}
          maxTagCount={0}
        />
      </div>
    )
  }

  const standardsSelect = () => (
    <div>
      <GenericTreeSelect
        name="standards"
        onChange={(option) => handleChange("standards", option)}
        placeholder="Indicators"
        treeData={indicators}
        maxTagCount={0}
      />
    </div>
  )

  const dessaCompetenciesSelect = () => {
    if (!isDessaReport) return null

    const selectedLoadIndicators = filters.load_indicators || []

    return (
      <div>
        <GenericTreeSelect
          name="dessa_competencies"
          onChange={(option, dessaOption) => handleChange("dessa_competencies", option, dessaOption)}
          placeholder="DESSA Competencies"
          treeData={convertValuesIntoFilters(
            window.gon.DESS_SEL_STANDARDS,
            selectedLoadIndicators,
          )}
          maxTagCount={0}
        />
      </div>
    )
  }

  const gradeLevelsSelect = () => (
    <div>
      <GenericTreeSelect
        name="internal_selected_grades"
        isMulti
        defaultValue={filters.internal_selected_grades}
        onChange={(option) => handleChange("internal_selected_grades", option)}
        placeholder="Grade Levels"
        treeData={CommonConstants.Grades.map((level) => ({
          label: level,
          value: level,
        }))}
        maxTagCount={0}
      />
    </div>
  )

  const isSELScan = () => _.isObject(filters.activities)

  return (
    <div className="px-2 py-4 overall-body">
      <div className="row">
        <div className={isDessaReport ? "col-3" : "col-3"}>{categories()}</div>
        <div className={isDessaReport ? "col-2" : "col-3"}>
          {tiersOfSupport()}
        </div>
        <div className={isDessaReport ? "col-2" : "col-3"}>
          {gradeLevelsSelect()}
        </div>
        {isSELScan() && (
          <div className={isDessaReport ? "col-2" : "col-3"}>
            {activitiesSelect()}
          </div>
        )}
        {!isSELScan() && (
          <div className={isDessaReport ? "col-2" : "col-2"}>
            {standardsSelect()}
          </div>
        )}
        <div className={isDessaReport ? "col-3" : "col-3"}>
          {dessaCompetenciesSelect()}
        </div>
      </div>
    </div>
  )
}

export default ProgramMatrixFilters
