import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { filterNullStudents, removeUniversalTier } from "../../utilities"

const TimelineDumbbell = ({
  titleText = "",
  dumbbellColorStart = "#0CA4A5",
  dumbbellColorEnd = "#D9D9D9",
  graphData,
  setOpenModal,
  setComparisonActualCapacity,
}) => {
  const [chartData, setChartData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (graphData && graphData.length > 0) {
      setChartData([{ data: graphData }])
      setIsLoading(false)
    } else {
      setChartData([])
      setIsLoading(false)
    }
  }, [graphData])

  const chartOptions = {
    chart: {
      height: 390,
      type: "rangeBar",
      zoom: {
        enabled: false,
      },
      events: {
        click: (event, chartContext, config) => {
          if (
            typeof config.seriesIndex !== "undefined"
            && typeof config.dataPointIndex !== "undefined"
          ) {
            const { seriesIndex } = config
            const { dataPointIndex } = config

            const clickedData = chartData[seriesIndex].data[dataPointIndex]

            if (clickedData) {
              const actualData = [clickedData.completeObject]

              const values = filterNullStudents(
                removeUniversalTier(actualData),
              ).length

              setComparisonActualCapacity(
                filterNullStudents(removeUniversalTier(actualData)),
              )

              if (values > 0) {
                setOpenModal(true)
              }
            }
          }
        },
      },
    },
    colors: ["#36BDCB", "#36BDCB"],
    plotOptions: {
      bar: {
        horizontal: true,
        isDumbbell: true,
        dumbbellColors: [[dumbbellColorStart, dumbbellColorEnd]],
      },
    },
    title: {
      text: titleText,
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "left",
    },
    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: ["#494949"],
        inverseColors: false,
        stops: [0, 100],
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
          color: "#000",
          height: 20,
        },
      },
      yaxis: {
        lines: {
          show: true,
          color: "#000",
          height: 20,
        },
      },
    },
    xaxis: {
      title: {
        text: "- Number Of Students -",
        style: {
          fontWeight: "normal",
          color: "#636363",
          size: "7",
        },
      },
    },
    yaxis: {
      title: {
        text: "- Activity Name -",
        style: {
          fontWeight: "normal",
          color: "#636363",
          size: "7",
        },
      },
    },
  }

  if (isLoading) {
    return null
  }

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartOptions}
          series={chartData}
          type="rangeBar"
          height={390}
        />
      </div>
      <div id="html-dist" />
    </div>
  )
}

export default TimelineDumbbell
