import React from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import pdfSvg from "../../../assets/images/pdf-file.svg"
import SchoolPlanPdf from "./SchoolPlanPdf"

function ImplementationPlan({
  rubric,
  reportHeadingTop,
  removeHtmlTags,
  currentSchoolName,
  dateForReport,
  currentschoolLogo,
}) {
  // const generatePDF = () => {
  //   window.open(`/implementation_rubrics/${rubric.id}/plan.pdf`)
  // }
  const sortArrayByRating = (array) => array
    .flatMap((stage) => stage.indicators.map((indicator) => ({
      index: indicator.index,
      label: indicator.label,
      explanation: indicator.explanation,
      rating: indicator.rating,
    })))
    .sort((a, b) => {
      if (a.rating === null && b.rating === null) {
        return 0
      }
      if (a.rating === null) {
        return 1
      }
      if (b.rating === null) {
        return -1
      }
      if (a.rating === 0 && b.rating === 0) {
        return 0
      }
      if (a.rating === 0) {
        return 1
      }
      if (b.rating === 0) {
        return -1
      }
      return b.rating - a.rating
    })

  return (
    <>
      <div className="newPdfButton">
        <PDFDownloadLink
          document={(
            <SchoolPlanPdf
              rubric={rubric}
              sortArrayByRating={sortArrayByRating}
              reportHeadingTop={reportHeadingTop}
              removeHtmlTags={removeHtmlTags}
              currentschoolLogo={currentschoolLogo}
            />
          )}
          fileName={`${
            currentSchoolName || "school_name"
          }_plan(${dateForReport}).pdf`}
        >
          {() => <img style={{ width: "40px" }} src={pdfSvg} />}
        </PDFDownloadLink>
      </div>

      <div>
        <div className="plan p-3 mb-4">
          <div className="narrative1">
            {sortArrayByRating(rubric.plan).map((indicator, n) => (
              <div
                key={n}
                className={`pl-4 pr-4 pt-2 pb-2 borderBottom ${
                  indicator.rating === 0
                  || indicator.rating === 1
                  || indicator.rating === null
                    ? "lightGreen"
                    : indicator.rating === 2 || indicator.rating === 3
                      ? "mediumGreen"
                      : indicator.rating === 4
                        ? "darkGreen"
                        : ""
                } ${n === 0 ? "rounded-top" : ""} ${
                  n === rubric.plan.length - 1 ? "rounded-bottom" : ""
                }`}
              >
                <span className="fontWeight600 anchorColor">
                  {indicator.index}.{" "}
                  <span dangerouslySetInnerHTML={{ __html: indicator.label }} />
                </span>
                <span className="italicFonts displayBlock">
                  {indicator.explanation}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ImplementationPlan
