import React, { useState, useEffect } from "react"
import { Row, Col } from "antd"
import moment from "moment"
import DrawerTable from "./CommonComponents/DrawerTable"
import Gauge from "./Gauge"
import { defaultSort, urlToSchool } from "../utilities"
import Loader from "./CommonComponents/Loader"

const CollapseWidget = ({
  title,
  description,
  src,
  schoolsWithSubmissions,
  copyProgramSchools,
  titleDoughnut,
  generalColor,
  schoolsWithSubmissionsCount,
  open,
  setOpen,
  graphLoader,
}) => {
  // const [open, setOpen] = useState(false);
  const [tableListData, setTableListData] = useState([])

  const combineUniqueElementsOfArrays = (subArray, superArray) => {
    let finalArray = [...subArray]
    const superArrayCopy = [...superArray]
    const diffArr = superArrayCopy.filter(
      (obj1) => !finalArray.some((obj2) => obj2.school_id === obj1.id),
    )
    finalArray = [...finalArray, ...diffArr]
    return finalArray
  }

  const mergeNameAttributeToMatchedSubSet = (superSet, subSet) => {
    const superSetCopy = [...superSet]
    const subSetCopy = [...subSet]
    superSetCopy.forEach((superSetElem) => {
      subSetCopy
        .filter((subSetElem) => subSetElem.school_id === superSetElem.id)
        .forEach((matchingElem) => {
          matchingElem.name = superSetElem.name
          return 0
        })
      return 0
    })
    return subSetCopy
  }

  const tableColumns = [
    {
      title: "School Name",
      dataIndex: "name",
      render: (text, _) => (
        <a
          className={
            (title === "Matrix" && "styling-name-matrix")
            || (title === "Implementation" && "styling-name-implementation")
            || (title === "Sustainability" && "styling-name-sustainability")
          }
          onClick={() => {
            urlToSchool(_.school_id)
          }}
        >
          {text}
        </a>
      ),
      width: 120,
      sorter: {
        compare: (a, b) => defaultSort(a.name, b.name),
      },
    },

    {
      title: "Last Submitted",
      dataIndex: "submitted_at",
      render: (date) => (date ? <a>{moment.utc(date).format("MM/DD/YYYY")}</a> : "N/A"),
      width: 90,
    },
  ]
  const headers = [
    { label: "School Name", key: "name" },
    { label: "Last Submitted", key: "submitted_at" },
  ]

  useEffect(() => {
    setTableListData(
      combineUniqueElementsOfArrays(
        mergeNameAttributeToMatchedSubSet(
          copyProgramSchools,
          schoolsWithSubmissions,
        ),
        copyProgramSchools,
      ),
    )
  }, [schoolsWithSubmissions, copyProgramSchools])

  return (
    <Row className="row d-flex align-items-center justify-content-between mb-5">
      <DrawerTable
        open={open}
        setOpen={setOpen}
        title={title}
        generalColor={generalColor}
        tableListData={tableListData}
        tableColumns={tableColumns}
        headers={headers}
        isCollapseBody={false}
      />

      <Col
        xs={10}
        sm={10}
        md={10}
        lg={10}
        className="d-flex align-items-center"
      >
        <img src={src} height="95px" className="mx-auto d-block" />
        <div className="d-flex flex-column">
          <h3 style={{ color: { generalColor } }} className="dashboard_title">
            {title}
          </h3>
          <span className="description_text">{description}</span>
        </div>
      </Col>

      <Col
        xs={10}
        sm={10}
        md={12}
        lg={12}
        className="d-flex justify-content-center flex-column"
      >
        <div className="roundChart">
          <span
            onClick={() => {
              setOpen(true)
            }}
          >
            {!graphLoader ? (
              <Gauge
                textWithoutSubmission="School Without Submission"
                textSubmission="Schools With Submission"
                title={title}
                obtained={schoolsWithSubmissionsCount}
                total={copyProgramSchools.length}
                generalColor={generalColor}
              />
            ) : (
              <div className="meter-loader">
                <Loader generalColor={generalColor} />
              </div>
            )}
          </span>
          <span className="donutChart textAlignCenter">{titleDoughnut}</span>
        </div>
      </Col>
    </Row>
  )
}

export default CollapseWidget
