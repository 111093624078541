import moment from "moment"
import Swal from "sweetalert2"
import _ from "underscore"
import { uaGold, uaRed, uaTeal } from "./enums"

export const sumStudentsCount = (dataArray) => {
  /* eslint-disable camelcase */
  dataArray.forEach((item) => {
    let sum_students_being_supported = 0
    let sum_students_can_supported = 0

    item.internal_selected_grades.forEach((grade) => {
      sum_students_being_supported += grade.students_being_supported
      sum_students_can_supported += grade.students_can_be_supported
    })

    item.sum_students_being_supported = sum_students_being_supported
    item.sum_students_can_supported = sum_students_can_supported
  })

  return dataArray
  /* eslint-enable camelcase */
}

export const asRelativeDate = (string) => moment(string).fromNow()
export const areYouSure = (
  icon,
  onConfirm,
  onCancel,
  title = "",
  text = "",
  confirmBtnText,
  cancelBtnText,
) => {
  Swal.fire({
    title: title || "Are you sure?",
    text,
    icon,
    iconColor: icon === "info" ? `rgb(${uaTeal})` : `rgb(${uaGold})`,
    showCancelButton: true,
    confirmButtonColor: "#0CA4A5",
    confirmButtonText: `${confirmBtnText || "Yes"}`,
    cancelButtonText: `${cancelBtnText || "No"}`,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm()
    } else {
      onCancel()
    }
  })
}

export const lightbulbAlert = ({ title, html }) => Swal.fire({
  iconHtml: "<i class=\"fa fa-lightbulb-o \" />",
  iconColor: `rgb(${uaGold})`,
  title,
  html,
  confirmButtonColor: "#0CA4A5",
})

export const infoAlert = ({ title, html }) => Swal.fire({
  icon: "info",
  iconColor: `rgb(${uaTeal})`,
  title,
  html,
  confirmButtonColor: "#0CA4A5",
})

export const errorAlert = ({ title = "Oops", html, text }) => Swal.fire({
  icon: "error",
  text,
  iconColor: `rgb(${uaRed})`,
  title,
  html,
  confirmButtonColor: "#0CA4A5",
})

export const errorAlertforProgramMatrix = ({ title = "Oops", html, text }) => {
  Swal.fire({
    icon: "error",
    text,
    iconColor: `rgb(${uaRed})`,
    title,
    html,
    confirmButtonColor: "#0CA4A5",
    customClass: {
      popup: "custom-swal-popup",
    },
  })
}

export const TruncateText = (truncateText, stringLength) => (truncateText.length > stringLength
  ? `${truncateText.substr(0, stringLength)}...`
  : truncateText)

export const defaultSort = (a, b) => {
  if (a < b) return -1
  if (b < a) return 1
  return 0
}

export const openNotification = (placement, api) => {
  api.info({
    message: "Alert",
    description: "The user has been deleted successfully",
    placement,
    duration: 2,
  })
}

export const dateSort = (dateA, dateB) => moment(dateA).diff(moment(dateB))

export const mergeArrayWithProgramSchools = (array1, array2) => array1.map((obj1) => {
  const matchingObj = array2.find((obj2) => obj2.id === obj1.school_id)
  if (!matchingObj) return obj1

  const mergedObj = {
    name: matchingObj.name,
    school_id: matchingObj.school_id,
    id: matchingObj.id,
  }
  return mergedObj
})

export const maxYAxisOffSet = (count, steps) => {
  let i = count
  while (true) {
    if (i % steps !== 0) {
      i += 1
    } else {
      return i
    }
  }
}

export const idIntoSchoolId = (newArray) => {
  const array = [...newArray]
  const updatedArray = array.map((obj) => {
    if (!Object.prototype.hasOwnProperty.call(obj, "school_id")) {
      obj = { ...obj }
      obj.school_id = obj.id
      delete obj.id
    }
    return obj
  })
  return updatedArray
}

export const addMissingProperty = (array) => array.map((obj) => {
  if (!obj.submitted_at) obj.submitted_at = null
  return obj
})

export const removeDuplicates = (array) => array.reduce((acc, current) => {
  const found = acc.find((obj) => obj.school_id === current.school_id)
  if (!found) {
    acc.push(current)
  } else if (!found.submitted_at && current.submitted_at) {
    found.submitted_at = current.submitted_at
  }
  return acc
}, [])

export const sortArray = (array) => {
  const sortedArray = array.sort((a, b) => {
    if (a.submitted_at === null && b.submitted_at === null) {
      return 0
    }
    if (a.submitted_at === null) {
      return 1
    }
    if (b.submitted_at === null) {
      return -1
    }
    return 0
  })

  return sortedArray
}

export const urlToSchool = (id) => {
  window.location.href = `welcome/school_route?school_id=${id}`
}

export const rangesSustainabilityTabs = [
  { start: 1, end: 6 },
  { start: 13, end: 15 },
  { start: 21, end: 24 },
  { start: 7, end: 12 },
  { start: 16, end: 20 },
  { start: 25, end: 25 },
  { start: 27, end: 27 },
  { start: 26, end: 26 },
]

export const formatDate = (inputDate) => {
  const date = new Date(inputDate)

  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  const year = date.getFullYear()

  const formattedDate = `${month}/${day}/${year}`

  return formattedDate
}

export const formatTimestamp = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp)
    const month = date.toLocaleString("en-US", { month: "long" })
    const year = date.getFullYear()
    return `${month}, ${year}`
  }
  return "N/A"
}
export const removeHtmlTags = (inputString) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(inputString, "text/html")
  return doc.body.textContent || ""
}

export const sortObjectsByOrder = (arr, order) => arr.sort((a, b) => {
  const orderA = order[a.name]
  const orderB = order[b.name]

  if (orderA !== undefined && orderB !== undefined) {
    return orderA - orderB
  }
  if (orderA !== undefined) {
    return -1
  }
  if (orderB !== undefined) {
    return 1
  }

  return 0
})

export const formatDateToMonthYear = (inputDate) => {
  const momentModifiedDate = moment(inputDate, "M/D/YYYY").format("MMM YYYY")
  return momentModifiedDate
}

export const customSortByRating = (objects) => {
  const ratingOrder = {
    "Well Developed": 1,
    Proficient: 2,
    Developing: 3,
    Initiating: 4,
    null: 5,
  }
  objects.sort((a, b) => {
    const ratingA = ratingOrder[a.rating]
    const ratingB = ratingOrder[b.rating]
    return ratingA - ratingB
  })
  return objects
}

export const filterByConnectionType = (data, connectionType) => {
  const filteredData = data.reduce((result, item) => {
    Object.keys(item.sel_standards).forEach((key) => {
      const connectionTypeValue = item.sel_standards[key].connection_type

      const studentsCanSupported = item.internal_selected_grades
        ? item.internal_selected_grades.reduce(
          (sum, gradeObj) => sum + (gradeObj.students_can_be_supported || 0),
          0,
        )
        : 0

      const studentsBeingSupported = item.internal_selected_grades
        ? item.internal_selected_grades.reduce(
          (sum, gradeObj) => sum + (gradeObj.students_being_supported || 0),
          0,
        )
        : 0

      if (
        connectionTypeValue === connectionType
        && studentsCanSupported !== null
        && studentsCanSupported !== undefined
        && studentsCanSupported !== ""
      ) {
        const existingItemIndex = result.findIndex(
          (existingItem) => existingItem.key === key,
        )

        if (existingItemIndex !== -1) {
          if (
            studentsCanSupported
            > result[existingItemIndex].students_can_supported
          ) {
            result[existingItemIndex] = {
              id: item.id,
              key,
              value: item.sel_standards[key],
              students_being_supported: studentsBeingSupported, // Now using calculated value based on tier_of_support
              students_can_supported: studentsCanSupported, // Now using calculated value based on tier_of_support
              activity: item.activity,
              category: item.category,
              sel_standards: item.sel_standards,
            }
          }
        } else {
          // If the key is not present in the result array, add it
          result.push({
            id: item.id,
            key,
            value: item.sel_standards[key],
            students_being_supported: studentsBeingSupported, // Now using calculated value based on tier_of_support
            students_can_supported: studentsCanSupported, // Now using calculated value based on tier_of_support
            activity: item.activity,
            category: item.category,
            sel_standards: item.sel_standards,
          })
        }
      }
    })
    return result
  }, [])

  return filteredData
}

export const sortKeys = (array) => array.sort((a, b) => {
  const getNumericPart = (str) => {
    const numericPart = parseInt(str.match(/\d+/), 10)
    return _.isNaN(numericPart) ? Infinity : numericPart
  }

  const numericA = getNumericPart(a)
  const numericB = getNumericPart(b)

  if (numericA !== numericB) {
    return numericA - numericB
  }

  return a.localeCompare(b)
})

export const sortObjectsByKey = (objectsArray) => objectsArray.sort((a, b) => {
  const keyA = a.key.toUpperCase()
  const keyB = b.key.toUpperCase()

  return keyA.localeCompare(keyB, undefined, {
    numeric: true,
    sensitivity: "base",
  })
})
export const extractKeys = (data) => {
  const keysArray = []

  data.forEach((category) => {
    const { standards } = category
    const categoryKeys = Object.keys(standards)

    keysArray.push(...categoryKeys)
  })
  return sortKeys(keysArray)
}

export const extractNames = (data) => {
  const namesArray = []

  data.forEach((ele) => {
    namesArray.push(ele.name)
  })
  return namesArray
}

export const mergeArrays = (array1, array2) => {
  const resultArray = []

  array1.forEach((obj1) => {
    const key1 = obj1.key
    let matchFound = false

    array2.forEach((obj2) => {
      const key2 = Object.keys(obj2)[0]

      if (key1 === key2) {
        matchFound = true
        const mergedObj = { ...obj1, [key2]: obj2[key2] }
        resultArray.push(mergedObj)
      }
    })

    if (!matchFound) {
      resultArray.push(obj1)
    }
  })

  array2.forEach((obj2) => {
    const key2 = Object.keys(obj2)[0]
    const keyExists = resultArray.some((obj) => obj[key2])

    if (!keyExists) {
      const newObj = { key: key2 }
      newObj[key2] = obj2[key2]
      resultArray.push(newObj)
    }
  })

  resultArray.sort((a, b) => (a.key > b.key ? 1 : -1))

  return resultArray
}

export const filterMaxStudents = (data) => {
  const filteredData = {}

  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const valueList = data[key]
      const maxStudentsValue = valueList.reduce(
        (max, item) => (item.sum_students_can_supported > max.sum_students_can_supported
          ? item
          : max),
        valueList[0],
      )
      filteredData[key] = [maxStudentsValue]
    }
  })

  return filteredData
}

export const makeCompetencyData = (STANDARDS, CURRENT_SCHOOL_ACTIVITIES) => {
  const finalArray = {}

  STANDARDS.forEach((standardGroup) => {
    const groupName = standardGroup.name
    finalArray[groupName] = []

    Object.keys(standardGroup.standards).forEach((key) => {
      CURRENT_SCHOOL_ACTIVITIES.forEach((activity) => {
        if (activity.sel_standards && activity.sel_standards[key]) {
          finalArray[groupName].push(activity)
        }
      })
    })
  })

  return finalArray
}

export const filterAndRemoveEmptyStandards = (data, connectionType) => {
  const filteredData = data.map((item) => {
    const selStandards = {}

    Object.keys(item.sel_standards).forEach((key) => {
      if (item.sel_standards[key].connection_type === connectionType) {
        selStandards[key] = item.sel_standards[key]
      }
    })

    return {
      ...item,
      sel_standards: selStandards,
    }
  })

  // Remove items with empty sel_standards
  const finalFilteredData = filteredData.filter(
    (item) => Object.keys(item.sel_standards).length > 0,
  )

  return finalFilteredData
}

export const extractStudentCounts = (dataObject) => {
  const keys = Object.keys(dataObject)
  const counts = []

  keys.forEach((key) => {
    const category = dataObject[key]

    if (Array.isArray(category)) {
      const studentCount = category
        .filter((item) => item && item.sum_students_can_supported !== null)
        .map((item) => item.sum_students_can_supported || 0)
        .reduce((sum, count) => sum + count, 0)

      counts.push(studentCount)
    }
  })

  return counts
}

export const convertObjectIntoArray = (obj) => Object.entries(obj).map(([key, value]) => ({ [key]: value }))

export const processDataForDumbbellChart = (originalData) => {
  const filteredData = originalData.filter(
    (record) => record.tier_of_support !== "Universal",
  )

  const uniqueActivities = {}
  filteredData.forEach((record) => {
    const { activity } = record
    if (
      !uniqueActivities[activity]
      || record.sum_students_can_supported
        > uniqueActivities[activity].sum_students_can_supported
    ) {
      uniqueActivities[activity] = record
    }
  })

  const result = Object.values(uniqueActivities).map((record) => ({
    x: record.activity,
    y: [record.sum_students_being_supported, record.sum_students_can_supported],
    completeObject: record,
  }))

  return result.filter((obj) => {
    if (obj.y.some((value) => value === null)) {
      return false
    }
    return true
  })
}

export const organizeArrayByStandard = (inputArray) => {
  const organizedArray = {}
  inputArray.forEach((item) => {
    const standardKeys = Object.keys(item.sel_standards)
    standardKeys.forEach((key) => {
      if (!organizedArray[key]) {
        organizedArray[key] = []
      }
      organizedArray[key].push(item)
    })
  })

  return organizedArray
}

export const mergeArraysToObjectKeys = (object, arrayKeys) => arrayKeys.map((key) => ({
  [key]: object[key] || [],
}))

export const filterNullStudents = (objects) => objects.filter(
  (obj) => obj.can_students_being_supported !== null
      && obj.can_students_can_supported !== null,
)

export const removeUniversalTier = (objects) => objects.filter((obj) => obj.tier_of_support !== "Universal")

export const sortArrayBasedOnToggle = (arrayData, toggle) => arrayData.map((obj) => {
  const newObj = { ...obj }

  newObj.sel_standards = Object.keys(newObj.sel_standards)
    .filter((key) => newObj.sel_standards[key].connection_type === toggle)
    .reduce((acc, key) => {
      acc[key] = newObj.sel_standards[key]
      return acc
    }, {})

  return newObj
})

export const removeParamProgramIdFromURL = (url) => {
  const urlObject = new URL(url)
  urlObject.searchParams.delete("program_id")
  return urlObject.toString()
}

export const findPreviousMonthRecord = (data) => {
  if (_.isNull(data)) return null

  // Sort the array by updated_at in ascending order
  const sortedData = data.sort(
    (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
  )

  // Get the month from the last element's updated_at value
  const lastMonth = new Date(sortedData[sortedData.length - 1].updated_at).getMonth() + 1

  // Find the previous month starting from the second last element
  for (let i = sortedData.length - 2; i >= 0; i -= 1) {
    const currentMonth = new Date(sortedData[i].updated_at).getMonth() + 1
    if (currentMonth !== lastMonth) {
      return sortedData[i]
    }
  }

  // If no previous month is found, return the last element
  return sortedData[sortedData.length - 1]
}
export const graphLabelObject = [
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Overall",
]
export const totalBarIndex = {
  overall: 4,
}
export const isTenureCompleted = (createdAt) => {
  // Convert created_at and current_date strings to Date objects
  const createdDate = new Date(createdAt)
  const currentDate = new Date()
  // Calculate the tenure completion date
  const tenureCompletionDate = new Date(currentDate.getFullYear(), 7, 1)
  if (currentDate.getMonth() < 7) {
    tenureCompletionDate.setFullYear(currentDate.getFullYear() - 1)
  }

  // Check if the created date is before or on the tenure completion date
  return createdDate <= tenureCompletionDate
}

export const filterBySchoolId = (schoolId, data) => data.filter((record) => record.schools.some((school) => school.id === schoolId))

export const extractSchoolsFromPrograms = (data) => {
  let schoolsList = []
  data.forEach((item) => {
    if (Array.isArray(item.schools)) {
      schoolsList = schoolsList.concat(item.schools)
    }
  })

  return schoolsList
}

export const removeDuplicateSchoolsDropdown = (data) => {
  const seenIds = new Set()
  const uniqueData = []

  data.forEach((item) => {
    if (!seenIds.has(item.id)) {
      seenIds.add(item.id)
      uniqueData.push(item)
    }
  })

  return uniqueData
}

export const makeDessaArray = (data) => {
  const result = []

  data.forEach((item) => {
    Object.entries(item.standards).forEach(([key, value]) => {
      if (Object.prototype.hasOwnProperty.call(item.standards, key)) {
        result.push({
          abbreviation: key,
          explanation: value.student_indicator_language.trim(),
        })
      }
    })
  })

  return result
}

export const countAttributes = (arr) => {
  const counts = { guidance: 0, other: 0 }

  arr.forEach((item) => {
    if (item.guidance === true) {
      counts.guidance += 1
    }
    if (item.guidance !== true) {
      counts.other += 1
    }
  })

  return [
    { id: 1, title: "Guidance", count: counts.guidance },
    { id: 2, title: "Other", count: counts.other },
  ]
}

export const filterRecordsById = (id, records) => {
  if (id === 1) {
    return records.filter((record) => record.guidance === true)
  }
  if (id === 2) {
    return records.filter(
      (record) => record.guidance === false || record.guidance === null,
    )
  }
  return []
}

export const sortOnCategory = (searchWord, array) => array.filter((record) => {
  // Check if searchWord is "N/A" and name_section is empty
  if (searchWord.toLowerCase() === "n/a") {
    return record.name_section.length === 0
  }
  // Otherwise, check if any section contains the searchWord
  return record.name_section.some((section) => section.toLowerCase().includes(searchWord.toLowerCase()))
})

export const removeDuplicateMonths = (dataArray) => {
  // Return an empty array if dataArray is null
  if (!dataArray) {
    return []
  }

  // Create a map to store the latest entry for each month
  const latestEntries = new Map()

  dataArray.forEach((entry) => {
    // Extract year and month from updated_at field
    const date = new Date(entry.updated_at)
    const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`

    // If there is no entry for this year-month or the current entry is newer, update the map
    if (
      !latestEntries.has(yearMonth)
      || new Date(latestEntries.get(yearMonth).updated_at) < date
    ) {
      latestEntries.set(yearMonth, entry)
    }
  })

  // Convert the map values back to an array
  return Array.from(latestEntries.values())
}

export const getStageCounts = (arr) => arr.reduce((acc, curr) => {
  const { stage } = curr
  if (stage) {
    acc[stage] = (acc[stage] || 0) + 1
  }
  return acc
}, {})

export const filterByGradeLevel = (dataArray, grade) => dataArray.filter((item) => item.internal_selected_grades.some((gradeObj) => gradeObj.grade === grade))

export const filterByGradeLevelInCategories = (data, grade) => {
  const result = {}

  // Iterate over each category in the data
  Object.keys(data).forEach((category) => {
    // Filter the array of objects based on internal_selected_grades
    const filteredItems = data[category].filter((item) => item.internal_selected_grades.some((gradeObj) => gradeObj.grade === grade))

    // Assign the filtered array to the category, even if it's empty
    result[category] = filteredItems
  })

  return result
}

export const isArrayNullOrEmpty = (arr) => {
  if (arr == null || arr.length === 0 || arr.some((value) => value === null)) {
    return true
  }

  return arr.every((element) => element === null)
}

export const formatReleaseDateTime = (updatedAt, createdAt) => {
  const date = new Date(updatedAt || createdAt)
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })
  const formattedTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })

  return { formattedDate, formattedTime }
}

export const formatDateforVideo = (dateString) => new Date(dateString).toLocaleDateString("en-US", {
  day: "numeric",
  month: "short",
  year: "numeric",
})
