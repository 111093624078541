export const uaRed = "166, 25, 46"
export const uaGray = "126, 124, 125"
export const uaTeal = "12, 164, 165"
export const uaDarkBlue = "51, 72, 93"
export const uaGold = "231, 169, 34"
export const uaSilver = "224, 226, 227"

export const uaTealHex = "#0EA4A5"
export const uaGoldHex = "#E7A922"
export const uaDarkBlueHex = "#33485D"
