import {
  Col, Form, Select, Tooltip, Card,
} from "antd"
import React from "react"
import componentStyles from "../../styles"

const { Option } = Select

function FormItemSelectCell(props) {
  const { border = "1px solid #000000", handleOnDeselect = () => {} } = props
  const selectStyle = {
    ...componentStyles.selectStyle,
    border,
  }

  const content = (
    <Col
      xs={24}
      sm={24}
      md={props.colDimensions ? props.colDimensions.md : 8}
      lg={props.colDimensions ? props.colDimensions.lg : 8}
    >
      <Form.Item
        label={props.label}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={props.name}
        rules={props.rules}
        key={props.keys}
        initialValue={props.defaultValues}
      >
        <Select
          onDeselect={(value) => {
            handleOnDeselect(value)
          }}
          allowClear={!!props.allowClear}
          id={props.name}
          name={props.name}
          mode={props.mode === "multiple" ? "multiple" : null}
          disabled={props.disabled}
          showSearch
          style={selectStyle}
          bordered={false}
          placeholder={props.placeholder}
          optionFilterProp="children"
          onChange={(key, val) => props.handleOnChangeSelect(key, val)}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase())
            >= 0}
          maxTagCount={3}
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{
                pointerEvents: "none",
              }}
              title={omittedValues.map(({ label }) => label).join(", ")}
            >
              <span>{`+${omittedValues.length} more`}</span>
            </Tooltip>
          )}
        >
          {props.isDetail
            ? props.values
              && props.values.map((value, index) => (
                <Option key={index} value={value.value} name={props.name}>
                  {value.detail}
                </Option>
              ))
            : props.values
              && props.values.map((value, index) => (
                <Option key={index} value={value} name={props.name}>
                  {value}
                </Option>
              ))}
        </Select>
      </Form.Item>
    </Col>
  )

  return props.showCard ? (
    <Card style={componentStyles.cardStyle}>{content}</Card>
  ) : (
    content
  )
}

export default FormItemSelectCell
