import React from "react"
import _ from "underscore"

function SustainabilityReport({ rubric }) {
  const generatePDF = () => {
    window.open(`/sustainability_rubrics/${rubric.id}/report.pdf`)
  }
  const fileList = (files) => (
    <ul>
      {files.map((file, index) => (
        <li className="evidence mb-3" key={index}>
          <a href={file.url} target="_blank" className="mb-2" rel="noreferrer">
            {file.description || file.filename}
          </a>
          {file.previewable && (
            <a href={file.url} target="_blank" rel="noreferrer">
              <object
                data={`${file.url}#scrollbar=0&toolbar=0&navpanes=0`}
                type={file.content_type}
                width="40%"
              />
            </a>
          )}
        </li>
      ))}
    </ul>
  )

  const showExampleArtifacts = (area) => area.example_artifacts.length > 0
    && (area.rating === "Initiating" || area.rating === "Developing")

  const renderArea = (area) => (
    <div className="area" key={area.name}>
      <h5 className="font-weight-bold mb-1">{area.name}</h5>
      <p className="font-italic mb-3">{area.body}</p>
      <p className="text-uppercase mb-3 bg-dark text-light px-2 py-1 d-inline-block">
        {area.rating || "Ungraded"}
      </p>
      {area.explanations.length > 0 && (
        <div>
          <p className="font-italic mb-1">Evidence:</p>
          <ul>
            {area.explanations.map((explanation, index) => (explanation.__html ? (
              <li key={index} dangerouslySetInnerHTML={explanation} />
            ) : null))}
          </ul>
        </div>
      )}
      {area.evidence.length > 0 && (
        <div>
          <p className="font-italic mb-1">Artifacts:</p>
          {fileList(area.evidence)}
        </div>
      )}
      {showExampleArtifacts(area) && (
        <div>
          <p className="font-italic mb-1">Example artifacts:</p>
          {fileList(area.example_artifacts)}
        </div>
      )}
      {area.areas_of_strength && (
        <div className="mb-3">
          <p className="font-italic mb-1">Areas of strength:</p>
          {area.areas_of_strength}
        </div>
      )}
      {area.opportunities_for_growth && (
        <div>
          <p className="font-italic mb-1">Opportunities for growth:</p>
          {area.opportunities_for_growth}
        </div>
      )}
    </div>
  )

  const gradedAreasCount = (report) => {
    const count = _.filter(report, (area) => area.rating).length
    return (
      <div>
        <h6 className="font-weight-bold bg-warning px-2 py-1 d-inline-block">
          ({count} of {report.length} areas graded)
        </h6>
      </div>
    )
  }

  return (
    <div>
      <i className="fa fa-file-pdf-o" onClick={generatePDF} />
      <div className="report sustainability">
        <h3 className="text-uppercase font-weight-bold mb-3 bg-dark text-light px-2 py-1 d-inline-block">
          {rubric.rating || "Ungraded"}
        </h3>
        {gradedAreasCount(rubric.report)}
        {/* {rubric.report.map((area) => renderArea(area))} */}
        {rubric.report.map((area) => {
          console.log(area) // Add this line to log the 'area' object
          return renderArea(area)
        })}
      </div>
    </div>
  )
}

export default SustainabilityReport
