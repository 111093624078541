import React from "react"
import commonStrings from "../../commonStrings"

function CustomLine({ side, color }) {
  const classNames = `custom-line ${side}-custom-line`
  const lineStyle = {
    backgroundColor: color,
  }
  return <div className={classNames} style={lineStyle} />
}
function renderArea(area, reportName) {
  const colorMapping = {
    range: "#E7A922",
    coordination: "#E7A922",
    "program fit/reinvention": "#0CA4A5",
  }

  const lowerReportName = reportName.toLowerCase()
  const defaultColor = "#A6192E"

  const verticalLineColor = colorMapping[lowerReportName] || defaultColor
  const heading1Color = verticalLineColor
  const heading2Color = verticalLineColor
  return (
    <div className="main-container">
      <div className="left-div">
        <div className="heading1" style={{ color: heading1Color }}>
          <span>{commonStrings.areasOfStrength}</span>
        </div>
        <CustomLine side="left" color={verticalLineColor} />
        {area.areas_of_strength && (
          <div className="heading1-content">
            <ul>
              {area.areas_of_strength.split("\n").map((item, index) => {
                if (item.trim() === "") {
                  return null
                }
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
        )}
      </div>
      <div
        className="vertical-line"
        style={{ backgroundColor: verticalLineColor }}
      />

      <div className="right-div">
        <div className="heading2" style={{ color: heading2Color }}>
          <span>{commonStrings.areasOfNeed}</span>
        </div>
        <CustomLine side="right" color={verticalLineColor} />
        {area.opportunities_for_growth && (
          <div className="heading2-content">
            <ul>
              {area.opportunities_for_growth.split("\n").map((item, index) => {
                if (item.trim() === "") {
                  return null
                }
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

function ReportContent({ area, reportName }) {
  const renderedArea = renderArea(area, reportName)
  return <div className="main-container">{renderedArea}</div>
}

export default ReportContent
