import React from "react"

function ResourceFileList({ resources }) {
  return (
    <ul>
      {resources.map((resource, index) => (
        <li key={index}>
          <a className="name" href={`/resources/${resource.id}`} target="_blank" rel="noreferrer">{resource.description}</a>
          <span> (<a className="styling-none" href={`mailto:${resource.user_email}`}><i>{resource.user_full_name}</i></a>{resource.school_name && <span>, {resource.school_name}</span>})</span>
        </li>
      ))}
    </ul>
  )
}

export default ResourceFileList
