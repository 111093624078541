import React from "react"
import {
  Modal, Button, Col, Row,
} from "antd"
import commonStrings from "../commonStrings"

const ReleaseVideoModal = ({ openStatus, setOpenStatus, videoUrl }) => (
  <Modal
    keyboard
    width={1000}
    title={<div className="noSchoolModal">{commonStrings.newFeature}</div>}
    open={openStatus}
    closable
    onCancel={() => {
      setOpenStatus(false)
    }}
    destroyOnClose
    footer={[
      <Button
        className="noSchoolModalButton"
        onClick={() => {
          setOpenStatus(false)
        }}
      >
        Close
      </Button>,
    ]}
    centered
  >
    <Row justify="center">
      <Col xs={24} sm={24} md={24} lg={24} className="video-modal-size">
        {videoUrl ? (
          <video
            width="100%"
            height="100%"
            controls
            className="videos-section"
          >
            <track kind="captions" srcLang="en" label="English captions" />
            <source src={videoUrl} type="video/mp4" />
            {commonStrings.browserSupport}
          </video>
        ) : (
          <p>{commonStrings.noVideoavailable}</p>
        )}
      </Col>
    </Row>
  </Modal>
)

export default ReleaseVideoModal
