import React from "react"
import videoSvgGrey from "../../assets/images/video-square-grey.svg"
import videoSvgBlack from "../../assets/images/video-square-black.svg"
import CommonColors from "../commonColors"

const VideoTabs = ({
  title, isSelected, setIsSelected, count, id,
}) => {
  const buttonsStyles = {
    height: "43px",
    borderRadius: "3px",
    padding: "7px",
    fontWeight: 700,
    fontSize: "16px",
    cursor: "pointer",
  }

  const selectedButtonColor = {
    backgroundColor: CommonColors.UA_RED,
  }
  const unSelectedColor = {
    backgroundColor: CommonColors.UA_GREY,
  }
  return (
    <div
      style={{
        ...buttonsStyles,
        ...(isSelected === id ? selectedButtonColor : unSelectedColor),
      }}
      className="mx-1"
      onClick={() => {
        setIsSelected(id)
      }}
    >
      <div className="d-flex align-items-center">
        <div>
          <img
            className="mr-1 video-play-size"
            src={isSelected === id ? videoSvgGrey : videoSvgBlack}
          />
        </div>
        <div
          style={{
            color:
              isSelected === id
                ? CommonColors.UA_WHITE
                : CommonColors.UA_BLUE_DARK,
            marginTop: "2px",
          }}
        >
          {`${title} (${count})`}
        </div>
      </div>
    </div>
  )
}

export default VideoTabs
