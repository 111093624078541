import React, { useState, useRef, useEffect } from "react"
import {
  Col, Form, Card, Radio, Row,
} from "antd"
import _ from "underscore"
import TextareaAutosize from "react-textarea-autosize"
import componentStyles from "../../styles"
import rules from "../../validations"
import commonStrings from "../../commonStrings"

function SELIndicator({
  form, item, handleOnChange, value, removeStandard,
}) {
  const standards = (key) => {
    const hash = {}
    _.pluck(window.gon.SEL_STANDARDS, "standards").forEach((array) => {
      _.extend(hash, array)
    })
    return key ? hash[key][item.adult ? "adult_indicator_language" : "student_indicator_language"] : hash
  }
  const [explanation, setExplanation] = useState(
    item.sel_standards[value].connection_explanation || "",
  )
  const controlRef = useRef()
  useEffect(() => {
    setExplanation(item.sel_standards[value].connection_explanation || "")
  }, [item.sel_standards, value])
  const handleValueChange = (e) => {
    setExplanation(e.target.value)
    handleOnChange(e)
  }
  const handleOnChangeRadio = (e) => {
    let temp = ""
    if (item.sel_standards[value].connection_explanation) {
      if (
        item.sel_standards[value].connection_explanation.includes("promotes")
      ) {
        temp = item.sel_standards[value].connection_explanation.replace(
          "promotes",
          e.target.value,
        )
      } else if (
        item.sel_standards[value].connection_explanation.includes("reinforces")
      ) {
        temp = item.sel_standards[value].connection_explanation.replace(
          "reinforces",
          e.target.value,
        )
      }
    }
    setExplanation(temp)
    const newItem = { ...item }
    newItem.sel_standards[value][controlRef.current.name] = temp
    APIClient.put(`/program_matrix/program_matrix_items/${newItem.id}`, {
      sel_standards: newItem.sel_standards,
    })
    handleOnChange(e)
  }
  const connectionsInputs = () => (
    <div key={value} className="sel-standard">
      <div className="form-group">
        <Form.Item
          name={`${value}}`}
          rules={rules.selectRequired}
          initialValue={item.sel_standards[value].connection_type || "promotes"}
        >
          <Radio.Group
            onChange={handleOnChangeRadio}
            name="connection_type"
            value={item.sel_standards[value].connection_type || ""}
          >
            <Radio name="radio1" value="promotes" ast={value}>
              {commonStrings.promotesCap}
            </Radio>
            <Radio name="radio2" value="reinforces" ast={value}>
              {commonStrings.reinforcesCap}
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="hint">
        <ul>
          <li>
            <span className="font-weight-bold">
              {commonStrings.promotesCap}
            </span>
            : {commonStrings.socialEmotional}
          </li>
          <li>
            <span className="font-weight-bold">
              {commonStrings.reinforcesCap}
            </span>
            : {commonStrings.socialEmotionalExperience}
          </li>
        </ul>
      </div>
      <div className="form-group">
        <Col xs={24} style={componentStyles.mb5}>
          <span style={componentStyles.titleStyle}>
            {`Describe how this activity ${item.sel_standards[value].connection_type} this`}
          </span>
        </Col>
        <TextareaAutosize
          ref={controlRef}
          style={componentStyles.inputStyle}
          className="form-control"
          id="connection_explanation"
          name="connection_explanation"
          value={
            explanation
            || `${item.activity} ${item.sel_standards[value].connection_type} ${standards(value).replace(/[^\w\s]+$/, "").toLowerCase()} by`
          }
          data-sel-standard-key={value}
          onChange={handleValueChange}
        />
        {!item.sel_standards[value].connection_explanation && (
          <p className="text-danger p-2">{commonStrings.completePrompt}</p>
        )}
      </div>
    </div>
  )

  return (
    <Col className="container sel_Indicator" xs={24} sm={24} md={24} lg={24}>
      <Card
        style={componentStyles.cardStyle}
        title={(
          <Row justify="space-between">
            <Col sm={23}>
              <h5>
                {value}. {standards(value)}
              </h5>
            </Col>
            <Col xs={1}>
              <i
                style={componentStyles.changeCursor}
                className="fa-solid fa-trash-can ml-2"
                onClick={() => removeStandard(value)}
              />
            </Col>
          </Row>
        )}
      >
        <Form layout="vertical" form={form}>
          {connectionsInputs()}
        </Form>
      </Card>
    </Col>
  )
}

export default SELIndicator
