import React, { useEffect, useState } from "react"
import {
  Drawer, Space, Row, Col, Table, Button,
} from "antd"
import { CSVLink } from "react-csv"
import "../../../assets/stylesheets/partials/system_admins.scss"
import moment from "moment"
import commonStrings from "../../commonStrings"
import {
  idIntoSchoolId,
  addMissingProperty,
  removeDuplicates,
  sortArray,
} from "../../utilities"

const DrawerTable = (props) => {
  const {
    open,
    setOpen,
    title,
    generalColor,
    tableListData,
    tableColumns,
    headers,
    isCollapseBody,
    rubrics,
  } = props

  const [refinedDataForTable, setRefinedDataForTable] = useState([])

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setRefinedDataForTable(
      sortArray(
        removeDuplicates(addMissingProperty(idIntoSchoolId(tableListData))),
      ),
    )
  }, [tableListData])

  const extractCsvObject = () => {
    const array = []
    refinedDataForTable.map((value) => {
      if (value.name) {
        if (isCollapseBody) {
          if (rubrics === "matrix") {
            array.push({
              name: value.name,
              Self_Awareness: value.matrix_hash.Self_Awareness,
              Self_Management: value.matrix_hash.Self_Management,
              Social_Awareness: value.matrix_hash.Social_Awareness,
              Social_Management: value.matrix_hash.Social_Management,
            })
          } else if (rubrics === "implementation") {
            array.push({
              name: value.name,
              Commitment_Stage_I: value.results.Commitment_Stage_I,
              Commitment_Stage_II: value.results.Commitment_Stage_II,
              Implementation_Stage_I: value.results.Implementation_Stage_I,
              Implementation_Stage_II: value.results.Implementation_Stage_II,
            })
          }
        } else {
          array.push({
            name: value.name,
            submitted_at: value.submitted_at
              ? moment.utc(value.submitted_at).format("MM/DD/YYYY")
              : "N/A",
          })
        }
      }
      return 0
    })

    return array
  }

  return (
    <div>
      <Drawer
        title={(
          <span
            style={{ fontSize: "30px", fontWeight: "600", color: generalColor }}
          >
            {`${title} Submissions`}
          </span>
        )}
        placement="top"
        height="80%"
        closable
        onClose={onClose}
        destroyOnClose
        open={open}
        mask
        maskClosable
        className="titleSubmission"
        // style={{
        //   width: "70%",
        //   //  position: "absolute",
        //   left: "50%",
        //   transform: "translateX(-50%)",
        // }}
        extra={(
          <Space>
            <CSVLink
              data={extractCsvObject()}
              headers={headers}
              style={{ backgroundColor: generalColor }}
              className="csvDownloadBtn"
              filename={`${title}_submissions.csv`}
            >
              {commonStrings.exportToCsv}
            </CSVLink>
          </Space>
        )}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={17} lg={17}>
            <Table
              className="table-design table-striped-rows drawer-table"
              columns={tableColumns}
              dataSource={refinedDataForTable}
              size="middle"
              scroll={{ y: 470 }}
            />
          </Col>
        </Row>

        <div className="closeButtonDiv">
          <Button
            className="btnClose"
            onClick={() => {
              onClose()
            }}
          >
            {commonStrings.close}
          </Button>
        </div>
      </Drawer>
    </div>
  )
}

export default DrawerTable
