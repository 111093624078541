import React, { useEffect } from "react"
import _ from "underscore"
// import ProgramMatrixFilters from "./Filters"

function DessaReport({ matrix }) {
  const filters = {
    categories: [],
    tiers_of_support: [],
    activities: [],
    dessa_competencies: [],
  }
  // const [filters] = useState({
  //   categories: [], tiers_of_support: [], activities: [], dessa_competencies: [],
  // })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const matchingItems = (key, type) => {
    let items = _.filter(
      matrix.completed_items,
      (item) => item.adult === (type === "adult")
        && _.contains(_.keys(item.sel_standards), key),
    )
    if (filters.categories.length) {
      items = _.filter(items, (item) => _.contains(filters.categories, item.category))
    }
    if (filters.tiers_of_support.length) {
      items = _.filter(items, (item) => _.contains(filters.tiers_of_support, item.tier_of_support))
    }
    if (filters.activities.length) {
      items = _.filter(items, (item) => _.contains(filters.activities, item.activity))
    }
    return items
  }

  const itemDetail = (item) => ["category", "tier_of_support", "length_of_time"]
    .map((attr, index) => (item[attr] ? (
      <span
        key={index}
        className={
              attr === "tier_of_support" ? item[attr].toLowerCase() : null
            }
      >
        {item[attr]}
      </span>
    ) : null))
    .filter((el) => el != null)
    .reduce((prev, curr) => [prev, ", ", curr])

  const boldFirstWord = (string) => string
    .split(" ")
    .map((s, i) => (i === 0 ? <b>{s}</b> : <span>{s}</span>))
    .reduce((prev, curr) => [prev, " ", curr])

  const renderStandard = (key, standard) => (
    <div key={key} className="mb-4">
      <h5 className="mb-1">
        {key}. {boldFirstWord(standard.student_indicator_language)}
      </h5>
      <ul className="mb-3 ml-5">
        {matchingItems(key, "student").map((item, index) => (
          <li key={index}>
            {item.activity} ({itemDetail(item)})
          </li>
        ))}
      </ul>
      <h5 className="d-flex mb-1">
        <span style={{ visibility: "hidden" }}>{key}.&nbsp;</span>
        <span>{boldFirstWord(standard.adult_indicator_language)}</span>
      </h5>
      <ul className="mb-3 ml-5">
        {matchingItems(key, "adult").map((item, index) => (
          <li key={index}>
            {item.activity} ({itemDetail(item)})
          </li>
        ))}
      </ul>
    </div>
  )

  const selStandardGroup = (group) => {
    console.log("Group", group)
    const dessaCompetencies = _.uniq(_.flatten(filters.dessa_competencies))
    const standards = _.compact(
      _.keys(group.standards).map((key) => {
        if (
          dessaCompetencies.length === 0
          || dessaCompetencies.find((k) => k === key)
        ) {
          return renderStandard(key, group.standards[key])
        }
        return null
      }),
    )

    if (standards.length > 0) {
      return (
        <div key={group.name} className="group p-4">
          {standards}
        </div>
      )
    }

    return null
  }

  return (
    <div>
      <div className="report program-matrix">
        {/* <ProgramMatrixFilters
          filters={filters}
          setFilters={setFilters}
          activities={_.pluck(matrix.completed_items, "activity")}
        /> */}
        {window.gon.DESS_SEL_STANDARDS.map((group) => selStandardGroup(group))}
      </div>
    </div>
  )
}

export default DessaReport
