import React, { useState } from "react"

function MetricForm() {
  const [name, setName] = useState("")
  const [saving, setSaving] = useState(false)

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setSaving(true)
    APIClient.post(
      "/metrics",
      { name: $.trim(name) },
      (response) => {
        window.location.href = `/metrics/${response.id}`
      },
    )
  }

  return (
    <div>
      <form className="metrics" onSubmit={handleOnSubmit}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Name of metric…"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={saving}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={$.trim(name) === "" || saving}
        >
          {saving ? "Creating…" : "Create"}
        </button>
      </form>
    </div>
  )
}

export default MetricForm
