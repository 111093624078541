import React, { useEffect, useState } from "react"
import ReactDOMServer from "react-dom/server"
import _ from "underscore"
import DoughnutChart from "../charts/DoughnutChart"
import PieChart from "../charts/PieChart"
import TChart from "../charts/TChart"
// import BarChart from "../charts/OldBarChart"
import {
  uaDarkBlue, uaGold, uaGray, uaRed, uaTeal,
} from "../../enums"
import TypeToggle from "./TypeToggle"
import { infoAlert } from "../../utilities"
import ProgressBar from "../charts/ProgressBar"
import commonStrings from "../../commonStrings"
// import BarChart from "../charts/BarChart"
import Gauge from "../Gauge"
import BarChart from "../charts/SELQualityBarChart"

function ProgramMatrixSELQuality({ matrix, submissionsCount, schoolsCount }) {
  const [type, setType] = useState("student")
  useEffect(() => {
    $("[data-toggle=\"tooltip\"]").tooltip("dispose").tooltip()
  }, [type])

  const percent = (a, b) => `${Math.round((a / b) * 100)}%`

  const standards = _.memoize(() => {
    const hash = {}
    _.pluck(window.gon.SEL_STANDARDS, "standards").forEach((array) => {
      _.extend(hash, array)
    })
    return hash
  })

  const r = matrix.quality_report[type]
  const activitiesCount = r.items.length
  const p = percent

  const growthScoreDataSet = (semester) => {
    const scores = r.growth_scores[semester.toLowerCase()] || {}
    const dates = semester === "Fall" ? "(Aug-Jan)" : "(Feb-Jul)"
    return {
      label: `${semester} ${dates}`,
      backgroundColor:
        semester === "Spring"
          ? `rgba(${uaTeal}, 0.75)`
          : `rgba(${uaGray}, 0.75)`,
      data: [
        scores.activities,
        scores.sel_indicators,
        scores.universal,
        scores.targeted,
        scores.tertiary,
      ],
    }
  }
  const growthScoresDataSets = [
    growthScoreDataSet("Fall"),
    growthScoreDataSet("Spring"),
  ]

  const universalSupportKeys = r.standards_with_universal_support
  const column1Items = universalSupportKeys.map((key) => (
    <>
      <b>{key}</b>:{" "}
      {type === "student"
        ? standards()[key].student_indicator_language
        : standards()[key].adult_indicator_language}
    </>
  ))
  const column2Items = _.difference(
    Object.keys(standards()),
    universalSupportKeys,
  ).map((key) => (
    <>
      <b>{key}</b>:{" "}
      {type === "student"
        ? standards()[key].student_indicator_language
        : standards()[key].adult_indicator_language}
    </>
  ))
  const infoAlertItemList = (items) => (
    <ul className="text-left" style={{ maxHeight: "400px", overflowY: "auto" }}>
      {items.map((item, i) => (
        <li key={i} className="mb-2 leading-tight">
          {item}
        </li>
      ))}
    </ul>
  )

  const handleIndicatorsWithUniversalSupportClick = (index) => {
    const items = index === 0 ? column1Items : column2Items
    const title = index === 0
      ? "Universally supported indicators"
      : "Indicators not yet with universal support"
    infoAlert({
      title,
      html: ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    })
  }

  const handleActivitiesWithDataClick = (index) => {
    const title = index === 0 ? "Activities with Data" : "Activities without Data"
    const items = r.items
      .filter((i) => (index === 0 ? !!i.data_collection : !i.data_collection))
      .map((i) => i.activity)
    infoAlert({
      title,
      html: ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    })
  }

  const handlePromoteReinforceClick = (index) => {
    const title = index === 0
      ? "Indicators that Promote SEL"
      : "Indicators that Reinforce SEL"
    const array = index === 0 ? r.standards_that_promote : r.standards_that_reinforce
    const items = array.map((key) => (
      <>
        <b>{key}</b>:{" "}
        {type === "student"
          ? standards()[key].student_indicator_language
          : standards()[key].adult_indicator_language}
      </>
    ))
    infoAlert({
      title,
      html: ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    })
  }

  const handleTierOfSupportClick = (index) => {
    const tier = ["Universal", "Targeted", "Tertiary"][index]
    const title = `Activities with ${tier} Support`
    const items = r[`items_with_${tier.toLowerCase()}_support`].map(
      (i) => i.activity,
    )
    infoAlert({
      title,
      html: ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    })
  }

  const handleActivitiesCountClick = (index) => {
    const key = Object.keys(r.activities_by_competency)[index]
    const items = r.activities_by_competency[key].map((i) => i.activity)
    infoAlert({
      title: key,
      html: ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    })
  }

  const handleDomainActivityCountClick = (index) => {
    const title = index === 0
      ? "Schools with at least 5 activities per domain"
      : "Schools without at least 5 activities per domain"
    const items = r.schools_by_domain_activity_count[index].map((i) => i.name)
    infoAlert({
      title,
      html: ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    })
  }

  const handleActivitiesByDESSAClick = (index) => {
    const key = Object.keys(r.activities_by_dessa)[index]
    const items = r.activities_by_dessa[key].map((i) => i.activity)
    infoAlert({
      title: key,
      html: ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    })
  }

  return (
    <div>
      <div className="report sel-quality program-matrix">
        {matrix.quality_report.adult.items.length > 0 && (
          <div className="group py-4">
            <TypeToggle type={type} setType={setType} />
          </div>
        )}
        {submissionsCount && (
          <div className="px-4 py-4">
            <div className="row">
              <div className="col-6">
                <h4>{commonStrings.schoolWithMatrix}</h4>
                <ProgressBar
                  progress={(submissionsCount / schoolsCount) * 100}
                  tooltip={`${submissionsCount} of ${schoolsCount} schools have submitted program matrices this school year`}
                  tooltipPlacement="bottom"
                />
              </div>
              <div className="col-6">
                <h4>Schools with at least 5 activities in each domain</h4>
                <ProgressBar
                  progress={
                    (r.schools_by_domain_activity_count[0].length
                      / submissionsCount)
                    * 100
                  }
                  tooltip={`${r.schools_by_domain_activity_count[0].length} of ${submissionsCount} schools`}
                  tooltipPlacement="bottom"
                  onClick={handleDomainActivityCountClick}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className="group px-4 py-4 SEL_quality_background"
          style={{ backgroundColor: "white" }}
        >
          <div className="row">
            <div className={`col-4 ${type === "adult" ? "offset-md-4" : null}`}>
              {!submissionsCount && (
                <>
                  <h4>
                    Universal support indicators:{" "}
                    <b>
                      {p(
                        r.standards_with_universal_support.length,
                        r.indicators_count,
                      )}
                    </b>
                  </h4>
                  <DoughnutChart
                    progress={p(
                      r.standards_with_universal_support.length,
                      r.indicators_count,
                    )}
                    onClick={handleIndicatorsWithUniversalSupportClick}
                  />
                  <h5>
                    ({r.standards_with_universal_support.length} /{" "}
                    {r.indicators_count}&nbsp;Indicators)
                  </h5>
                </>
              )}
              {submissionsCount && (
                <>
                  <h4>{commonStrings.percentageUniversal}</h4>
                  <PieChart
                    labels={["0-25%", "25-50%", "50-75%", "75-100%"]}
                    colors={[uaRed, uaGold, uaDarkBlue, uaTeal]}
                    values={r.schools_with_universal_support}
                    tooltipSuffix=" schools"
                    legend
                  />
                </>
              )}
            </div>
            {type === "student" && (
              <div className="col-8 border border-secondary rounded">
                <h4>
                  {commonStrings.ratioIndicator}:{" "}
                  <b>
                    {p(r.standards_that_promote.length, r.standards_count)} /{" "}
                    {p(r.standards_that_reinforce.length, r.standards_count)}
                  </b>{" "}
                  ({r.standards_that_promote.length} /{" "}
                  {r.standards_that_reinforce.length})
                </h4>
                <div className="row">

                  <div className="col-6">
                    <PieChart
                      labels={["promotes", "reinforces"]}
                      values={[
                        p(r.standards_that_promote.length, r.standards_count),
                        p(r.standards_that_reinforce.length, r.standards_count),
                      ]}
                      onClick={handlePromoteReinforceClick}
                      legend
                    />
                  </div>
                  <div className="col-6">
                    <PieChart
                      labels={["promotes", "reinforces"]}
                      values={[60, 40]}
                      legend
                    />
                    <h5>* Goal</h5>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {!submissionsCount && (
          <div className="group px-4 py-4 SEL_quality_background">
            <TChart
              column1Title="Universally supported indicators"
              column2Title="Indicators not yet with universal support"
              column1Items={column1Items}
              column2Items={column2Items}
            />
          </div>
        )}
        {/* working */}
        <div className="group px-4 py-4 SEL_quality_background">
          <div className="row">
            {/* <div className="col-4">
              <h4>
                Activities with data:{" "}
                <b>{p(r.items_with_data.length, activitiesCount)}</b>
              </h4>
              <DoughnutChart
                progress={p(r.items_with_data.length, activitiesCount)}
                onClick={handleActivitiesWithDataClick}
              />
              <h5>
                ({r.items_with_data.length} / {activitiesCount} Activities)
              </h5>
            </div> */}
            <div className="col-4">
              <h4>
                Activities with data:{" "}
                <b>{p(r.items_with_data.length, activitiesCount)}</b>
              </h4>
              <Gauge
                obtained={r.items_with_data.length}
                progress={p(r.items_with_data.length, activitiesCount)}
                total={activitiesCount}
                title="Matrix"
                onClick={handleActivitiesWithDataClick}
              />
              <h5>
                ({r.items_with_data.length} / {activitiesCount} Activities)
              </h5>
            </div>

            <div className="col-8 border border-secondary rounded">
              <h4>
                Ratio of universal, targeted, and tertiary activities:{" "}
                <b>
                  {p(r.items_with_universal_support.length, activitiesCount)} /{" "}
                  {p(r.items_with_targeted_support.length, activitiesCount)} /{" "}
                  {p(r.items_with_tertiary_support.length, activitiesCount)}
                </b>{" "}
                ({r.items_with_universal_support.length} /{" "}
                {r.items_with_targeted_support.length} /{" "}
                {r.items_with_tertiary_support.length})
              </h4>
              <div className="row">
                <div className="col-6">
                  <PieChart
                    labels={["universal", "targeted", "tertiary"]}
                    values={[
                      p(r.items_with_universal_support.length, activitiesCount),
                      p(r.items_with_targeted_support.length, activitiesCount),
                      p(r.items_with_tertiary_support.length, activitiesCount),
                    ]}
                    onClick={handleTierOfSupportClick}
                  />
                  <h5>* Actual</h5>
                </div>
                <div className="col-6">
                  <PieChart
                    labels={["universal", "targeted", "tertiary"]}
                    values={[80, 15, 5]}
                  />
                  <h5>* Goal</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group px-4 py-4 SEL_quality_background">
          <div className="row">
            <div className="col-6">
              <BarChart
                labels={_.keys(r.activities_by_competency)}
                datasets={[
                  {
                    label: "activities",
                    data: _.values(r.activities_by_competency).map(
                      (a) => a.length,
                    ),
                    backgroundColor: `rgba(${uaTeal}, 0.75)`,
                  },
                ]}
                stepSize={1}
                onClick={handleActivitiesCountClick}
              />
              <div className="activities_count">
                <span>{commonStrings.activitiesCount}</span>
              </div>
            </div>
            <div className="col-6">
              <BarChart
                labels={_.keys(r.activities_by_dessa)}
                datasets={[
                  {
                    label: "activities",
                    data: _.values(r.activities_by_dessa).map((a) => a.length),
                    backgroundColor: `rgba(${uaTeal}, 0.75)`,
                  },
                ]}
                stepSize={1}
                onClick={handleActivitiesByDESSAClick}
              />
              <div className="count_Dessa">
                <span>{commonStrings.countByDessa}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="group px-4 py-4 SEL_quality_background">
          <h4>{commonStrings.schoolYearProgress}</h4>
          <BarChart
            labels={[
              "Activities",
              "SEL Indicators",
              "Universal",
              "Targeted",
              "Tertiary",
            ]}
            datasets={growthScoresDataSets}
            mb={3}
            legend
          />
          <h5>{commonStrings.noSpringScore}</h5>
        </div>
      </div>
    </div>
  )
}

export default ProgramMatrixSELQuality
