import React from "react"
import {
  Input, Button, Row, Col, Form, Space, Tooltip,
} from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import commonStrings from "../../commonStrings"
import componentStyles from "../../styles"
import "../../../assets/stylesheets/partials/system_admins.scss"
import { TruncateText } from "../../utilities"
import validations from "../../validations"

const Attachment = (props) => {
  const {
    CreateLinkObject,
    linkObject,
    setLinkPreview,
    linkPreview,
    setLinkObject,
    attachmentForm,
  } = props

  const createPreviewCard = () => {
    attachmentForm
      .validateFields()
      .then(() => {
        const newCard = [...linkPreview]
        newCard.push({ title: linkObject.title, link: linkObject.link })
        setLinkPreview(newCard)
        setLinkObject({})
        attachmentForm.resetFields()
      })
      .catch(() => {})
  }
  function removeLinkFromArray(linkToRemove) {
    const newTags = linkPreview.filter((tag) => tag.link !== linkToRemove)
    setLinkPreview(newTags).catch(() => {})
  }
  return (
    <Row justify="end">
      <Col
        xs={24}
        sm={24}
        md={4}
        lg={4}
        className="module-attachment displayFlexSpaceBetween attachments"
      >
        <span>{commonStrings.attachemnts} :</span>
      </Col>

      <Col
        xs={24}
        sm={24}
        md={20}
        lg={20}
        className="module-attachment  attachmentsSection"
      >
        <div className="displayFlexSpaceBetween">
          <Form form={attachmentForm} style={{ display: "flex" }}>
            <Form.Item name="title">
              <Input
                style={componentStyles.inputTitleAttachment}
                name="title"
                type="text"
                onChange={(value) => CreateLinkObject(value)}
                placeholder="Title"
                allowClear
              />
            </Form.Item>
            <Form.Item rules={validations.verfyLink} name="link">
              <Input
                style={componentStyles.inputLinkAttachment}
                name="link"
                type="text"
                placeholder="Link"
                onChange={(value) => CreateLinkObject(value)}
                allowClear
              />
            </Form.Item>
          </Form>

          <Button
            onClick={createPreviewCard}
            style={componentStyles.addAttachmentButton}
            disabled={!(linkObject.title && linkObject.link)}
          >
            {commonStrings.addAttachment}
          </Button>
        </div>

        <div key={linkPreview} className="scrollable-div mt-3">
          {linkPreview.map((li, index) => (
            <div className="main-tags">
              <div>
                <span className="numbering">{`${index + 1}. `}</span>
                <Space size={[0, 8]} wrap>
                  <Tooltip title={li.title}>
                    <a
                      className="titleSize"
                      target="_blank"
                      href={li.link}
                      rel="noreferrer"
                    >
                      {TruncateText(li.title, 30).charAt(0).toUpperCase()
                        + TruncateText(li.title, 30).slice(1)}
                    </a>
                  </Tooltip>
                </Space>
              </div>

              <div className="d-flex">
                <div className="paddingR">
                  <DeleteOutlined
                    className="dustbinIcon"
                    onClick={() => {
                      removeLinkFromArray(li.link)
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  )
}
export default Attachment
