import { Form, Input } from "antd"
import React from "react"
import PropTypes from "prop-types"
import componentStyles from "../../styles"
import "../../../assets/stylesheets/partials/system_admins.scss"

const ruleShape = PropTypes.shape({
  required: PropTypes.bool,
  message: PropTypes.string,
})

function SystemAdminInputCell({
  name,
  label,
  rules,
  keys,
  defaultValues,
  disabled,
  maxLength,
  prefix,
  type,
  min,
  handleChange,
}) {
  return (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name={name}
      label={label}
      rules={rules}
      key={keys}
      initialValue={defaultValues}
    >
      <Input
        style={componentStyles.inputStyleNew}
        name={name}
        disabled={disabled}
        maxLength={maxLength}
        prefix={prefix}
        type={type}
        min={min || "0"}
        onChange={(value) => handleChange(value)}
      />
    </Form.Item>
  )
}

SystemAdminInputCell.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(ruleShape),
  keys: PropTypes.string,
  defaultValues: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  prefix: PropTypes.node,
  type: PropTypes.string,
  min: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}

SystemAdminInputCell.defaultProps = {
  rules: [],
  keys: "",
  defaultValues: "",
  disabled: false,
  maxLength: undefined,
  prefix: null,
  type: "text",
  min: undefined,
}

export default SystemAdminInputCell
