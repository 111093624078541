import React, { useState } from "react"
import _ from "underscore"
import TextareaAutosize from "react-textarea-autosize"
import { useDebounceEffect } from "ahooks"
import SustainabilityReport from "./Report"
import SustainabilityRubricTabs from "./Tabs"
import SustainabilityRubricIndicator from "./Indicator"
import RubricHeader from "../rubrics/Header"
import FooterButtons from "../rubrics/FooterButtons"

function SustainabilityRubric({ rubric: initialRubric }) {
  const [step, setStep] = useState(initialRubric.workflow_state === "submitted" ? 9 : 1)
  const [rubric, setRubric] = useState(initialRubric)

  const saveRubric = () => {
    showSaving()
    APIClient.put(
      `/sustainability_rubrics/${rubric.id}`,
      {
        areas_of_strength: rubric.areas_of_strength,
        opportunities_for_growth: rubric.opportunities_for_growth,
      },
      () => {
        hideSaving()
      },
    )
  }

  useDebounceEffect(() => {
    if (rubric !== initialRubric) {
      saveRubric()
    }
  }, [rubric], { wait: 250 })

  const indicatorsForSubsection = (indices) => _.filter(rubric.indicators, (indicator) => _.contains(indices, indicator.index.toString()))

  const updateTextarea = (key, group, value) => {
    const newRubric = { ...rubric }
    newRubric[key][group] = value
    setRubric(newRubric)
  }

  const renderSubsection = (subsection, group, index) => (
    <div key={index}>
      <div className="row top" style={{ borderTopLeftRadius: "0.4rem", borderTopRightRadius: "0.4rem" }}>
        <div className="col-9">
          {subsection.label && <h2>{subsection.label}</h2>}
          <p>{subsection.body} </p>
          <div className="form-group">
            <TextareaAutosize
              className="form-control areas-of-strength"
              placeholder={`Areas of strength across ${group}…`}
              onChange={(e) => updateTextarea("areas_of_strength", group, e.target.value)}
              value={rubric.areas_of_strength[group] || ""}
              disabled={rubric.workflow_state === "submitted"}
            />
          </div>
          <div className="form-group">
            <TextareaAutosize
              className="form-control"
              placeholder={`Opportunities for growth across ${group}…`}
              onChange={(e) => updateTextarea("opportunities_for_growth", group, e.target.value)}
              value={rubric.opportunities_for_growth[group] || ""}
              disabled={rubric.workflow_state === "submitted"}
            />
          </div>
        </div>
      </div>
      {indicatorsForSubsection(_.keys(subsection.indicators)).map((indicator) => (
        <SustainabilityRubricIndicator
          key={indicator.index}
          indicator={indicator}
          rubricId={rubric.id}
          disabled={rubric.workflow_state === "submitted"}
        />
      ))}
    </div>
  )

  const renderSection = (section, index) => (
    <div key={index} className={`tab-pane initial ${step === (index + 1) ? "active" : null}`}>
      {section.subsections.map((subsection, i) => renderSubsection(subsection, (subsection.label || section.label), i))}
    </div>
  )

  const tabContent = () => (
    <div
      className="tab-content"
      style={{
        borderRadius: "0.4rem", borderTopLeftRadius: step !== 1 ? "0.4rem" : "", overflow: "hidden",
      }}
    >
      {rubric.template.sections.map((section, index) => renderSection(section, index))}
      {rubric.workflow_state === "submitted"
        && (
          <div className={`tab-pane ${step === 9 ? "active" : null}`}>
            <SustainabilityReport rubric={rubric} />
          </div>
        )}
    </div>
  )

  return (
    <div>
      <RubricHeader
        rubric={rubric}
        type="Sustainability"
        instructions="Directions: Complete the Sustainability Rubric to reflect on your SEL implementation and to house artifacts that reflect your school’s solutions around indicators of sustainability. Score each component of a category and then upload an artifact to justify your score."
      />
      <div className={`rubric sustainability ${rubric.workflow_state}`}>
        <SustainabilityRubricTabs
          rubric={rubric}
          step={step}
          setStep={setStep}
        />
        {tabContent(rubric)}
        <FooterButtons
          rubric={rubric}
          type="Sustainability"
        />
      </div>
    </div>
  )
}

export default SustainabilityRubric
