import React from "react"
import { Collapse, Row, Col } from "antd"
import { UpOutlined } from "@ant-design/icons"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import _ from "underscore"
import commonStrings from "../../../commonStrings"

const Collapsible = (props) => {
  const {
    generalColor,
    id,
    title,
    subHeading,
    text,
    indicators,
    tabContent,
    index,
  } = props

  const { Panel } = Collapse

  const rating = () => _.reduce(_.pluck(indicators, "rating"), (memo, num) => memo + num, 0)

  const percentage = () => {
    const hasNullRating = indicators.some(
      (indicator) => indicator.rating !== null,
    )
    return hasNullRating
      ? ((rating() / (indicators.length * 4)) * 100).toFixed(0)
      : null
  }

  return (
    <div
      key={index}
      className="div-collapse collapsibleHeaderImplementation"
      id={id}
    >
      <Collapse
        key={index}
        style={{ background: generalColor, color: "white" }}
        expandIcon={({ isActive }) => (
          <UpOutlined
            style={{
              fontSize: "25px",
              color: "white",
              cursor: "pointer",
            }}
            rotate={isActive ? 0 : 180}
          />
        )}
        expandIconPosition="end"
        collapsible="header"
        // bordered={false}
      >
        <Panel
          header={(
            <div className="container panelCollapse">
              <div className="d-flex justify-content-between">
                <div className="title-collapse">
                  <span>{title}</span>
                </div>

                <div className="subHeading-collapse">
                  <span>
                    <span className="partSubHeading">{subHeading}</span> -{" "}
                    <span>{text}</span>
                  </span>
                </div>
                <div className="progress-collapse">
                  {percentage() === null ? (
                    <span className="incomplete-text">
                      {commonStrings.inCompleteText}
                    </span>
                  ) : (
                    <div style={{ width: 55, height: 55, textAlign: "center" }}>
                      <CircularProgressbar
                        text={`${percentage()}%`}
                        value={percentage()}
                        strokeWidth={15}
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "white",
                          trailColor: "#ffffff33",
                          textAlign: "center",
                        })}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          key={id}
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              {tabContent()}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  )
}

export default Collapsible
