import React from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Link,
  Svg,
  Line,
  Polygon,
} from "@react-pdf/renderer"
import _ from "underscore"
import uaLogo from "../../../assets/images/rsp-logo.png"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import commonStrings from "../../commonStrings"
import { removeHtmlTags, customSortByRating } from "../../utilities"

const backgroundColors = {
  coordination: "#E7A922",
  range: "#E7A922",
  "program fit/reinvention": "#0CA4A5",
}
const sustainabilityMainHeaders = [
  {
    title: "Commitment",
    description:
      "The demonstrated willingness of the school/district to participate in activities that create a continued capacity for delivery of program concepts and tenants.",
    color: "#A6192E",
  },
  {
    title: "Integration",
    description:
      "The steps the school/district has taken to incorporate program delivery into relevant ongoing and future activities.",
    color: "#E7A922",
  },
  {
    title: "Innovation",
    description:
      "The steps the school or district has taken to adapt the program in order to meet the needs of the stakeholders (in accordance with core program tenets).",
    color: "#0CA4A5",
  },
]
const SustainabilityReportpdf = ({
  rubric,
  currentSchoolLogo,
  rubricNumberDate,
}) => {
  function containsAnchorTag(inputString) {
    const anchorTagRegex = /<a\b[^>]*>/i

    return anchorTagRegex.test(inputString)
  }
  function getHrefValue(inputString) {
    const anchorTagRegex = /<a\s+(?:[^>]*?\s+)?href=["']([^"']*)["'][^>]*>/i

    const match = inputString.match(anchorTagRegex)

    if (match && match.length > 1) {
      return match[1]
    }
    return null
  }
  Font.register({
    family: "Archivo Narrow",
    fonts: [
      {
        src: archivoNarrow,
      },
      {
        src: archivoNarrowBold,
        fontWeight: 700,
      },
    ],
  })
  const pdfStyles = StyleSheet.create({
    viewStylesTop: {
      marginTop: 60,
    },
    page: {
      fontFamily: "Archivo Narrow",
      paddingTop: 14,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },

    header: {
      display: "flex",
      flexDirection: "column",
      fontSize: "11",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "8",
      fontWeight: "700",
      minHeight: "40",
      width: "100%",
    },
    widthHeading: {
      width: "30%",
      flexDirection: "row",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      fontWeight: "700",
      fontSize: "14",
      backgroundColor: "#FFFFFF",
    },
    widthHeading1: {
      width: "20%",
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      fontWeight: "600",
      fontSize: "14",
      paddingTop: "5",
    },
    widthDescription: {
      width: "65%",

      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "row",
      fontWeight: "300",
      paddingRight: "10",
      paddingLeft: "15",
      fontSize: "10",
      backgroundColor: "#FFFFFF",
    },
    widthDescription1: {
      width: "80%",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "row",
      paddingRight: "40",
      display: "flex",

      fontSize: "11",

      fontWeight: "600",
    },
    stages: {
      marginTop: "10",
      padding: "3px",
      width: "15%",
      fontSize: "11",
      textAlign: "center",
      fontWeight: "600",
      textTransform: "uppercase",
    },

    widthRating: {
      width: "17%",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "700",
      fontSize: "9",
      textTransform: "uppercase",
      backgroundColor: "#FFFFFF",
    },
    imageContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    uaLogo: {
      width: 115,
      height: 64,
    },
    heading: {
      marginTop: "7px",
      marginBottom: "8px",
      fontSize: "14px",
      fontFamily: "Archivo Narrow",
    },
    overallScore: {
      color: "black",
      fontSize: "14",
      fontWeight: 700,
    },
    need: {
      width: "50%",
      textAlign: "center",
    },
    strength: {
      width: "50%",
      textAlign: "center",
    },
    dFlex: {
      marginTop: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    pointsAreaOfStrength: {
      width: "50%",
    },
    TextAreaOfStrenght: {
      paddingLeft: "5px",
      paddingRight: "10px",
      fontSize: "10px",
      marginTop: "5px",
      marginBottom: "6px",
      fontWeight: "400",
    },
    pointsAreaOfNeed: { width: "50%" },
    TextAreaOfNeed: {
      paddingLeft: "5px",
      paddingRight: "10px",
      fontSize: "10px",
      marginTop: "6px",
      marginBottom: "5px",
      fontWeight: "400",
    },
    mergeViews: {
      display: "flex",
      flexDirection: "row",
    },
    paddingX: {
      paddingLeft: "7px",
      paddingRight: "7px",
    },
    evidence: {
      marginTop: 10,

      fontSize: "12px",
    },
    evidenceContent: {
      fontSize: "10px",
    },
    artifactContainer: {
      marginBottom: 20,
    },
    artifactTitle: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 10,
    },
    artifactFile: {
      marginBottom: 5,
    },

    stepper: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    stepContainer: {
      flexDirection: "row",

      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
    },
    step: {
      width: 20,
      height: 20,
      backgroundColor: "lightgray",
      marginTop: 16,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transform: "rotate(45deg)",
      position: "relative",
      top: 1,
    },
    activeStep: {
      backgroundColor: "#33485D",
    },
    inactiveStep: {
      backgroundColor: "#E5E5E5",
    },

    stepContent: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "10px",
      marginLeft: "30px",
    },
    stepTextStyle: {
      fontSize: 10,
      fontWeight: 700,
    },
    overallScoreRating: {
      marginBottom: 15,
      marginTop: 10,
      display: "flex",
      alignItems: "baseline",
    },
    stepStyleContainer: {
      marginTop: "10",
      border: "2px solid",

      width: "25%",
      fontSize: "11",
      textAlign: "center",
      fontWeight: "600",
      textTransform: "uppercase",

      display: "flex",
      flexDirection: "row",

      justifyContent: "space-between",
      alignItems: "center",

      minHeight: "40",
    },
    ratingContent: {
      textTransform: "uppercase",
      alignItems: "center",
      borderColor: "#33485D",
      borderWidth: 3,
      marginRight: 15,
      marginLeft: 5,
      fontSize: 11,
      fontWeight: 600,
      color: "#33485D",
      paddingLeft: 8,
      paddingRight: 4,

      paddingTop: 4,
    },
    uaLogo1: {
      width: 115,
      height: 64,
    },
    StepTileAndSteppers: {
      flexDirection: "column",
      alignItems: "center",
      marginRight: 25,
      marginLeft: 25,
    },
    stepsTitleView: {
      justifyContent: "space-evenly",
      marginRight: 25,
      marginBottom: -15,
      marginLeft: -10,
    },
    stpesTitileText: {
      fontSize: "11",
      fontWeight: "700",
      marginRight: 25,
      marginLeft: 45,
    },
    lines: {
      marginLeft: "-20",
      zIndex: "99",
      width: "135%",
    },
    gradedRating: {
      borderColor: "black",
      borderWidth: 1,
      fontSize: 10,
      fontWeight: 600,
      marginTop: 2,
      alignItems: "center",
      textAlign: "center",
      padding: 4,
    },
    evidenceContainer: {
      marginTop: 30,
    },
    evidenceContentText: {},
  })

  function Stepper({ steps, currentStep }) {
    const ratingColors = {
      INITIATING: "#E5E5E5",
      DEVELOPING: "#E5E5E5",
      PROFICIENT: "#E5E5E5",
      "WELL DEVELOPED": "#E5E5E5",
    }

    if (currentStep >= 0 && currentStep < steps.length) {
      const currentRating = steps[currentStep]
      ratingColors[currentRating] = "#0000FF"
    }

    return (
      <View style={pdfStyles.stepContainer}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <View style={pdfStyles.StepTileAndSteppers}>
              <View style={pdfStyles.stepsTitleView}>
                <Text
                  style={[
                    pdfStyles.stpesTitileText,
                    {
                      color: `${index <= currentStep ? "#33485D" : "#E5E5E5"}`,
                    },
                  ]}
                >
                  {step}
                </Text>
              </View>
              <View style={{ position: "relative", xIndex: -1 }}>
                <Svg width="80" height="100">
                  <Polygon
                    points="18,23 30,10 42,23 30,36"
                    fill={index <= currentStep ? "#33485D" : "#E5E5E5"}
                    strokeWidth="10 "
                    transform="translate(35, 25)"
                  />
                </Svg>
              </View>
            </View>

            <View style={pdfStyles.lines}>
              {index < steps.length - 1 && (
                <Svg height="50" width="161%">
                  <Line
                    x1="10"
                    y1="23"
                    x2="950"
                    y2="23"
                    strokeWidth={8}
                    stroke={
                      index <= currentStep && index + 1 <= currentStep
                        ? "#33485D"
                        : "#E5E5E5"
                    }
                  />
                </Svg>
              )}
            </View>
          </React.Fragment>
        ))}
      </View>
    )
  }

  const determineCurrentStep = (rating) => {
    if (!_.isNull(rating)) {
      const ratingMap = {
        initiating: 0,
        developing: 1,
        proficient: 2,
        "well developed": 3,
      }

      return ratingMap[rating.toLowerCase()] || 0
    }
    return null
  }

  const currentStep = determineCurrentStep(rubric.rating)

  const steps = ["INITIATING", "DEVELOPING", "PROFICIENT", "WELL DEVELOPED"]
  function gradedAreasCount(report) {
    const count = report.filter((area) => area.rating).length
    return `${count} of ${report.length} areas graded`
  }
  const titleToLabels = {
    Commitment: [
      "Data",
      "Program Review",
      "Communication",
      "Leadership",
      "Physical Visibility",
      "School Norms",
    ],
    Integration: ["Range", "Coordination"],
    Innovation: ["Program Fit/Reinvention"],
  }
  const titlesInOrder = sustainabilityMainHeaders.map((header) => header.title)

  const sortedRubricReport = _.flatten(
    titlesInOrder.map((title) => {
      const labels = titleToLabels[title]
      const filteredItems = rubric.report.filter((item) => labels.includes(item.name))
      return customSortByRating(filteredItems)
    }),
  )

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.imageContainer} fixed>
          <Image style={pdfStyles.uaLogo} src={uaLogo} />

          {currentSchoolLogo && (
            <Image style={pdfStyles.uaLogo1} src={currentSchoolLogo} />
          )}
        </View>
        <View style={pdfStyles.section} fixed>
          <Text style={pdfStyles.heading}>{rubricNumberDate}</Text>
        </View>
        <View
          style={[
            pdfStyles.overallScoreRating,
            {
              flexDirection: "row",
              alignItems: "center",
            },
          ]}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={pdfStyles.overallScore}>Overall Score:</Text>
            <Text style={pdfStyles.ratingContent}>
              {rubric.rating || "Ungraded"}
            </Text>
          </View>
          <View style={pdfStyles.gradedRating}>
            <Text
              style={[
                pdfStyles.graded,
                {
                  alignItems: "center",
                  textAlign: "center",
                },
              ]}
            >
              {gradedAreasCount(rubric.report)}
            </Text>
          </View>
        </View>
        <View>
          <Stepper steps={steps} currentStep={currentStep} />
        </View>

        {sustainabilityMainHeaders.map((header, index) => {
          const filteredReportItems = rubric.report.filter((item) => titleToLabels[header.title].includes(item.name))
          const sortedOrderBasedOnRating = customSortByRating(filteredReportItems)
          return (
            <View style={pdfStyles.header}>
              <View
                key={index}
                style={[
                  pdfStyles.header,
                  {
                    borderColor: header.color,
                    borderWidth: 2,
                    borderBottomWidth: 0,
                    backgroundColor: "#FFFFFF",
                    color: header.color,
                    flexDirection: "row",
                  },
                ]}
              >
                <View
                  style={[
                    pdfStyles.widthHeading1,
                    {
                      paddingLeft: "10px",
                    },
                  ]}
                >
                  <Text>{header.title}</Text>
                </View>
                <View />

                <View style={pdfStyles.widthDescription1}>
                  <Text>{header.description}</Text>
                </View>
              </View>
              {sortedOrderBasedOnRating.map((item, innerIndex) => (
                <View
                  key={innerIndex}
                  style={{
                    ...pdfStyles.header,
                    color:
                      backgroundColors[item.name.toLowerCase()] || "#A6192E",
                    backgroundColor: "#FFFFFF",
                    borderColor:
                      backgroundColors[item.name.toLowerCase()] || "#A6192E",
                    borderWidth: 2,
                    flexDirection: "row",
                    alignItems: "center",
                    borderBottomWidth:
                      innerIndex === sortedOrderBasedOnRating.length - 1
                        ? 2
                        : 0,
                  }}
                >
                  <View
                    style={[
                      pdfStyles.widthHeading,
                      {
                        color:
                          backgroundColors[item.name.toLowerCase()]
                          || "#A6192E",
                      },
                    ]}
                  >
                    <Text>{item.name}</Text>
                  </View>
                  <View
                    style={[
                      pdfStyles.widthDescription,
                      {
                        color:
                          backgroundColors[item.name.toLowerCase()]
                          || "#A6192E",
                      },
                    ]}
                  >
                    <Text>{item.body}</Text>
                  </View>
                  <View
                    style={[
                      pdfStyles.widthRating,
                      {
                        borderColor:
                          backgroundColors[item.name.toLowerCase()]
                          || "#A6192E",
                        borderWidth: 2,

                        color:
                          backgroundColors[item.name.toLowerCase()]
                          || "#A6192E",
                      },
                    ]}
                  >
                    <Text>{item.rating || "Ungraded"}</Text>
                  </View>
                </View>
              ))}
            </View>
          )
        })}
      </Page>
      {sortedRubricReport.map((stage) => {
        let arrayAreaOfStrength = []
        let arrayAreaofNeed = []

        if (stage.areas_of_strength) {
          arrayAreaOfStrength = stage.areas_of_strength.split("\n")
        }
        if (stage.opportunities_for_growth) {
          arrayAreaofNeed = stage.opportunities_for_growth.split("\n")
        }
        return (
          <Page size="A4" style={pdfStyles.page}>
            <View style={pdfStyles.imageContainer} fixed>
              <Image style={pdfStyles.uaLogo} src={uaLogo} />

              {currentSchoolLogo && (
                <Image style={pdfStyles.uaLogo1} src={currentSchoolLogo} />
              )}
            </View>
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.heading}>{rubricNumberDate}</Text>
            </View>
            <View
              style={[
                pdfStyles.header,
                {
                  color:
                    backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                  backgroundColor: "#FFFFFF",
                  borderColor:
                    backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                  borderWidth: 2,
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <View
                style={[
                  pdfStyles.widthHeading,
                  {
                    color:
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                  },
                ]}
              >
                <Text>{stage.name}</Text>
              </View>
              <View
                style={[
                  pdfStyles.widthDescription,
                  {
                    color:
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                  },
                ]}
              >
                <Text style={{ fontWeight: "700" }}>{stage.body}</Text>
              </View>
              <View
                style={[
                  pdfStyles.widthRating,
                  {
                    borderColor:
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                    borderWidth: 2,

                    color:
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                  },
                ]}
              >
                <Text>{stage.rating || "Ungraded"}</Text>
              </View>
            </View>
            <View style={[pdfStyles.dFlex]}>
              <View
                style={[
                  pdfStyles.strength,
                  {
                    color:
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                    borderBottom: `2px solid ${
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E"
                    }`,
                    borderRight: `1px solid ${
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E"
                    }`,
                    marginRight: "-1px",
                  },
                ]}
              >
                <Text style={{ fontWeight: "700" }}>
                  {commonStrings.areasOfStrength}
                </Text>
              </View>
              <View
                style={[
                  pdfStyles.need,
                  {
                    color:
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                    borderBottom: `2px solid ${
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E"
                    }`,
                    borderLeft: `1px solid ${
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E"
                    }`,
                    marginLeft: "-1px",
                  },
                ]}
              >
                <Text style={{ fontWeight: "700" }}>
                  {commonStrings.areasOfNeed}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.mergeViews}>
              <View
                style={[
                  pdfStyles.pointsAreaOfStrength,
                  {
                    borderRight: `1px solid ${
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E"
                    }`,
                    minHeight: "300px",
                  },
                ]}
              >
                {stage.areas_of_strength
                  && arrayAreaOfStrength.map((value) => {
                    if (value) {
                      return (
                        <View
                          style={[
                            pdfStyles.mergeViews,
                            pdfStyles.paddingX,
                            {
                              marginBottom: "-5px",
                            },
                          ]}
                        >
                          <Text style={{ color: "#383838" }}>•</Text>
                          <Text style={[pdfStyles.TextAreaOfStrenght]}>
                            {value}
                          </Text>
                        </View>
                      )
                    }
                    return null
                  })}
              </View>
              <View
                style={[
                  pdfStyles.pointsAreaOfNeed,
                  {
                    borderLeft: `1px solid ${
                      backgroundColors[stage.name.toLowerCase()] || "#A6192E"
                    }`,
                    minHeight: "300px",
                  },
                ]}
              >
                {stage.opportunities_for_growth
                  && arrayAreaofNeed.map((value) => {
                    if (value) {
                      return (
                        <View
                          style={[
                            pdfStyles.mergeViews,
                            pdfStyles.paddingX,
                            {
                              marginBottom: "-5px",
                            },
                          ]}
                        >
                          <Text style={{ color: "#383838" }}>•</Text>
                          <Text style={[pdfStyles.TextAreaOfNeed]}>
                            {value}
                          </Text>
                        </View>
                      )
                    }
                    return null
                  })}
              </View>
            </View>

            {stage.explanations.length > 0 && (
              <View style={pdfStyles.evidenceContainer}>
                <Text
                  style={[
                    pdfStyles.evidenceHeading,
                    {
                      color:
                        backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                      fontWeight: "700",
                    },
                  ]}
                >
                  {commonStrings.evidence}
                </Text>
                <View>
                  {stage.explanations.map((explanation, index) => {
                    if (containsAnchorTag(explanation.__html)) {
                      return (
                        <View
                          style={[
                            {
                              fontSize: 12,
                              display: "flex",
                              flexDirection: "row",
                            },
                          ]}
                        >
                          <Text style={{ fontSize: 12, marginRight: "5px" }}>
                            •
                          </Text>
                          <Link
                            src={getHrefValue(explanation.__html)}
                            target="_blank"
                            rel="noreferrer"
                            style={[{ textDecoration: "none", color: "black" }]}
                          >
                            {removeHtmlTags(explanation.__html)}
                          </Link>
                        </View>
                      )
                    }
                    try {
                      return explanation.__html ? (
                        <View
                          key={index}
                          style={{
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text style={{ fontSize: 12, marginRight: 5 }}>
                            •
                          </Text>
                          <Text style={{ fontSize: 12 }}>
                            {explanation.__html.split("\n")}
                          </Text>
                        </View>
                      ) : null
                    } catch (error) {
                      console.error("Error rendering explanation:", error)
                      return null
                    }
                  })}
                </View>
              </View>
            )}
            {stage.evidence.length > 0 && (
              <View style={{ marginTop: 30 }}>
                <Text
                  style={[
                    pdfStyles.evidenceHeading,
                    {
                      color:
                        backgroundColors[stage.name.toLowerCase()] || "#A6192E",
                      fontWeight: "400",
                    },
                  ]}
                >
                  <Text style={{ fontWeight: "700" }}>
                    {commonStrings.artifacts}
                  </Text>
                </Text>
                {stage.evidence.map((evidence, index) => (
                  <View key={evidence.id}>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <Text style={{ fontSize: 12, marginRight: 5 }}>•</Text>
                      <Text
                        style={[
                          pdfStyles.evidenceContentText,
                          {
                            fontSize: 12,
                          },
                        ]}
                      >
                        <Link
                          // style={{ color: "black" }}
                          // src={evidence.url}
                          // target="_blank"
                          style={{ color: "black" }}
                          src={evidence.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {evidence.description}
                        </Link>
                      </Text>
                    </View>
                    {index !== stage.evidence.length - 1 && (
                      <Text pageBreak="after" />
                    )}
                  </View>
                ))}
              </View>
            )}
          </Page>
        )
      })}
    </Document>
  )
}

export default SustainabilityReportpdf
