import React from "react"
import { Doughnut } from "react-chartjs-2"

const Gauge = ({
  id = "gauge",
  title,
  progress,
  obtained,
  generalColor,
  onGaugeClick,
  type,
}) => {
  const parsedProgress = parseInt(progress, 10)

  const backgroundColor = [
    (title === "Matrix" && (type === "adult" ? "#33485D" : "#0CA4A5"))
      || (title === "Implementation" && "#E7A922")
      || (title === "Sustainability" && "#85919D"),
    type === "adult"
      ? "rgba(51, 72, 93, 0.4)"
      : "rgba(12, 164, 165, 0.4)",
  ]

  const data = {
    datasets: [
      {
        data: [parsedProgress, 100 - parsedProgress],
        backgroundColor,
        display: true,
        borderColor: "#D1D6DC",
      },
    ],
  }
  const plugins = [
    {
      beforeDraw(chart) {
        const { width, height, ctx } = chart

        ctx.restore()
        ctx.height = "100px"
        const fontSize = (height / 160).toFixed(2)
        ctx.font = `${fontSize}em sans-serif`
        ctx.textBaseline = "top"
        const text = obtained
        const textX = Math.round((width - ctx.measureText(text).width) / 2) - 5
        const textY = height / 2 + 21
        ctx.fillStyle = generalColor
        ctx.font = "bold 25px serif"
        ctx.fillText(text, textX, textY)
        ctx.save()
      },
      datalabels: {
        display: false,
      },
    },
  ]

  const options = {
    rotation: 270,
    circumference: 180,
    // legend: {
    //   display: false,
    // },
    onHover: (event, element) => {
      event.native.target.style.cursor = element[0] ? "pointer" : "default"
    },

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      // tooltip: {
      //   callbacks: {
      //     label: (tooltipItem) => ` ${tooltipItem.formattedValue}%`,
      //   },
      // },
      tooltip: {
        callbacks: {
          label: (context) => {
            if (parsedProgress === 0) {
              return ` ${0}%`
            }
            // const tooltipItem = context.dataset.data[context.dataIndex];
            // if (tooltipItem === 0) {
            //   return "0%";
            // }
            return ` ${context.formattedValue}%`
          },
        },
      },
    },
  }

  return (
    <div style={{ width: "200px" }}>
      <Doughnut
        plugins={plugins}
        id={id}
        data={data}
        key={obtained}
        options={{
          responsive: true,
          // onClick: (evt, element) => {
          //   if (element.length > 0) {
          //     const indexClicked = element[0]._index;
          //     onClick(indexClicked);
          //   }
          // },
          onClick(event, array) {
            if (array && array.length > 0) {
              const clickedElementIndex = array[0].index
              if (clickedElementIndex === 0) {
                onGaugeClick(0)
              } else {
                onGaugeClick(1)
              }
            }
          },

          ...options,
        }}
      />
    </div>
  )
}

export default Gauge
