import React from "react"
import { Drawer, Collapse } from "antd"
import commonStrings from "../../commonStrings"

const DrawerOldRecords = ({
  stages, rubricData, open, closeDrawer,
}) => {
  const { Panel } = Collapse

  const hasRating = (arr) => arr.some((item) => item.rating !== null)

  return (
    <Drawer
      title={commonStrings.implementationDetails}
      placement={commonStrings.left}
      closable
      onClose={closeDrawer}
      visible={open}
      destroyOnClose
      mask
      maskClosable
      className="implementation-graph-drawer"
    >
      <div className="font-family-default">
        <div className="mb-2 overall-score">
          <span>{"Overall: "}</span>
          <span>{rubricData.overall}%</span>
        </div>
        {stages.map((value, index) => (
          <Collapse className="collapsible-newChart" activeKey={value.key}>
            <Panel
              key={value.key}
              style={{
                backgroundColor: value.color,
                marginBottom: 12,
              }}
              header={(
                <div className="center-props sideDrawer-old">
                  <div>
                    <span>{value.title}</span>
                  </div>
                  <div>
                    <span>{rubricData.stages[index].rating}%</span>
                  </div>
                </div>
              )}
            >
              {!hasRating(rubricData.stages[index].data) && (
                <div>
                  <span>{commonStrings.noRating}</span>
                </div>
              )}
              {rubricData.stages[index].data.map(
                (v) => v.rating !== null && (
                <div>
                  <div style={{ fontSize: "14px" }}>
                    {commonStrings.indicator} {v.index}:{" "}
                    {commonStrings.rating}: <span>{v.rating}</span>
                  </div>
                </div>
                ),
              )}
            </Panel>
          </Collapse>
        ))}
      </div>
    </Drawer>
  )
}
export default DrawerOldRecords
