import React, { useState, useEffect } from "react"
import {
  Col, Row, Drawer, Table, Button, Tooltip, Space,
} from "antd"
import _ from "underscore"
import BarCharts from "./Charts/BarCharts"
import commonStrings from "../../commonStrings"
// import BarChart from "../charts/OldBarChart"
import {
  maxYAxisOffSet,
  defaultSort,
  TruncateText,
  urlToSchool,
} from "../../utilities"

const SustainabilityCollaspe = (props) => {
  const [createTableList, setCreateTableList] = useState([])
  const [createTableColums, setCreateTableColums] = useState([])

  const [getClick, setGetClick] = useState("")
  const [open, setOpen] = useState(false)
  const { sustainabilityGraph, sustainabilityProgressStage } = props

  const onClose = () => {
    setOpen(false)
    setCreateTableList([])
    setGetClick("")
  }

  const moveEmptySustainabilityToEnd = (arr) => {
    const { nonEmptyArr, emptyArr } = arr.reduce(
      (acc, item) => {
        if (item.sustainability.length === 0) {
          acc.emptyArr.push(item)
        } else {
          acc.nonEmptyArr.push(item)
        }
        return acc
      },
      { nonEmptyArr: [], emptyArr: [] },
    )

    return [...nonEmptyArr, ...emptyArr]
  }

  useEffect(() => {
    if (getClick === "graph1") {
      setCreateTableColums([
        {
          title: "School Name",
          dataIndex: "school",
          render: (text, _) => (
            <Tooltip
              style={{ borderRadius: "8px" }}
              placement="top"
              title={text.name}
            >
              <a
                className="styling-name-sustainability"
                onClick={() => {
                  urlToSchool(_.school.id)
                }}
              >
                {TruncateText(text.name, 15)}
              </a>
            </Tooltip>
          ),
          width: 60,
          fixed: "left",
          sorter: {
            compare: (a, b) => defaultSort(a.school.name, b.school.name),
          },
        },
        {
          title: "Physical Visibility",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results["Physical Visibility"] !== null
                    ? _.last(value)
                      .results["Physical Visibility"].split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 65,
        },
        {
          title: "Communication",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results.Communication !== null
                    ? _.last(value)
                      .results.Communication.split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 60,
        },
        {
          title: "Data",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results.Data !== null
                    ? _.last(value)
                      .results.Data.split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 30,
        },
        {
          title: "Leadership",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results.Leadership !== null
                    ? _.last(value)
                      .results.Leadership.split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 45,
        },
        {
          title: "Program Review",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results["Program Review"] !== null
                    ? _.last(value)
                      .results["Program Review"].split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 65,
        },
        {
          title: "Range",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results.Range !== null
                    ? _.last(value)
                      .results.Range.split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 35,
        },
        {
          title: "Program Fit/Reinvention",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results["Program Fit/Reinvention"] !== null
                    ? _.last(value)
                      .results["Program Fit/Reinvention"].split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 85,
        },
        {
          title: "Coordination",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results.Coordination !== null
                    ? _.last(value)
                      .results.Coordination.split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 50,
        },

        {
          title: "School Norms",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results["School Norms"] !== null
                    ? _.last(value)
                      .results["School Norms"].split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 60,
        },
        {
          title: "Overall Score",
          dataIndex: "sustainability",
          render: (value) => {
            if (value.length > 0) {
              return (
                <a>
                  {_.last(value).results.Overall !== null
                    ? _.last(value)
                      .results.Overall.split(" ")
                      .map((word) => word.charAt(0))
                    : "N/A"}
                </a>
              )
            }
            return <a>N/A</a>
          },
          width: 55,
        },
      ])

      setCreateTableList(
        moveEmptySustainabilityToEnd(sustainabilityProgressStage),
      )
    }
  }, [getClick, open])

  const type1Chart1 = {
    labels: [
      "Not-Started",
      "Initiating",
      "Developing",
      "Proficient",
      "Well-Developed",
    ],
    datasets: [
      {
        backgroundColor: "#33485D",
        borderWidth: 2,
        data: [
          sustainabilityGraph.not_started,
          sustainabilityGraph.initiating,
          sustainabilityGraph.developing,
          sustainabilityGraph.proficient,
          sustainabilityGraph.well_developed,
        ],
        borderRadius: 3,
        barThickness: 40,
        fill: false,
        datalabels: {
          marginTop: 20,
        },
      },
    ],
  }
  return (
    <Row>
      {open && (
        <Drawer
          title={(
            <div>
              <span
                style={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#33485D",
                }}
              >
                {commonStrings.schoolsSubmissionsScores}
              </span>
              <div>
                <Space size={16}>
                  <span>Initiating: I</span>
                  <span>Developing: D</span>
                  <span>Proficient: P</span>
                  <span>Well Developed: WD</span>
                </Space>
              </div>
            </div>
          )}
          placement="top"
          height="87%"
          closable
          onClose={onClose}
          destroyOnClose
          open={open}
          mask
          maskClosable
        >
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
              <Table
                className="table-design table-striped-rows drawer-table"
                columns={createTableColums}
                dataSource={createTableList}
                size="middle"
                scroll={{ x: 1400, y: 530 }}
                bordered
              />
            </Col>
          </Row>
          <div className="closeButtonDiv">
            <Button
              className="btnClose"
              onClick={() => {
                onClose()
              }}
            >
              {commonStrings.close}
            </Button>
          </div>
        </Drawer>
      )}
      <Col
        xs={24}
        sm={24}
        md={11}
        lg={11}
        className="boxShadowOnHover marginTopBottom "
        onClick={() => {
          setOpen(true)
          setGetClick("graph1")
        }}
      >
        <div>
          <BarCharts
            state={type1Chart1}
            yMax={
              maxYAxisOffSet(sustainabilityProgressStage.length || 0, 3) + 3
            }
            yMin={0}
            stepSize={3}
            BarChartDesc={commonStrings.graphSustainability}
          />
        </div>
      </Col>
      {/* <Col xs={24} sm={24} md={24} lg={24}>
        <div className="marginTop60 displayFlexEnd">
          <Button className="exportToCSVButtonImpl">
            {commonStrings.exportToCsv}
          </Button>
        </div>
      </Col> */}
      <Col xs={24} sm={24} md={24} lg={24}>
        <div className="marginTop60 displayFlexEnd">
          <Button
            onClick={() => {
              window.location.href = "/sustainability_rubrics/program_export"
            }}
            className="exportToCSVButtonSustain"
          >
            {commonStrings.exportToCsv}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default SustainabilityCollaspe
