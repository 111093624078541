import React from "react"

function NewReportColapsible({
  name, body, rating, color,
}) {
  const displayRating = rating !== null && rating !== "" ? rating : "Ungraded"
  // const lowerCaseName = name.toLowerCase()

  // const backgroundColors = {
  //   coordination: "#E7A922",
  //   range: "#E7A922",
  //   "program fit/reinvention": "#0CA4A5",
  // }

  // const backgroundColor = backgroundColors[lowerCaseName] || "#A6192E"
  // console.log(backgroundColor)
  // console.log(lowerCaseName)
  return (
    <div className="main-div cursorPointer">
      <div className="collapsible-title" style={{ color }}>
        <span>{name}</span>
      </div>

      <div
        className="collapsible-content-report"
        style={{ color, fontWeight: "bold" }}
      >
        <span>{body}</span>
      </div>
      <div className="main-program-name">
        <div
          className="program-name"
          style={{
            fontSize:
              name.toLowerCase() === "well developed" ? "17px" : "initial",
            color,
            border: `2px solid ${color}`,
          }}
        >
          <span>{displayRating.toUpperCase()} </span>
        </div>
      </div>
    </div>
  )
}

export default NewReportColapsible
