import React, { useEffect } from "react"

import ProgramMatrixCollapsible from "./ProgramMatrixCollapsible"

function ProgramMatrixSELScan({ matrix, currentSchool }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="report program-matrix">
        <ProgramMatrixCollapsible
          data={window.gon.DESS_SEL_STANDARDS}
          matrix={matrix}
          currentSchool={currentSchool}
          colors={[
            "#A6192E",
            "#A6192E",
            "#33485D",
            "#33485D",
            "#33485D",
            "#33485D",
            "#E7A922",
            "#E7A922",
          ]}
          isDessaReport={false}
        />
      </div>
    </div>
  )
}

export default ProgramMatrixSELScan
