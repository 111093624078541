import React from "react"
import PropTypes from "prop-types"
import { Col, Form, Select } from "antd"
import componentStyles from "../../styles"
import commonStrings from "../../commonStrings"

const { Option } = Select

function SystemAdminSelectCell({
  colDimensions,
  label,
  name,
  rules,
  keys,
  isDetail,
  defaultValues,
  mode,
  disabled,
  options,
  values,
  handleChange,
  placeholder,
}) {
  return (
    <Col
      xs={24}
      sm={24}
      md={colDimensions ? colDimensions.md : 8}
      lg={colDimensions ? colDimensions.lg : 8}
    >
      <Form.Item
        label={label}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={name}
        rules={rules}
        key={keys}
        initialValue={
          isDetail
            ? defaultValues
            : defaultValues
              ? commonStrings.yes
              : commonStrings.no
        }
      >
        {mode === commonStrings.multiple ? (
          <Select
            showArrow
            mode="multiple"
            id={name}
            name={name}
            disabled={disabled}
            options={options}
            style={
              disabled
                ? componentStyles.inputStyleNewDisabled
                : componentStyles.inputStyleNew
            }
            bordered={false}
            placeholder={placeholder}
            optionFilterProp="children"
            onChange={(value) => handleChange(value)}
          >
            {options
              && options.map((value, index) => (
                <Option key={index} value={value.id} name={value.name}>
                  {value.name}
                </Option>
              ))}
          </Select>
        ) : (
          <Select
            id={name}
            name={name}
            disabled={disabled}
            style={
              disabled
                ? componentStyles.inputStyleNewDisabled
                : componentStyles.inputStyleNew
            }
            bordered={false}
            placeholder={placeholder}
            optionFilterProp="children"
            onChange={(value) => handleChange(value)}
          >
            {values
              && values.map((value, index) => (
                <Option key={index} value={value.value} name={name}>
                  {value.name}
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>
    </Col>
  )
}

SystemAdminSelectCell.propTypes = {
  colDimensions: PropTypes.shape({
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  keys: PropTypes.string.isRequired,
  isDetail: PropTypes.bool,
  defaultValues: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  mode: PropTypes.oneOf(["multiple"]),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

SystemAdminSelectCell.defaultProps = {
  colDimensions: {},
  rules: [],
  isDetail: false,
  defaultValues: null,
  mode: "single",
  disabled: false,
  options: [],
  values: [],
  placeholder: "Select an option",
}

export default SystemAdminSelectCell
