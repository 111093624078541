import React from "react"
import _ from "underscore"

function ResourceFilterer({ filters, setFilters, resources }) {
  const filter = (category, e) => {
    if (e.target.checked) {
      filters.push(category)
    } else {
      filters = _.without(filters, category)
    }
    setFilters(filters)
  }
  const categoryCounts = window.gon.RESOURCE_CATEGORIES.reduce(
    (counts, category) => {
      counts[category] = resources.filter(
        (resource) => resource.category === category,
      ).length
      return counts
    },
    {},
  )
  return (
    <ul className="filter row">
      {window.gon.RESOURCE_CATEGORIES.map((category, index) => (
        <li key={index} className="col-4 mb-2 form-check">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e) => filter(category, e)}
              checked={filters.includes(category)}
            />
            {category} ({categoryCounts[category] || 0}){" "}
          </label>
        </li>
      ))}
    </ul>
  )
}
export default ResourceFilterer
