import "core-js/stable"
import "regenerator-runtime/runtime"
import "chart.js/auto"
import "antd/dist/antd.css"

// Support component names relative to this directory:
const componentRequireContext = require.context("components", true)
const ReactRailsUJS = require("react_ujs")

ReactRailsUJS.useContext(componentRequireContext)
