import React from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer"
import _ from "underscore"
import uaLogo from "../../../assets/images/rsp-logo.png"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import commonStrings from "../../commonStrings"
import { removeHtmlTags } from "../../utilities"

const ImplementationReportPdf = ({
  rubric,
  generalColor,
  currentschoolLogo,
  rubricNumber,
  graphUrlBase64,
}) => {
  Font.register({
    family: "Archivo Narrow",
    fonts: [
      {
        src: archivoNarrow,
      },
      {
        src: archivoNarrowBold,
        fontWeight: 700,
      },
    ],
  })

  const styles = StyleSheet.create({
    viewStylesTop: {
      marginTop: 60,
    },
    page: {
      fontFamily: "Archivo Narrow",
      paddingTop: 14,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },

    uaLogo: {
      width: 115,
      height: 64,
    },
    uaLogo1: {
      width: 115,
      height: 64,
    },
    imageContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    heading: {
      marginTop: "5px",
      marginBottom: "5px",
      fontSize: "14px",
      fontFamily: "Archivo Narrow",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      fontSize: "11",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10",
      fontWeight: "700",
      minHeight: "60",
    },
    widthHeading: {
      width: "20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "700",
    },
    widthDescription: {
      width: "65%",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "400",
      paddingRight: "10",
      paddingLeft: "10",
    },
    widthRating: {
      width: "9%",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "700",
    },
    widthNumber: {
      width: "10%",
      alignItems: "center",
      justifyContent: "center",
    },
    stages: {
      marginTop: "10",
      border: "2px solid red",
      padding: "3px",
      width: "15%",
      fontSize: "11",
      textAlign: "center",
      fontWeight: "600",
      textTransform: "uppercase",
    },
    description: {
      fontSize: "11",
      marginTop: "10",
    },
    need: {
      width: "50%",
      textAlign: "center",
    },
    strength: {
      width: "50%",
      textAlign: "center",
    },
    dFlex: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    pointsAreaOfStrength: {
      width: "50%",
    },
    TextAreaOfStrenght: {
      paddingLeft: "5px",
      paddingRight: "10px",
      fontSize: "10px",
      marginTop: "5px",
      marginBottom: "6px",
    },
    pointsAreaOfNeed: { width: "50%" },
    TextAreaOfNeed: {
      paddingLeft: "5px",
      paddingRight: "10px",
      fontSize: "10px",
      marginTop: "6px",
      marginBottom: "5px",
    },
    mergeViews: {
      display: "flex",
      flexDirection: "row",
    },
    paddingX: {
      paddingLeft: "7px",
      paddingRight: "7px",
    },
  })

  return (
    <Document pageCount={4}>
      <Page size="A4" style={styles.page}>
        <View style={styles.imageContainer} fixed>
          <Image style={styles.uaLogo} src={uaLogo} />

          {currentschoolLogo && (
            <Image style={styles.uaLogo1} src={currentschoolLogo} />
          )}
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>{rubricNumber}</Text>
          <View style={styles.viewStylesTop}>
            <Image src={graphUrlBase64} />
          </View>
        </View>
      </Page>
      {_.first(rubric.report, 4).map((stage, index) => (
        <Page size="A4" style={styles.page}>
          <View style={styles.imageContainer} fixed>
            <Image style={styles.uaLogo} src={uaLogo} />
            {currentschoolLogo && (
              <Image style={styles.uaLogo1} src={currentschoolLogo} />
            )}
          </View>
          <View style={styles.section}>
            <Text style={styles.heading}>{rubricNumber}</Text>
          </View>

          <View
            style={[
              styles.header,
              {
                border: `2px solid ${generalColor[index].generalColor}`,
                color: generalColor[index].generalColor,
              },
            ]}
          >
            <View style={styles.widthHeading}>
              <Text>{stage.label}</Text>
            </View>
            <View style={styles.widthDescription}>
              <Text>
                <Text style={{ fontWeight: "700" }}>
                  {rubric.template.stages[index].heading} -{" "}
                </Text>
                {rubric.template.stages[index].description}
              </Text>
            </View>
            <View style={styles.widthRating}>
              <Text>Current Score</Text>
            </View>
            <Text>{stage.completion}%</Text>
          </View>
          <View
            style={[
              styles.stages,
              {
                border: `2px solid ${generalColor[index].generalColor}`,
                color: generalColor[index].generalColor,
              },
            ]}
          >
            <Text>{stage.rank}</Text>
          </View>
          <View style={styles.description}>
            <Text>{stage.narrative}</Text>
          </View>
          <View style={[styles.dFlex]}>
            <View
              style={[
                styles.strength,
                {
                  color: generalColor[index].generalColor,
                  borderBottom: `2px solid ${generalColor[index].generalColor}`,
                  borderRight: `1px solid ${generalColor[index].generalColor}`,
                  marginRight: "-1px",
                },
              ]}
            >
              <Text>{commonStrings.areasOfStrength}</Text>
            </View>
            <View
              style={[
                styles.need,
                {
                  color: generalColor[index].generalColor,
                  borderBottom: `2px solid ${generalColor[index].generalColor}`,
                  borderLeft: `1px solid ${generalColor[index].generalColor}`,
                  marginLeft: "-1px",
                },
              ]}
            >
              <Text>{commonStrings.areasOfNeed}</Text>
            </View>
          </View>
          <View style={styles.mergeViews}>
            <View
              style={[
                styles.pointsAreaOfStrength,
                {
                  borderRight: `1px solid ${generalColor[index].generalColor}`,
                  minHeight: "300px",
                },
              ]}
            >
              {stage.areas_of_strength.map((value) => {
                const stringText = removeHtmlTags(value.__html)

                return (
                  <View style={[styles.mergeViews, styles.paddingX]}>
                    <Text style={{ color: "#383838" }}>•</Text>
                    <Text style={[styles.TextAreaOfStrenght]}>
                      {stringText}
                    </Text>
                  </View>
                )
              })}
            </View>
            <View
              style={[
                styles.pointsAreaOfNeed,
                {
                  borderLeft: `1px solid ${generalColor[index].generalColor}`,
                  minHeight: "300px",
                },
              ]}
            >
              {stage.areas_of_need.map((value) => {
                const stringText = removeHtmlTags(value.__html)

                return (
                  <View style={[styles.mergeViews, styles.paddingX]}>
                    <Text style={{ color: "#383838" }}>•</Text>
                    <Text style={[styles.TextAreaOfNeed]}>{stringText}</Text>
                  </View>
                )
              })}
            </View>
          </View>
        </Page>
      ))}{" "}
    </Document>
  )
}

export default ImplementationReportPdf
