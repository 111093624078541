import React from "react"
import { Tooltip } from "antd"
import { TruncateText, defaultSort, urlToSchool } from "../../../utilities"

export const convertArrayToObject = (arr) => {
  const finalArray = []

  // Iterate over each element in the array
  for (let i = 0; i < arr.length; i += 1) {
    // Create an object with 'label' and 'value' properties
    const obj = {
      label: arr[i], // Use the array element as the value for 'label'
      value: arr[i], // Use the array element as the value for 'value'
    }

    // Add the object to the finalArray
    finalArray.push(obj)
  }

  // Return the finalArray
  return finalArray
}

export const convertObjectToArray = (dataObject) => {
  const array = [
    {
      title: "School Name",
      dataIndex: "name",
      render: (value, _) => (
        <Tooltip
          style={{ borderRadius: "8px" }}
          placement="top"
          title={value}
          onClick={() => {
            urlToSchool(_.school_id)
          }}
        >
          <a className="styling-name-matrix">{TruncateText(value, 11)}</a>
        </Tooltip>
      ),
      width: 100,
      sorter: {
        compare: (a, b) => defaultSort(a.name, b.name),
      },
    },
  ]

  Object.entries(dataObject).forEach(([key, values]) => {
    const childArray = values.map((item) => ({
      title: item,
      dataIndex: "matrix_hash",
      render: (value) => {
        let displayedValue
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          displayedValue = value[key][item]
        } else {
          displayedValue = value.indicators_count[key][item]
        }
        return <a>{displayedValue}</a>
      },
      width: 50,
    }))

    array.push({
      title: key,
      dataIndex: "matrix_hash",
      children: childArray,
      width: 50,
    })
  })

  return array
}

export const getStandardValue = (data, key, type) => {
  let result
  data.forEach((item) => {
    if (item.standards && item.standards[key]) {
      result = item.standards[key][type]
    }
  })
  return result
}

export const convertObjectToArrayWithHover = (dataObject, rawData, type) => {
  const array = [
    {
      title: "School Name",
      dataIndex: "name",
      render: (value, _) => (
        <Tooltip
          style={{ borderRadius: "8px" }}
          placement="top"
          title={value}
          onClick={() => {
            urlToSchool(_.school_id)
          }}
        >
          <a className="styling-name-matrix">{TruncateText(value, 11)}</a>
        </Tooltip>
      ),
      width: 100,
      sorter: {
        compare: (a, b) => defaultSort(a.name, b.name),
      },
    },
  ]

  Object.entries(dataObject).forEach(([key, values]) => {
    const childArray = values.map((item) => ({
      title: (
        <div style={{ cursor: "pointer" }}>
          <Tooltip title={`${getStandardValue(rawData, item, type)}`}>
            {item}
          </Tooltip>
        </div>
      ),

      dataIndex: "matrix_hash",
      render: (value) => {
        let displayedValue
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          displayedValue = value[key][item]
        } else {
          displayedValue = value.indicators_count[key][item]
        }
        return <a>{displayedValue}</a>
      },
      width: 50,
    }))

    array.push({
      title: key,
      dataIndex: "matrix_hash",
      children: childArray,
      width: 50,
    })
  })

  return array
}

export const transformData = (data, version) => data.map((item) => {
  const transformedItem = { name: item.name }

  if (version) {
    // Iterate over each key in item.matrix_hash
    Object.keys(item.matrix_hash).forEach((key) => {
      const domainData = item.matrix_hash[key]

      // Iterate over each key in domainData
      Object.keys(domainData).forEach((domain) => {
        const indicators = domainData[domain]

        // Iterate over each key-value pair in indicators
        Object.entries(indicators).forEach(([indicator, value]) => {
          transformedItem[indicator] = value
        })
      })
    })
  } else {
    // Iterate over each key in item.matrix_hash
    Object.keys(item.matrix_hash).forEach((domain) => {
      const domainData = item.matrix_hash[domain]

      // Iterate over each key-value pair in domainData
      Object.entries(domainData).forEach(([key, value]) => {
        transformedItem[key] = value
      })
    })
  }

  return transformedItem
})

export const makeCsvHeaders = (headerValue) => {
  const headers = [
    { label: "name", key: "name" },
    ...Object.values(headerValue)
      .flat()
      .map((label) => ({
        label,
        key: label,
      })),
  ]

  return headers
}
