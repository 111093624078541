import React from "react"

function TChart({
  column1Title, column2Title, column1Items, column2Items,
}) {
  return (
    <div className="d-flex justify-content-between t-chart">
      <div className="w-100 column1">
        <Title>{column1Title}</Title>
        <ul>
          {column1Items.map((item, i) => (<ListItem key={i}>{item}</ListItem>))}
        </ul>
      </div>
      <div className="w-100 column2">
        <Title>{column2Title}</Title>
        <ul>
          {column2Items.map((item, i) => (<ListItem key={i}>{item}</ListItem>))}
        </ul>
      </div>
    </div>
  )
}

function Title({ children }) {
  return <p className="text-center text-xl font-weight-bold">{children}</p>
}

function ListItem({ children }) {
  return <li className="mb-2 leading-tight">{children}</li>
}

export default TChart
