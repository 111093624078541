import React from "react"
import _ from "underscore"
import LineChart from "../charts/LineChart"

function MetricChart({ metric, color, showTitle }) {
  const filterValues = (metricValues) => metricValues.filter((metricValue) => metricValue.id)

  const data = filterValues(metric.metric_values)
  const labels = _.map(data, (d) => d.date)
  const datasets = [{
    label: metric.name,
    data: _.map(data, (d) => d.value),
    backgroundColor: color ? `rgba(${color}, 0.2)` : "rgba(220,220,220,0.2)",
    borderColor: color ? `rgba(${color}, 1)` : "rgba(220,220,220,1)",
  }]

  return (
    <div>
      {showTitle && <h5 className="mb-3">{metric.name}</h5>}
      {data.length >= 2 && <LineChart labels={labels} datasets={datasets} />}
      {data.length < 2 && <h6>Add at least two datapoints to see chart</h6>}
    </div>
  )
}

export default MetricChart
