export const SelCompetency = (countMatrixHash) => {
  const labels = Object.keys(countMatrixHash)
  const data = Object.values(countMatrixHash)

  const studentSelCompetency = {
    labels,
    datasets: [
      {
        backgroundColor: "#3FA4A5",
        borderWidth: 2,
        data,
        borderRadius: 3,
        barThickness: data.length > 8 ? 20 : 40,
        fill: false,
        datalabels: {
          marginTop: 20,
        },
      },
      {
        backgroundColor: "#C2E8E8",
        borderWidth: 2,
        data: data.map((value) => 42 - value),
        borderRadius: 3,
        barThickness: data.length > 8 ? 20 : 40,
        fill: false,
        datalabels: {
          display: false,
        },
      },
    ],
  }

  return studentSelCompetency
}

export const SelMatrix = (studentSelmatrixCompetency) => {
  const labels = Object.keys(studentSelmatrixCompetency)
  const data = Object.values(studentSelmatrixCompetency)

  const StudentSelMatrix = {
    labels,
    datasets: [
      {
        backgroundColor: "#3FA4A5",
        borderWidth: 2,
        data: data.map((value) => value),
        fill: false,
        datalabels: {
          marginTop: 20,
        },
        borderRadius: 8,
        barThickness: data.length > 6 ? 40 : 70,
      },
    ],
  }
  return StudentSelMatrix
}

export const SelDessaGraph = (studentDessaCompetencyCount) => {
  const labels = Object.keys(studentDessaCompetencyCount)
  const data = Object.values(studentDessaCompetencyCount)

  const StudentSelDessa = {
    labels,
    datasets: [
      {
        backgroundColor: "#3FA4A5",
        borderWidth: 2,
        data: data.map((value) => value),
        barThickness: data.length > 9 ? 25 : 40,
        fill: false,
        datalabels: {
          marginTop: 20,
        },
        borderRadius: 8,
      },
    ],
  }
  return StudentSelDessa
}
