export const sustainabilityMainHeaders = [
  {
    title: "Commitment",
    description:
      "The demonstrated willingness of the school/district to participate in activities that create a continued capacity for delivery of program concepts and tenants.",
    color: "#A6192E",
  },
  {
    title: "Integration",
    description:
      "The steps the school/district has taken to incorporate program delivery into relevant ongoing and future activities.",
    color: "#E7A922",
  },
  {
    title: "Innovation",
    description:
      "The steps the school or district has taken to adapt the program in order to meet the needs of the stakeholders (in accordance with core program tenets).",
    color: "#0CA4A5",
  },
]

export const Textcolors = [
  {
    color: "#A6192E",
  },
  {
    color: "#33485D",
  },
  {
    color: "#E7A922",
  },
  {
    color: "#0CA4A5",
  },
  {
    color: "#A6192E",
  },
  {
    color: "#33485D",
  },
  {
    color: "#E7A922",
  },
  {
    color: "#0CA4A5",
  },
]

export const subHeaders = [
  {
    id: 1,
    generalColor: "#A6192E",
  },
  {
    id: 2,
    generalColor: "#E7A922",
  },
  {
    id: 3,
    generalColor: "#0CA4A5",
  },
]

export const sustainabilityCollapse = [
  {
    id: 1,
    generalColor: "#A6192E",
  },
  {
    id: 2,
    generalColor: "#33485D",
  },
  {
    id: 3,
    generalColor: "#E7A922",
  },
  {
    id: 4,
    generalColor: "#0CA4A5",
  },
  {
    id: 5,
    generalColor: "#A6192E",
  },
  {
    id: 6,
    generalColor: "#33485D",
  },
  {
    id: 7,
    generalColor: "#E7A922",
  },
  {
    id: 8,
    generalColor: "#0CA4A5",
  },
]
