import React, { useEffect } from "react"
import { notification } from "antd"

const ForgotPassword = ({ errors }) => {
  const [api, contextHolder] = notification.useNotification()
  useEffect(() => {
    if (errors) {
      api.error({
        message: "Notification",
        description: (
          <span
            className="forgotPassword"
            dangerouslySetInnerHTML={{
              __html: errors,
            }}
          />
        ),
        placement: "topRight",
        closeIcon: false,
        className: "custom-class-forgotPasswprd",
        duration: 8,
      })
    }
  }, [errors])

  return <div>{contextHolder}</div>
}

export default ForgotPassword
