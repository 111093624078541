import React, { useState, useEffect } from "react"
import { Dropdown, Space } from "antd"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { areYouSure, errorAlert } from "../../utilities"
import commonStrings from "../../commonStrings"

function RubricHeaderButtons({
  rubric,
  type,
  canCreate,
  setListingSubmitted,
  dropDownIconRotate,
  setDropDownIconRotate,
  showListingSubmitted,
}) {
  const [submitting, setSubmitting] = useState(false)
  const [labelClicked, setLabelClicked] = useState(false)

  const getIndicatorNumber = (data) => {
    const filteredData = data.filter(
      (item) => item.rating === null && (item.explanation || item.evidence.length > 0),
    )
    const finalData = filteredData.map((value) => value.index)

    if (finalData.length === 0) {
      return ""
    }
    if (finalData.length === 1) {
      return finalData[0].toString()
    }
    const lastValue = finalData.pop()
    return `${finalData.join(", ")}, and ${lastValue}`
  }

  const handleErrors = (errors) => {
    setSubmitting(false)
    errorAlert({ html: errors.join("<br/><br/>") })
  }

  const confirmSubmit = () => {
    areYouSure("info", () => {
      setSubmitting(true)
      APIClient.put(
        `/${type}_rubrics/${rubric.id}/submit`,
        {},
        () => {
          window.location.href = `/${type}_rubrics/${rubric.id}`
        },
        (errors) => {
          handleErrors(errors)
        },
      )
    })
  }

  const submitClicked = () => {
    APIClient.get(
      `/sustainability_rubrics/${rubric.id}/load_sustainability_rubric`,
      (response) => {
        const remainingRatingOfIndicators = getIndicatorNumber(
          response.indicators,
        )
        if (remainingRatingOfIndicators.length > 0) {
          handleErrors([
            `Please <b>select rating</b> for indicator ${remainingRatingOfIndicators}`,
          ])
        } else {
          APIClient.put(
            `/${type}_rubrics/${rubric.id}/validate`,
            {},
            () => {
              confirmSubmit()
            },
            (errors) => {
              handleErrors(errors)
            },
          )
        }
      },
    )
  }

  const unsubmitClicked = () => {
    areYouSure("info", () => {
      setSubmitting(true)
      APIClient.put(`/${type}_rubrics/${rubric.id}/unsubmit`, {}, () => {
        window.location.href = `/${type}_rubrics/${rubric.id}`
      })
    })
  }

  useEffect(() => {
    if (labelClicked) {
      fetch("/sustainability_rubrics/new")
        .then((response) => response.url)
        .then((url) => {
          window.location.href = url
        })
        .catch((error) => {
          console.error("Error fetching URL:", error)
        })
    }
  }, [labelClicked])

  const createNewRubric = () => {
    if (!labelClicked && canCreate) {
      setLabelClicked(true)
    }
  }
  const items = [
    {
      label: <div style={{ fontFamily: "Archivo Narrow" }}>View Rubrics</div>,
      key: "0",
      onClick: () => {
        setListingSubmitted(true)
        setDropDownIconRotate(false)
      },
    },
    {
      label: (
        <div
          style={{ fontFamily: "Archivo Narrow" }}
          className={`${!canCreate && "disabledDropdown"}`}
        >
          {commonStrings.createNewRubric}
        </div>
      ),
      key: "1",
      onClick: () => {
        if (canCreate) {
          createNewRubric()
          setDropDownIconRotate(false)
        }
      },
      disabled: !canCreate,
    },
  ]

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="d-flex justify-content-center align-items-center">
        {rubric.workflow_state === "draft" && !showListingSubmitted && (
          <button
            className="btn submit btn-sm fontWeight600 marginLeft5 btnGenerateReport"
            disabled={submitting}
            onClick={submitClicked}
          >
            {submitting ? "Submitting…" : "Generate Report"}
          </button>
        )}
      </div>
      {/* <div className="d-flex justify-content-center align-items-center">
        {canCreate && (
          <button
            className="btn sustainabilityRubricButton btn-sm fontWeight600 marginLeft5"
            onClick={createNewRubric}
          >
            {commonStrings.createNewRubric}
          </button>
        )}
      </div> */}
      <div className="d-flex justify-content-center align-items-center">
        {rubric.workflow_state === "submitted" && !showListingSubmitted && (
          <button
            className="btn btn-warning btn-sm update-button-remover-hover fontWeight600 marginLeft5"
            disabled={submitting}
            onClick={unsubmitClicked}
          >
            {submitting
              ? commonStrings.unSubmitting
              : commonStrings.updateRubric}
          </button>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <span className="dropdown-rubric cursorPointer">
          <Dropdown
            className="btnRubric"
            menu={{
              items,
            }}
            trigger={["click"]}
            onOpenChange={(state) => {
              setDropDownIconRotate(state)
            }}
          >
            <div>
              <Space>
                <span className="fontSize14">Rubrics</span>
                <div className="mb-1">
                  {dropDownIconRotate ? <UpOutlined /> : <DownOutlined />}
                </div>
              </Space>
            </div>
          </Dropdown>
        </span>
      </div>
    </div>
  )
}

export default RubricHeaderButtons
