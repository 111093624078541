import React from "react"
import ChartDataLabels from "chartjs-plugin-datalabels"
import Chart from "chart.js/auto"
import { Bar } from "react-chartjs-2"

// import faker from "faker";
Chart.register(ChartDataLabels)

const BarCharts = ({
  state, yMax, yMin, stepSize, BarChartDesc,
}) => (
  <Bar
    height={220}
    className="displayFlexDirectionColumn"
    data={state}
    options={{
      scales: {
        y: {
          min: yMin,
          max: yMax,
          ticks: {
            stepSize,
          },
          grid: {
            display: true,
            drawBorder: false,
            borderDash: [3, 3],
          },
          stacked: true,
        },

        x: {
          stacked: true,
          grid: {
            display: true,
            drawBorder: false,
            borderDash: [3, 3],
          },
        },
      },

      plugins: {
        // tooltip: {
        //   callbacks: {
        //     title() {
        //       return labels
        //     },
        //     // label: function () {
        //     //   return "lable";
        //     // },
        //     afterLabel() {
        //       return ""
        //     },
        //   },
        //   displayColors: false,
        // },
        tooltip: {
          enabled: false, // <-- this option disables tooltips
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          anchor: "end",
          font: { size: "11" },
        },
        title: {
          display: true,
          text: BarChartDesc,
          position: "bottom",
          color: "#000000",
          font: {
            size: 17,
          },
          marginTop: 5,
        },
      },
    }}
  />
)

export default BarCharts
