import React from "react"
import { Collapse, Row, Col } from "antd"
import { UpOutlined } from "@ant-design/icons"

import commonStrings from "../../../commonStrings"

const Collapsible = (props) => {
  const {
    title, id, tabContent, rubric, subHeaderColor,
  } = props

  const { Panel } = Collapse

  function filterObjectsByTitle(title) {
    switch (title) {
      case "Program Observability":
        return rubric.indicators.filter(
          (obj) => obj.index >= 1 && obj.index <= 6,
        )
      case "Data":
        return rubric.indicators.filter(
          (obj) => obj.index >= 7 && obj.index <= 9,
        )
      case "Program Review":
        return rubric.indicators.filter(
          (obj) => obj.index >= 10 && obj.index <= 13,
        )
      case "Communication":
        return rubric.indicators.filter(
          (obj) => obj.index >= 14 && obj.index <= 19,
        )
      case "Leadership":
        return rubric.indicators.filter(
          (obj) => obj.index >= 20 && obj.index <= 24,
        )
      case "Range":
        return rubric.indicators.filter((obj) => obj.index === 25)
      case "Coordination":
        return rubric.indicators.filter((obj) => obj.index === 26)
      case "Program Fit/Reinvention":
        return rubric.indicators.filter((obj) => obj.index === 27)
      default:
        return []
    }
  }
  const renderUngradedMessage = (filteredIndicators) => {
    const hasNonNullExplanation = filteredIndicators.some(
      (indicator) => indicator.rating !== null,
    )

    return (
      !hasNonNullExplanation && (
        <div className="fontSize20 fontWeight">{commonStrings.ungraded}</div>
      )
    )
  }

  return (
    <div className="div-collapse" id={id}>
      <Collapse
        style={{
          background: "white",
          color: subHeaderColor,
          border: `1px solid ${subHeaderColor}`,
          borderTopColor: `${subHeaderColor}00`,
        }}
        expandIcon={({ isActive }) => (
          <UpOutlined
            style={{
              fontSize: "25px",
              color: subHeaderColor,
              cursor: "pointer",
            }}
            rotate={isActive ? 0 : 180}
          />
        )}
        expandIconPosition="end"
        collapsible="header"
      >
        <Panel
          header={(
            <div className="container panelCollapse">
              <div className="d-flex justify-content-between">
                <div
                  className="title-collapse-sus"
                  style={{ color: `${subHeaderColor}` }}
                >
                  <div>{title}</div>
                  {renderUngradedMessage(filterObjectsByTitle(title))}
                </div>
              </div>
            </div>
          )}
          key={id}
        >
          <Row style={{ backgroundColor: "white" }}>
            <Col xs={24} sm={24} md={24} lg={24}>
              {tabContent}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  )
}

export default Collapsible
