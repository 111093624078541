import React, { useState } from "react"
import { Collapse } from "antd"
import { UpOutlined } from "@ant-design/icons"
import NewReportColapsible from "./NewReportCollapsible"
import ReportContent from "./ReportConent"
import Evidence from "./Evidence"
import Artifacts from "./Artifacts"
import { sortObjectsByOrder, customSortByRating } from "../../utilities"

const { Panel } = Collapse

function NestedCollapsibleforReport({
  title,
  description,
  color,
  rubric: initialRubric,
}) {
  const [rubric] = useState(initialRubric)
  const predefinedOrder = {
    "Program Review": 1,
    Communication: 2,
    Leadership: 3,
    "Physical Visibility": 4,
    "School Norms": 5,
    Data: 6,
    Coordination: 7,
    Range: 8,
    "Program Fit/Reinvention": 9,
  }

  function filterObjectsByTitle(arr, title) {
    const lowerCase = title.toLowerCase()
    switch (lowerCase) {
      case "commitment":
        return arr.filter((obj) => [
          "Data",
          "Program Review",
          "Communication",
          "Leadership",
          "Physical Visibility",
          "School Norms",
        ].includes(obj.name))
      case "integration":
        return arr.filter((obj) => ["Range", "Coordination"].includes(obj.name))
      case "innovation":
        return arr.filter((obj) => ["Program Fit/Reinvention"].includes(obj.name))
      default:
        return []
    }
  }

  return (
    <div style={{ border: `1px solid ${color}` }}>
      <div
        className="new-collapsible-div"
        style={{ backgroundColor: color, color: "white", width: "100%" }}
      >
        <div className="c-ttile">
          <div className="new-collapsible-title new-collapsible-title-report d-flex align-items-center pl-4">
            <span>{title}</span>
          </div>
        </div>

        <div className="collapsible-content-new">
          <span>{description}</span>
        </div>
      </div>
      <div>
        {customSortByRating(
          filterObjectsByTitle(
            sortObjectsByOrder(rubric.report, predefinedOrder),
            title,
          ),
        ).map((values, index) => (
          <div className="sustainability-report-ui" key={index}>
            <Collapse
              style={{ padding: "0px 0px" }}
              expandIcon={({ isActive }) => (
                <UpOutlined
                  style={{
                    fontSize: "25px",
                    color,
                    cursor: "pointer",
                  }}
                  rotate={isActive ? 0 : 180}
                />
              )}
              expandIconPosition="end"
              collapsible="header"
            >
              <Panel
                style={{
                  borderBottom: `0.6px solid ${color}`,
                  backgroundColor: "white",
                }}
                header={(
                  <NewReportColapsible
                    name={values.name}
                    body={values.body}
                    rating={values.rating}
                    color={color}
                  />
                )}
              >
                <div className="report-content">
                  <ReportContent area={values} reportName={values.name} />
                </div>
                <div className="report-evidence">
                  <Evidence area={values} reportName={values.name} />
                </div>
                <div className="report-artifacts">
                  <Artifacts area={values} reportName={values.name} />
                </div>
              </Panel>
            </Collapse>
          </div>
        ))}
      </div>
    </div>
  )
}

export default NestedCollapsibleforReport
