import React, { useState, useEffect } from "react"
import {
  Button, Card, Row, Col, Form, notification,
} from "antd"
import { EditOutlined } from "@ant-design/icons"
import _ from "underscore"
import FormComponent from "./FormComponent"
import componentStyles from "../../styles"
import commonStrings from "../../commonStrings"
import { areYouSure } from "../../utilities"

const AddNewUser = ({
  adminUser,
  adminResources,
  userResources,
  adminSchools,
}) => {
  const [api, contextHolder] = notification.useNotification()
  const [form] = Form.useForm()
  const [submitForm, setSubmitForm] = useState({})
  const [adminResourcesValue, setAdminResourcesValue] = useState([])
  const [adminSchoolsValue, setAdminSchoolsValue] = useState([])

  const optionValueAdmins = (admin) => {
    const opt = []
    if (!_.isEmpty(admin)) {
      admin.map((value) => opt.push({ value: value.id, label: value.name }))
    }
    return opt
  }
  const editCancel = () => {
    areYouSure("warning", () => {
      window.location.replace("/system_admins")
    })
  }
  const openNotification = (response) => {
    let status = null
    let responseMessage = null
    let error = null
    let errorName = null

    if (!_.isEmpty(response.errors)) {
      error = response.errors
      responseMessage = _.first(Object.values(error))
      errorName = _.first(Object.keys(error))
    } else {
      responseMessage = response.message
    }
    if (!_.isEmpty(response.status)) {
      status = response.status
    }
    if (!_.isEmpty(response.errors)) {
      api.error({
        message: `Notification: ${status}`,
        description: <span>{`${errorName} ${responseMessage}`}</span>,
        placement: "topRight",
        closeIcon: false,
        className: "custom-class",
      })
    } else {
      api.success({
        message: `Notification: ${status}`,
        description: <span>{responseMessage}</span>,
        placement: "topRight",
        closeIcon: false,
        className: "custom-class",
      })
      setTimeout(() => {
        window.location.replace("/system_admins")
      }, 2000)
    }
  }
  const handleSubmit = () => {
    APIClient.post(
      "/system_admins_items/",
      {
        submitForm,
      },
      (result) => {
        openNotification(result)
      },
      (error) => {
        openNotification(error)
      },
    )
  }
  useEffect(() => {
    if (adminSchools) {
      setAdminSchoolsValue(optionValueAdmins(adminSchools))
    }
    if (adminResources) {
      setAdminResourcesValue(optionValueAdmins(adminResources))
    }
  }, [adminSchools, adminResources])

  return (
    <>
      <Row justify="center" className="my-3">
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card
            style={componentStyles.cardStyle}
            title={
              <span className="form-heading">{commonStrings.addNewUser}</span>
            }
            extra={(
              <div className="displayFlexSpaceAround">
                <Button
                  onClick={() => {
                    editCancel()
                  }}
                  className="width150FormBtnBack"
                >
                  {commonStrings.cancel}
                </Button>

                <Button
                  className="width150FormButtons  px-2"
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => {
                        handleSubmit()
                      })
                      .catch(() => {})
                  }}
                >
                  {commonStrings.save}
                  <EditOutlined />
                </Button>
              </div>
            )}
          >
            {contextHolder}
            <FormComponent
              setSubmitForm={setSubmitForm}
              cardTitle={commonStrings.addNewUser}
              isUserNewForm
              form={form}
              edit
              adminUser={adminUser}
              adminResources={adminResourcesValue}
              userResources={userResources}
              adminSchools={adminSchoolsValue}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AddNewUser
