import React from "react"

function ImplementationRubricTabs({ rubric, step, setStep }) {
  const tabName = (name) => {
    const split = name.split(" ")
    return (
      <div><span>{split[0]}</span><br /><span className="text-uppercase">{split[1]} {split[2]}</span></div>
    )
  }

  const popovers = [
    "This report summarizes your progress towards implementing high-quality SEL. Review the report and create action steps to increase your scores.",
    "This report highlights in yellow indicators with opportunity for development. Review these indicators, determine which you will focus on first, and think through how to adapt and execute your plan. Indicators where the execution of the plan is complete will highlight in green celebrating your success.",
  ]

  return (
    <ul className="nav nav-tabs">
      {rubric.template.stages.map((stage, index) => (
        <li key={index} className={step === (index + 1) ? "active" : null}>
          <a
            className="nav-link"
            onClick={() => setStep(index + 1)}
          >{tabName(stage.name)}
          </a>
        </li>
      ))}
      {rubric.workflow_state === "submitted"
        && (
        <li className={step === 5 ? "active" : null}>
          <a
            className="nav-link"
            onClick={() => setStep(5)}
            data-toggle="popover"
            data-content={`<h6 class='mb-0'>${popovers[0]}</h6>`}
          >Report<br />&nbsp;
          </a>
        </li>
        )}
      {rubric.workflow_state === "submitted"
        && (
        <li className={step === 6 ? "active" : null}>
          <a
            className="nav-link"
            onClick={() => setStep(6)}
            data-toggle="popover"
            data-content={`<h6 class='mb-0'>${popovers[1]}</h6>`}
          >Plan<br />&nbsp;
          </a>
        </li>
        )}
    </ul>
  )
}

export default ImplementationRubricTabs
