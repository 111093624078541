import React, { useState, useEffect } from "react"
import {
  Col, Row, Tooltip, Table, Drawer, Button,
} from "antd"
// import { CSVLink } from "react-csv"
import BarCharts from "./Charts/BarCharts"
import commonStrings from "../../commonStrings"
import {
  maxYAxisOffSet,
  defaultSort,
  idIntoSchoolId,
  addMissingProperty,
  removeDuplicates,
  sortArray,
  urlToSchool,
  TruncateText,
} from "../../utilities"

const ImplementationCollaspe = (props) => {
  const { countImplementationHash, type1Chart1YMax, graphValueImplementation } = props

  const [createTableList, setCreateTableList] = useState([])
  const [createTableColums, setCreateTableColums] = useState([])
  // const [createXls, setCreateXls] = useState([])
  const [getClick, setGetClick] = useState("")
  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }

  // const extractCsvObject = () => {
  //   const array = []
  //   createTableList.map((value) => {
  //     array.push({
  //       name: value.name,
  //       Commitment_Stage_I: value.results.Commitment_Stage_I,
  //       Commitment_Stage_II: value.results.Commitment_Stage_II,
  //       Implementation_Stage_I: value.results.Implementation_Stage_I,
  //       Implementation_Stage_II: value.results.Implementation_Stage_II,
  //     })

  //     return 0
  //   })

  //   return array
  // }
  useEffect(() => {
    if (getClick === "row_1_component_1") {
      setCreateTableColums([
        {
          title: "School Name",
          dataIndex: "name",
          render: (text, _) => (
            <Tooltip
              style={{ borderRadius: "8px" }}
              placement="top"
              title={text}
            >
              <a
                className="styling-name-implementation"
                onClick={() => {
                  urlToSchool(_.school_id)
                }}
              >
                {TruncateText(text, 15)}
              </a>
            </Tooltip>
          ),
          width: 50,
          fixed: "left",
          sorter: {
            compare: (a, b) => defaultSort(a.name, b.name),
          },
        },
        {
          title: "Commitment Stage I",
          dataIndex: "results",
          render: (hash) => <a>{hash.Commitment_Stage_I}</a>,
          width: 80,
        },
        {
          title: "Commitment Stage II",
          dataIndex: "results",
          render: (hash) => <a>{hash.Commitment_Stage_II}</a>,
          width: 80,
        },
        {
          title: "Implementation Stage I",
          dataIndex: "results",
          render: (hash) => <a>{hash.Implementation_Stage_I}</a>,
          width: 80,
        },
        {
          title: "Implementation Stage II",
          dataIndex: "results",
          render: (hash) => <a>{hash.Implementation_Stage_II}</a>,
          width: 80,
        },
        {
          title: "Overall Score",
          dataIndex: "results",
          render: (value) => <a>{Math.floor(value.Overall)}</a>,
          width: 70,
        },
      ])
      // setCreateXls([
      //   { label: "School Name", key: "name" },
      //   { label: "Commitment Stage I", key: "Commitment_Stage_I" },
      //   { label: "Commitment Stage II", key: "Commitment_Stage_II" },
      //   { label: "Implementation Stage I", key: "Implementation_Stage_I" },
      //   { label: "Implementation Stage II", key: "Implementation_Stage_II" },
      // ])

      setCreateTableList(
        sortArray(
          removeDuplicates(
            addMissingProperty(idIntoSchoolId(graphValueImplementation)),
          ),
        ),
      )
    }
  }, [getClick, open])

  const type1Chart1 = {
    labels: [
      "Commitment I",
      "Commitment II",
      "Implementation I",
      "Implementation II",
    ],
    datasets: [
      {
        backgroundColor: "#E7A922",
        borderWidth: 2,
        data: [
          countImplementationHash.Commitment_Stage_I,
          countImplementationHash.Commitment_Stage_II,
          countImplementationHash.Implementation_Stage_I,
          countImplementationHash.Implementation_Stage_II,
        ],
        borderRadius: 3,
        barThickness: 40,
        fill: false,
        datalabels: {
          marginTop: 20,
        },
      },
      {
        backgroundColor: "#F9E9C8",
        borderWidth: 2,
        data: [
          maxYAxisOffSet(type1Chart1YMax, 3)
            - countImplementationHash.Commitment_Stage_I,
          maxYAxisOffSet(type1Chart1YMax, 3)
            - countImplementationHash.Commitment_Stage_II,
          maxYAxisOffSet(type1Chart1YMax, 3)
            - countImplementationHash.Implementation_Stage_I,
          maxYAxisOffSet(type1Chart1YMax, 3)
            - countImplementationHash.Implementation_Stage_II,
        ],
        borderRadius: 3,
        barThickness: 40,
        fill: false,
        datalabels: {
          display: false,
        },
      },
    ],
  }

  return (
    <Row>
      {open && (
        <div className="schoolSubmissionScores">
          <Drawer
            title={(
              <span
                style={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#E7A922",
                }}
              >
                {commonStrings.schoolsSubmissionsScores}
              </span>
            )}
            placement="top"
            height="87%"
            closable
            onClose={onClose}
            destroyOnClose
            open={open}
            mask
            maskClosable
            // extra={(
            //   <Space>
            //     <CSVLink
            //       data={extractCsvObject()}
            //       headers={createXls}
            //       style={{ backgroundColor: "#E7A922" }}
            //       className="csvDownloadBtn"
            //       filename="matrix_submissions.csv"
            //     >
            //       {commonStrings.exportToCsv}
            //     </CSVLink>
            //   </Space>
            // )}
          >
            <Row justify="center">
              <Col xs={24} sm={24} md={17} lg={17}>
                <Table
                  className="table-design table-striped-rows drawer-table"
                  columns={createTableColums}
                  dataSource={createTableList}
                  size="middle"
                  scroll={{ x: 1450, y: 470 }}
                />
              </Col>
            </Row>

            <div className="closeButtonDiv">
              <Button
                className="btnClose"
                onClick={() => {
                  onClose()
                }}
              >
                {commonStrings.close}
              </Button>
            </div>
          </Drawer>
        </div>
      )}
      <Col
        xs={24}
        sm={24}
        md={11}
        lg={11}
        className="boxShadowOnHover marginTopBottom "
        onClick={() => {
          setOpen(true)
          setGetClick("row_1_component_1")
        }}
      >
        <div>
          <BarCharts
            state={type1Chart1}
            yMax={maxYAxisOffSet(type1Chart1YMax, 3)}
            yMin={0}
            stepSize={3}
            BarChartDesc={commonStrings.graphImplementation}
          />
        </div>
      </Col>
      {/* <Col xs={24} sm={24} md={24} lg={24}>
        <div className="marginTop60 displayFlexEnd">
          <Button className="exportToCSVButtonImpl">
            {commonStrings.exportToCsv}
          </Button>
        </div>
      </Col> */}

      <Col xs={24} sm={24} md={24} lg={24}>
        <div className="marginTop60 displayFlexEnd">
          <Button
            onClick={() => {
              window.location.href = "/implementation_rubrics/program_export"
            }}
            className="exportToCSVButtonImpl"
          >
            {commonStrings.exportToCsv}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default ImplementationCollaspe
