import React, { useEffect, useState, useLayoutEffect } from "react"
import { Divider, Modal, Button } from "antd"
import _ from "underscore"
import Loader from "./CommonComponents/Loader"
import ImplementationImage from "../../assets/images/Implementation_Dashboard_Icon.png"
import SustainabilityImage from "../../assets/images/Sustainability_Dashboard_Icon.png"
import MatrixImage from "../../assets/images/Matrix_Dashboard_Icon.png"
import Accordion from "./Accordion"
import MatrixCollaspe from "./CommonComponents/MartixCollaspe"
import ImplementationCollaspe from "./CommonComponents/ImplementationCollaspe"
import SustainabilityCollaspe from "./CommonComponents/SustainabilityCollaspe"
import {
  sustainabilityIndicators,
  implementationIndicators,
  scoreMap,
} from "./CommonComponents/DashboardObjects"
import {
  getActivitiesCount,
  getActivitySum,
  rubricsArrayForGraphs,
  arrMergeProgramSchools,
  getUniqueSchoolsWithLatestSubmission,
  flattenArrayOfRubrics,
  schoolsStudentActivitiesHash,
  eliminateObjectsWithFalse,
  programSchoolsForDropDown,
  transformObject,
} from "./CommonComponents/ProgramDashboardFunctions"
import { removeDuplicates } from "../utilities"
import ReleaseVideo from "./ReleaseVideo"
import {
  uaDarkBlue,
  uaGold,
  uaTeal,
  uaTealHex,
  uaGoldHex,
  uaDarkBlueHex,
} from "../enums"
import commonStrings from "../commonStrings"
import CommonColors from "../commonColors"

function Dashboard(props) {
  const {
    matrixHref,
    implementationHref,
    sustainabilityHref,
    implementationRubrics,
    sustainabilityRubrics,
    sustainabilityProgressStage,
    currentProgram,
    indicatorTypes,
    programSchools,
    latestReleaseNotes,
    currentUser,
  } = props

  const [selectedIndicatorType, setSelectedIndicatorType] = useState(
    _.first(indicatorTypes),
  )
  const [
    updatedSustainabilityProgressStage,
    setUpdatedSustainabilityProgressStage,
  ] = useState([])
  const [matrixCompetencyGraph, setMatrixCompetencyGraph] = useState([])
  const [studentDessAcitivities, setStudentDessaActivities] = useState([])
  const [apiResponseDessaGraph, setApiResponseDessaGraph] = useState([])
  const [schoolSelected, setSelectedSchool] = useState(0)
  const [copyProgramSchools, setCopyProgramsSchools] = useState([])
  const [graphValueImplementation, setGraphValueImplementation] = useState([])
  const [open, setOpen] = useState(false)
  const [countMatrixHash, setCountMatrixHash] = useState({})

  const [apiAdultSelActivitiesCountState, setApiAdultSelActivitiesCountState] = useState([])
  const [countMatrixHashSelAdult, setCountMatrixHashSelAdult] = useState({})
  const [studentSelmatrixCompetency, setStudentSelmatrixCompetency] = useState(
    {},
  )
  const [adultSelActivitiesCount, setAdultSelActivitiesCount] = useState({})
  const [dessaCompetencyCount, setDessaCompetencyCount] = useState({})
  const [studentDessaCompetencyCount, setStudentDessaCompetencyCount] = useState({})
  const [sustainabilityGraph, setSustainabilityGraph] = useState(
    sustainabilityIndicators,
  )
  const [countImplementationHash, setCountImplementation] = useState(
    implementationIndicators,
  )
  const [
    schoolSelectedAdultSelActivitiesCount,
    setSchoolSelectedAdultSelActivitiesCount,
  ] = useState(0)

  const [
    schoolsImplementationSubmissions,
    setSchoolsImplementationSubmissions,
  ] = useState([])
  const [
    schoolsSustainabilitySubmissions,
    setSchoolsSustainabilitySubmissions,
  ] = useState([])

  const [matrixIndicators, setMatrixIndicators] = useState({})
  const [dessaIndicators, setDessaIndicators] = useState({})

  const [studentMatrixTable, setStudentMatrixTable] = useState([])
  const [adultMatrixTable, setAdultMatrixTable] = useState([])

  const [spinner, setSpinner] = useState(true)
  const [graphLoader, setGraphLoader] = useState(true)

  const [dashBoardObject, setDashBoardObject] = useState({})

  const incrementArrayValuesForMatrixHash = (array) => {
    const updatedState = { ...transformObject(matrixIndicators) }

    if (array && !_.isEmpty(updatedState)) {
      let objKey = {}
      removeDuplicates(array).forEach((obj) => {
        objKey = transformObject(matrixIndicators)
        Object.values(objKey).forEach((value, index) => {
          const key = Object.keys(objKey)[index]
          const matrixHash = obj.matrix_hash[key]
          if (Object.values(matrixHash).some((val) => val > 0)) {
            updatedState[key] += 1
          }
        })
      })
    }
    return updatedState
  }

  const indicatorCountDessaGraphs = (array, title) => {
    const titleToFunctionMap = {
      student_sel_graph_2: schoolSelected
        ? setStudentSelmatrixCompetency
        : () => setStudentSelmatrixCompetency(
          getActivitySum(array, transformObject(matrixIndicators)),
        ),
      studentDessaGraph: schoolSelected
        ? setStudentDessaCompetencyCount
        : () => setStudentDessaCompetencyCount(
          getActivitySum(array, transformObject(dessaIndicators)),
        ),
      adult_sel_graph_2: schoolSelectedAdultSelActivitiesCount
        ? setAdultSelActivitiesCount
        : () => setAdultSelActivitiesCount(
          getActivitySum(array, transformObject(matrixIndicators)),
        ),
      dessaGraph: schoolSelectedAdultSelActivitiesCount
        ? setDessaCompetencyCount
        : () => setDessaCompetencyCount(
          getActivitySum(array, transformObject(dessaIndicators)),
        ),
    }
    if (array.length && !spinner) {
      array.forEach((obj) => {
        const selectedFunction = titleToFunctionMap[title]
        selectedFunction(obj.matrix_hash.activities_count)
      })
    } else if (!array.length) {
      if (!_.isEmpty(matrixIndicators)) {
        setStudentSelmatrixCompetency(transformObject(matrixIndicators))
        setAdultSelActivitiesCount(transformObject(matrixIndicators))
      }
      if (!_.isEmpty(dessaIndicators)) {
        setDessaCompetencyCount(transformObject(dessaIndicators))
        setStudentDessaCompetencyCount(transformObject(dessaIndicators))
      }
    }
  }

  const incrementArrayValuesForImplementationHash = (array) => {
    if (array) {
      array.forEach((item) => {
        Object.keys(item.results).forEach((key) => {
          if (item.results[key] >= 75) {
            setCountImplementation((prevState) => ({
              ...prevState,
              [key]: prevState[key] + 1,
            }))
          }
        })
      })
    }
  }

  const updateSustainabilityGraph = (array) => {
    array.forEach((item) => {
      const score = Math.floor(item.score)
      const property = scoreMap[score]
      if (item.sustainability.length === 0 && item.score === 0) {
        setSustainabilityGraph((prevState) => ({
          ...prevState,
          not_started: prevState.not_started + 1,
        }))
      } else if (property) {
        setSustainabilityGraph((prevState) => ({
          ...prevState,
          [property]: prevState[property] + 1,
        }))
      }
    })
  }

  const studentMatrixDessaActivities = (id) => {
    if (!_.isNull(currentProgram)) {
      getActivitiesCount(
        "activities_count",
        setMatrixCompetencyGraph,
        currentProgram,
        id,
        selectedIndicatorType,
      )
      getActivitiesCount(
        "dessa_activities_count",
        setStudentDessaActivities,
        currentProgram,
        id,
        selectedIndicatorType,
      )
    }
  }

  const adultMatrixDessaActivities = (id) => {
    if (!_.isNull(currentProgram)) {
      getActivitiesCount(
        "adult_activities_count",
        setApiAdultSelActivitiesCountState,
        currentProgram,
        id,
        selectedIndicatorType,
      )
      getActivitiesCount(
        "adult_dessa_activities_count",
        setApiResponseDessaGraph,
        currentProgram,
        id,
        selectedIndicatorType,
      )
    }
  }

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      setGraphLoader(true)
      APIClient.get(
        `/program_dashboard/${currentProgram.id}/program_dashboard_data?indicator_type=${selectedIndicatorType}`,
        (response) => {
          if (response) {
            setSpinner(false)
            setGraphLoader(false)
            setDashBoardObject(response)
          }
        },
      )
    }
  }, [selectedIndicatorType])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      if (!_.isEmpty(transformObject(matrixIndicators))) {
        setCountMatrixHash(
          incrementArrayValuesForMatrixHash(
            arrMergeProgramSchools(
              dashBoardObject.matrix_submissions_indicator_competency,
              dashBoardObject.programSchools,
            ),
          ),
        )

        setCountMatrixHashSelAdult(
          incrementArrayValuesForMatrixHash(
            arrMergeProgramSchools(
              dashBoardObject.adultMatrixSubmissionsIndicatorCompetency,
              dashBoardObject.programSchools,
            ),
          ),
        )
      }
    }
  }, [matrixIndicators])

  useLayoutEffect(() => {
    if (
      !_.isEmpty(transformObject(matrixIndicators))
      && !_.isEmpty(dashBoardObject)
    ) {
      setStudentMatrixTable(
        dashBoardObject.matrix_submissions_indicator_competency,
      )

      setAdultMatrixTable(
        dashBoardObject.adultMatrixSubmissionsIndicatorCompetency,
      )
    }
  }, [selectedIndicatorType, dashBoardObject, matrixIndicators])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      adultMatrixDessaActivities(schoolSelectedAdultSelActivitiesCount)
    }
  }, [schoolSelectedAdultSelActivitiesCount, selectedIndicatorType])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      studentMatrixDessaActivities(schoolSelected)
    }
  }, [schoolSelected, selectedIndicatorType])

  useEffect(() => {
    $("[data-toggle=\"tooltip\"]").tooltip()
  }, [])

  const updateProgramIdInUrl = (id) => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    url.searchParams.set("program_id", id)
    const updatedUrl = url.toString()
    window.history.replaceState(null, null, updatedUrl)
    return updatedUrl
  }

  useEffect(() => {
    if (window.location.href.includes("school_id")) {
      window.history.replaceState(null, null, "/")
    }

    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      updateProgramIdInUrl(currentProgram.id)
    }
  }, [])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      if (!_.isEmpty(sustainabilityRubrics)) {
        setSchoolsSustainabilitySubmissions(
          getUniqueSchoolsWithLatestSubmission(
            flattenArrayOfRubrics(sustainabilityRubrics),
          ),
        )
      }
      if (!_.isEmpty(implementationRubrics)) {
        setSchoolsImplementationSubmissions(
          getUniqueSchoolsWithLatestSubmission(
            flattenArrayOfRubrics(implementationRubrics),
          ),
        )
      }

      if (!_.isEmpty(programSchools)) {
        setCopyProgramsSchools(programSchools)
      }
    }
  }, [implementationRubrics, sustainabilityRubrics, programSchools])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      setGraphValueImplementation(
        rubricsArrayForGraphs(implementationRubrics, "implementation"),
      )
    }
  }, [implementationRubrics])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      incrementArrayValuesForImplementationHash(graphValueImplementation)
    }
  }, [graphValueImplementation])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      indicatorCountDessaGraphs(matrixCompetencyGraph, "student_sel_graph_2")
    }
  }, [matrixCompetencyGraph, spinner, matrixIndicators, dessaIndicators])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      indicatorCountDessaGraphs(studentDessAcitivities, "studentDessaGraph")
    }
  }, [studentDessAcitivities, spinner, matrixIndicators, dessaIndicators])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      indicatorCountDessaGraphs(
        apiAdultSelActivitiesCountState,
        "adult_sel_graph_2",
      )
    }
  }, [
    apiAdultSelActivitiesCountState,
    spinner,
    matrixIndicators,
    dessaIndicators,
  ])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      indicatorCountDessaGraphs(
        apiResponseDessaGraph,
        commonStrings.dessaGraph,
      )
    }
  }, [apiResponseDessaGraph, spinner, matrixIndicators, dessaIndicators])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      updateSustainabilityGraph(sustainabilityProgressStage)
    }
  }, [sustainabilityProgressStage])

  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      if (!_.isEmpty(dashBoardObject)) {
        setMatrixIndicators(dashBoardObject.indicators)
        setDessaIndicators(dashBoardObject.dessa_standards)
      }
    }
  }, [dashBoardObject])
  useEffect(() => {
    if (
      !_.isNull(currentProgram)
      && sustainabilityProgressStage
      && !_.isNull(programSchools)
    ) {
      const updatedSustainabilityProgressStage = sustainabilityProgressStage.map((item) => {
        const sortedSustainability = item.sustainability
          .slice()
          .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
        return { ...item, sustainability: sortedSustainability }
      })

      setUpdatedSustainabilityProgressStage(updatedSustainabilityProgressStage)
    }
  }, [sustainabilityProgressStage, currentProgram])
  const makeAccordianObject = () => [
    {
      generalColor: uaTealHex,
      title: commonStrings.titleMatrix,
      description: commonStrings.descriptionMatrix,
      src: MatrixImage,
      color: { uaTeal },
      href: { matrixHref },
      schoolsWithSubmissions: dashBoardObject.schoolsWithMatrixSubmissions,
      titleDoughnut: commonStrings.matrixDoughnut,
      graphLoader,

      componentsCollapse: (
        <MatrixCollaspe
          dashBoardObject={dashBoardObject}
          generalColor={uaTealHex}
          studentMatrixTable={arrMergeProgramSchools(
            studentMatrixTable,
            dashBoardObject.programSchools,
          )}
          adultMatrixTable={arrMergeProgramSchools(
            adultMatrixTable,
            dashBoardObject.programSchools,
          )}
          loading={graphLoader}
          indicatorTypes={indicatorTypes}
          setSelectedIndicatorType={setSelectedIndicatorType}
          selectedIndicatorType={selectedIndicatorType}
          DessaStandards={dessaIndicators}
          Indicators={matrixIndicators}
          countMatrixHash={countMatrixHash}
          totalProgramSchool={dashBoardObject.programSchools.length || 0}
          schoolsWithAdultActivityCount={
            dashBoardObject.schoolsWithAdultActivity.length || 0
          }
          countMatrixHashSelAdult={countMatrixHashSelAdult}
          schoolsWithAdultActivity={schoolsStudentActivitiesHash(
            dashBoardObject.schoolsWith5Activities,
            dashBoardObject.programSchools,
          )}
          setOpen={setOpen}
          open={open}
          gagugeWith5Activities={schoolsStudentActivitiesHash(
            dashBoardObject.schoolsWith5Activities,
            dashBoardObject.programSchools,
          )}
          obtained={
            eliminateObjectsWithFalse(dashBoardObject.schoolsWith5Activities)
              .length || 0
          }
          studentSelmatrixCompetency={studentSelmatrixCompetency}
          studentDessaCompetencyCount={studentDessaCompetencyCount}
          rawStudentSelMatrixCompetency={arrMergeProgramSchools(
            matrixCompetencyGraph,
            dashBoardObject.programSchools,
          )}
          rawAdultSelActivitiesCount={arrMergeProgramSchools(
            apiAdultSelActivitiesCountState,
            dashBoardObject.programSchools,
          )}
          rawDessaGraph={arrMergeProgramSchools(
            apiResponseDessaGraph,
            dashBoardObject.programSchools,
          )}
          rawstudentDessAcitivities={arrMergeProgramSchools(
            studentDessAcitivities,
            dashBoardObject.programSchools,
          )}
          currentProgram={currentProgram}
          programSchoolsForDropDown={programSchoolsForDropDown(
            dashBoardObject.programSchools,
          )}
          setSelectedSchool={setSelectedSchool}
          setSchoolSelectedAdultSelActivitiesCount={
            setSchoolSelectedAdultSelActivitiesCount
          }
          adultSelActivitiesCount={adultSelActivitiesCount}
          dessaCompetencyCount={dessaCompetencyCount}
        />
      ),
      copyProgramSchools: dashBoardObject.programSchools,
      schoolsWithSubmissionsCount:
        dashBoardObject.schoolsWithMatrixSubmissions.length,
    },
    {
      generalColor: uaGoldHex,
      title: commonStrings.titleImplementation,
      description: commonStrings.descriptionImplementation,
      src: ImplementationImage,
      color: { uaGold },
      href: { implementationHref },
      titleDoughnut: commonStrings.implementationDoughnut,
      componentsCollapse: (
        <ImplementationCollaspe
          setOpen={setOpen}
          open={open}
          countImplementationHash={countImplementationHash}
          type1Chart1YMax={programSchools.length || 0}
          graphValueImplementation={graphValueImplementation}
        />
      ),
      schoolsWithSubmissions: schoolsImplementationSubmissions,
      copyProgramSchools,
      schoolsWithSubmissionsCount: implementationRubrics.length,
    },
    {
      generalColor: uaDarkBlueHex,
      title: commonStrings.titleSustainability,
      description: commonStrings.descriptionSustainability,
      src: SustainabilityImage,
      color: { uaDarkBlue },
      href: { sustainabilityHref },
      titleDoughnut: commonStrings.sustainabilityDoughnut,
      componentsCollapse: (
        <SustainabilityCollaspe
          sustainabilityGraph={sustainabilityGraph}
          sustainabilityProgressStage={updatedSustainabilityProgressStage}
        />
      ),
      schoolsWithSubmissions: schoolsSustainabilitySubmissions,
      copyProgramSchools,
      schoolsWithSubmissionsCount: sustainabilityRubrics.length,
    },
  ]
  useEffect(() => {
    if (!_.isNull(currentProgram) && !_.isNull(programSchools)) {
      if (!_.isEmpty(dashBoardObject) && !graphLoader) {
        makeAccordianObject()
      }
    }
  }, [dashBoardObject, graphLoader])
  const logoutUser = () => {
    const csrfToken = document.querySelector("[name=csrf-token]").content
    fetch("/users/sign_out", {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 204) {
        window.location.href = "/users/sign_in"
      }
    })
  }

  return (
    <>
      {!_.isNull(currentProgram) && !_.isNull(programSchools) && (
        <>
          {!_.isEmpty(latestReleaseNotes)
            && currentUser.release_notes
            && (currentUser.role === "program user"
              || currentUser.role === "program admin") && (
              <div>
                <ReleaseVideo
                  currentUser={currentUser}
                  latestReleaseNotes={latestReleaseNotes}
                />
              </div>
          )}
          <Divider />
          {!spinner && !_.isEmpty(matrixIndicators) ? (
            makeAccordianObject().map((props) => (
              <>
                <Accordion
                  generalColor={props.generalColor}
                  title={props.title}
                  description={props.description}
                  src={props.src}
                  color={props.color}
                  href={props.href}
                  schoolsWithSubmissions={props.schoolsWithSubmissions}
                  titleDoughnut={props.titleDoughnut}
                  componentsCollapse={props.componentsCollapse}
                  copyProgramSchools={props.copyProgramSchools}
                  schoolsWithSubmissionsCount={
                    props.schoolsWithSubmissionsCount
                  }
                  graphLoader={props.graphLoader}
                />
                <Divider />
              </>
            ))
          ) : (
            <Loader />
          )}
        </>
      )}

      {!_.isNull(currentProgram) && _.isNull(programSchools) && (
        <div
          className="noSchoolWithProgram"
          style={{ color: CommonColors.UA_RED }}
        >
          <span>{commonStrings.noSchoolAssociationText}</span>&nbsp;
          <span>
            <b>{currentProgram.name}</b>
          </span>
        </div>
      )}

      {_.isNull(currentProgram) && (
        <Modal
          width={650}
          title={<div className="noSchoolModal">Alert!</div>}
          open
          closable={false}
          footer={[
            <Button
              className="noSchoolModalButton"
              onClick={() => {
                logoutUser()
              }}
            >
              Logout
            </Button>,
          ]}
          centered
        >
          <div>
            <span className="noSchoolModalBody">
              {commonStrings.noProgramString}{" "}
              <a href="mailto:rsptech@urbanassembly.org">
                rsptech@urbanassembly.org
              </a>
            </span>
          </div>
        </Modal>
      )}
    </>
  )
}

export default Dashboard
