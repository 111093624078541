import React, { useState, useEffect } from "react"
import { Layout, Menu } from "antd"
import { VideoCameraOutlined } from "@ant-design/icons"
import commonStrings from "../../commonStrings"

const { Sider, Content } = Layout

const Video = () => {
  const [videos, setVideos] = useState([])
  const [selectedKeys, setSelectedKeys] = useState({
    Guidance: "Implementation",
    Release: "Release Category 1",
    Other: "Implementation",
  })

  const [currentCategory, setCurrentCategory] = useState("Guidance")

  const handleMenuClick = (category, key) => {
    setSelectedKeys((prevState) => ({
      ...prevState,
      [category]: key,
    }))
    setCurrentCategory(category)
  }

  useEffect(() => {
    APIClient.get("/video_resources/video", (response) => {
      if (response) {
        setVideos(response)
      }
    })
  }, [])

  console.log(videos)

  const renderContent = () => {
    if (videos.length === 0) {
      return (
        <span
          style={{ display: "flex", flex: "auto", justifyContent: "center" }}
        >
          {commonStrings.noVideosAvailable}
        </span>
      )
    }

    const filteredVideos = videos.filter((video) => {
      if (currentCategory === "Other") {
        return (
          !video.guidance
          && (video.name_section.includes(selectedKeys.Other)
            || video.name_section.length === 0)
        )
      }
      return (
        video.name_section.includes(selectedKeys[currentCategory])
        || video.name_section.length === 0
      )
    })

    if (filteredVideos.length === 0 && !videos.video && !videos.youtube_link) {
      return (
        <span
          style={{ display: "flex", flex: "auto", justifyContent: "center" }}
        >
          {commonStrings.noVideo} {selectedKeys[currentCategory]}
        </span>
      )
    }

    return (
      <>
        {filteredVideos.map((video) => (
          <div
            key={video.id}
            style={{
              display: "flex",
              marginBottom: "20px",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <div style={{ marginRight: "20px" }}>
                {video.youtube_link ? (
                  <iframe
                    title="video"
                    width="460"
                    height="315"
                    src={`https://www.youtube.com/embed/${new URL(
                      video.youtube_link,
                    ).searchParams.get("v")}`}
                    frameBorder="0"
                    allowFullScreen
                  />
                ) : (
                  <video width="460" height="315" controls>
                    <track
                      kind="captions"
                      srcLang="en"
                      label="English captions"
                    />
                    <source src={video.video.url} type="video/mp4" />
                    {commonStrings.browserSupport}
                  </video>
                )}
              </div>
              <div>
                <div>
                  <span
                    style={{ textTransform: "uppercase" }}
                    className="video-title"
                  >
                    {video.name}
                  </span>
                </div>
                <div>
                  {video.description && (
                    <span className="video-description">
                      {video.description}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <hr
              style={{
                border: "none",
                width: "100%",
                backgroundColor: "rgba(211, 211, 211, 0.5)",
                height: "3px",
              }}
            />
          </div>
        ))}
      </>
    )
  }

  const Categories = [
    {
      key: "Guidance",
      title: "Guidance",
      icon: <VideoCameraOutlined />,
      items: [
        "Implementation",
        "Sustainability",
        "SEL Scan",
        "DESSA Report",
        "SEL Quality",
        "Activity Details",
      ],
    },
    {
      key: "Release",
      title: "Release",
      icon: <VideoCameraOutlined />,
      items: [
        "Implementation",
        "Sustainability",
        "SEL Scan",
        "DESSA Report",
        "SEL Quality",
        "Activity Details",
      ],
    },
    {
      key: "Other",
      title: "Other",
      icon: <VideoCameraOutlined />,
      items: [
        "Implementation",
        "Sustainability",
        "SEL Scan",
        "DESSA Report",
        "SEL Quality",
        "Activity Details",
      ],
    },
  ]

  const renderSubMenu = () => (
    <>
      {Categories.map((category) => (
        <Menu.SubMenu
          key={category.key}
          title={category.title}
          icon={category.icon}
          className="custom-submenu"
        >
          {(category.items || []).map((item) => (
            <Menu.Item
              key={`${category.key}-${item}`}
              className="menu-item"
              onClick={() => handleMenuClick(category.key, item)}
            >
              {item}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ))}
    </>
  )

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={200}
        theme="light"
        style={{ overflow: "auto", height: "100vh", left: 0 }}
      >
        <Menu
          mode="inline"
          selectedKeys={[`${currentCategory}-${selectedKeys[currentCategory]}`]}
          onClick={() => {}}
          style={{ height: "100%", borderRight: 0 }}
          className="video-menu"
        >
          {renderSubMenu()}
        </Menu>
      </Sider>

      <Layout>
        <Content style={{ margin: "7px" }}>
          <div className="site-layout-background" style={{ padding: 24 }}>
            {renderContent()}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Video
