import { Form, Input, Card } from "antd"
import React from "react"
import componentStyles from "../../styles"

function FormItemInputCell(props) {
  const handleBlur = (e) => {
    if (e && e.target && e.target.name !== undefined) {
      const currentValue = e.target.value
      const initialValue = props.defaultValues

      if (currentValue !== initialValue) {
        props.handleChange(currentValue, e.target.name)
      }
    }
  }

  return (
    <Card style={componentStyles.cardStyle}>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={props.name}
        label={props.label}
        rules={props.rules}
        key={props.keys}
        initialValue={props.defaultValues}
      >
        <Input
          style={componentStyles.inputStyle}
          name={props.name}
          disabled={props.disabled}
          maxLength={props.maxLength}
          prefix={props.prefix}
          type={props.type}
          min={props.min || "0"}
          onBlur={(value) => handleBlur(value)}
        />
      </Form.Item>
    </Card>
  )
}

export default FormItemInputCell
