import React, { useEffect, useState } from "react"
import {
  Row, Col, Divider, Select,
} from "antd"
import _ from "underscore"
import VideoTabs from "../VideoTabs"
import {
  countAttributes,
  filterRecordsById,
  sortOnCategory,
} from "../../utilities"
import VideoCard from "../VideoCard"
import VideoModal from "../VideoModal"
import LatestReleaseTab from "../LatestReleaseTab"
import ReleaseVidoeCard from "../ReleaseVidoeCard"

const VideoResources = ({ currentUser, latestReleaseNotes }) => {
  const [videosArray, setVideosArray] = useState([])
  const [tabButtons, setTabButtons] = useState([])
  const [activeButton, setActiveButton] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [clickedVideoObject, setClickedVideoObject] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")

  const initialCallForVideos = () => {
    APIClient.get("/video_resources/video", (response) => {
      setVideosArray(response)
    })
  }

  const getFilteredVideos = () => {
    setFilteredData(filterRecordsById(activeButton, videosArray))
  }

  const returnCategorizedData = (selectedCategory, array) => {
    setFilteredData(sortOnCategory(selectedCategory, array))
    setTabButtons(
      countAttributes(sortOnCategory(selectedCategory, videosArray)),
    )
  }

  useEffect(() => {
    initialCallForVideos()
  }, [])

  useEffect(() => {
    if (videosArray.length === 0 && !_.isEmpty(latestReleaseNotes) > 0) {
      setTabButtons([])
      setActiveButton(4)
    } else {
      const newTabButtons = countAttributes(
        sortOnCategory(selectedCategory, videosArray),
      )
      setTabButtons(newTabButtons)

      if (newTabButtons.length === 0) {
        setActiveButton(4)
      } else if (activeButton === 4 && newTabButtons.length > 0) {
        setActiveButton(1)
      }
    }
  }, [videosArray, selectedCategory, latestReleaseNotes])

  useEffect(() => {
    if (videosArray.length > 0) {
      getFilteredVideos()
    }
  }, [videosArray, activeButton])

  useEffect(() => {
    if (selectedCategory) {
      returnCategorizedData(
        selectedCategory,
        filterRecordsById(activeButton, videosArray),
      )
    } else {
      getFilteredVideos()
    }
  }, [selectedCategory, activeButton])

  const categoryArray = [
    { value: 1, label: "Program Dashboard" },
    { value: 2, label: "Matrix" },
    { value: 3, label: "Implementation Rubric" },
    { value: 4, label: "Sustainability Rubric" },
    { value: 5, label: "N/A" },
  ]

  return (
    <Row justify="center" className="deployedFontFamily">
      <VideoModal
        openStatus={openModal}
        setOpenStatus={setOpenModal}
        clickedVideoObject={clickedVideoObject}
      />
      {(videosArray.length > 0 || !_.isEmpty(latestReleaseNotes)) && (
        <>
          <Col xs={24} sm={24} md={24} lg={24} className="d-flex">
            {tabButtons.map((record, index) => (
              <VideoTabs
                key={index}
                title={record.title}
                count={record.count}
                isSelected={activeButton}
                setIsSelected={setActiveButton}
                id={record.id}
              />
            ))}
            <Col>
              <LatestReleaseTab
                title="Release"
                count={latestReleaseNotes.length}
                isSelected={activeButton}
                setIsSelected={() => setActiveButton(4)}
                id={4}
              />
            </Col>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Divider />
          </Col>
          {activeButton !== 4 && (
            <Col xs={24} sm={24} md={24} lg={24} className="mb-3">
              <div className="video-select">
                <Select
                  allowClear
                  style={{ width: "20%", borderRadius: "9px" }}
                  placeholder="Select Name Section"
                  options={categoryArray}
                  onSelect={(singleValue, object) => {
                    setSelectedCategory(object.label)
                  }}
                  onClear={() => {
                    setSelectedCategory("")
                  }}
                />
              </div>
            </Col>
          )}
        </>
      )}
      <Col xs={24} sm={24} md={24} lg={24}>
        {activeButton === 4 ? (
          <ReleaseVidoeCard
            latestReleaseNotes={latestReleaseNotes}
            currentUser={currentUser}
            setOpenModal={setOpenModal}
            setClickedVideoObject={setClickedVideoObject}
          />
        ) : (
          <VideoCard
            filteredData={filteredData}
            setOpenModal={setOpenModal}
            setClickedVideoObject={setClickedVideoObject}
          />
        )}
      </Col>
    </Row>
  )
}
export default VideoResources
