import _, { isEmpty } from "underscore"

export const getActivitiesCount = (
  path,
  onSuccess,
  currentProgram,
  schoolId = null,
  indicatorType = null,
) => {
  const url = `/program_dashboard/${currentProgram.id}/${path}${schoolId ? `?school_id=${schoolId}` : ""
  }${!isEmpty(indicatorType)
    ? `${schoolId ? "&" : "?"}indicator_type=${indicatorType}`
    : ""
  }`
  APIClient.get(url, onSuccess)
}

export const getActivitySum = (arr, headers) => {
  const activityKeys = Object.keys(headers)
  const activitySums = {}

  arr.forEach((item) => {
    const activitiesCount = item.matrix_hash.activities_count
    activityKeys.forEach((key) => {
      if (!activitySums[key]) {
        activitySums[key] = 0
      }
      activitySums[key] += activitiesCount[key]
    })
  })
  return activitySums
}

export const rubricsArrayForGraphs = (array, purpose) => {
  const flatArray = []
  array.forEach((newArray) => {
    flatArray.push(_.first(newArray[1]))
  })
  if (purpose === "implementation") {
    flatArray.forEach((obj) => {
      const updatedResults = {}
      Object.keys(obj.results).forEach((key) => {
        const newKey = key.replace(/ /g, "_")
        updatedResults[newKey] = obj.results[key]
      })
      obj.results = updatedResults
    })
    return flatArray
  }
  return undefined
}

export const arrMergeProgramSchools = (array1, array2) => array1.map((obj1) => {
  const matchingObj = array2.find(
    (obj2) => obj2.id === obj1.program_matrix.school_id,
  )
  if (!matchingObj) return obj1

  const mergedObj = {
    name: matchingObj.name,
    school_id: obj1.program_matrix.school_id,
    id: matchingObj.id,
    program_matrix: obj1.program_matrix,
    matrix_hash: obj1.matrix_hash,
  }
  return mergedObj
})

export const flattenArrayOfRubrics = (rubrics) => {
  const refinedArray1 = []
  const refinedArray2 = []
  rubrics.map((value) => {
    refinedArray1.push(value[1])
    return 0
  })
  refinedArray1.map((value) => {
    value.map((value) => {
      refinedArray2.push(value)
      return 0
    })
    return 0
  })
  return refinedArray2
}

export const getUniqueSchoolsWithLatestSubmission = (arrayWithDuplication) => {
  const uniqueSchools = Object.values(
    arrayWithDuplication.reduce((acc, cur) => {
      if (
        !acc[cur.school_id]
        || Date.parse(acc[cur.school_id].submitted_at)
        < Date.parse(cur.submitted_at)
      ) {
        acc[cur.school_id] = cur
      }
      return acc
    }, {}),
  )

  return uniqueSchools
}

export const schoolsStudentActivitiesHash = (
  schoolsWith5Activities,
  programSchools,
) => schoolsWith5Activities.map((schoolsWith5ActivitiesObj) => {
  const matchingProgramSchoolsObj = programSchools.find(
    (programSchoolsObj) => programSchoolsObj.id
      === schoolsWith5ActivitiesObj.program_matrix.school_id,
  )
  if (matchingProgramSchoolsObj) {
    return {
      ...schoolsWith5ActivitiesObj,
      program_matrix: {
        ...schoolsWith5ActivitiesObj.program_matrix,
        name: matchingProgramSchoolsObj.name,
      },
    }
  }
  return schoolsWith5ActivitiesObj
})

export const eliminateObjectsWithFalse = (array) => {
  const newArray = []
  array.forEach((value) => {
    if (value.matrix_hash.all_categories_have_5_or_more_activities) {
      newArray.push(value)
    }
  })

  return newArray
}
export const programSchoolsForDropDown = (programSchools) => {
  const transformedArray = programSchools.map((item) => ({
    label: item.name,
    value: item.id,
  }))
  return transformedArray
}

export const findHighestValue = (obj) => {
  let maxVal = -Infinity
  const keys = Object.keys(obj)
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    if (obj[key] > maxVal) {
      maxVal = obj[key]
    }
  }
  return maxVal
}

export const transformObject = (inputObject) => {
  const transformedObject = {}

  Object.keys(inputObject).forEach((key) => {
    transformedObject[key] = 0
  })

  return transformedObject
}

export const matrixGraphsValue = (
  type,
  setState,
  currentProgram,
  setIsPending,
) => {
  APIClient.get(
    `/program_dashboard/${currentProgram.id}/${type}`,
    (response) => {
      if (response) {
        setState(transformObject(response))
        setIsPending(false)
      }
    },
  )
}
