import React, { useState } from "react"
import { Collapse } from "antd"
import { UpOutlined } from "@ant-design/icons"
import "../../assets/stylesheets/partials/programdashboard.scss"
import CollapseWidget from "./CollapseWidget"

const Accordion = (props) => {
  const {
    title,
    description,
    src,
    href,
    schoolsWithSubmissions,
    copyProgramSchools,
    componentsCollapse,
    titleDoughnut,
    generalColor,
    schoolsWithSubmissionsCount,
    graphLoader,
  } = props
  const { Panel } = Collapse
  const [open, setOpen] = useState(false)
  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <UpOutlined
          className={`ArrowIcon:${title}`}
          style={{
            fontSize: "25px",
            color: generalColor,
            cursor: "pointer",
          }}
          rotate={isActive ? 0 : 180}
        />
      )}
      expandIconPosition="end"
      collapsible="header"
      bordered={false}
    >
      <Panel
        header={(
          <CollapseWidget
            graphLoader={graphLoader}
            generalColor={generalColor}
            title={title}
            description={description}
            src={src}
            href={href}
            schoolsWithSubmissions={schoolsWithSubmissions}
            copyProgramSchools={copyProgramSchools}
            titleDoughnut={titleDoughnut}
            schoolsWithSubmissionsCount={schoolsWithSubmissionsCount}
            setOpen={setOpen}
            open={open}
          />
        )}
        key={title}
      >
        {componentsCollapse}
      </Panel>
    </Collapse>
  )
}

export default Accordion
