import React from "react"

function SustainabilityRubricTabs({ rubric, step, setStep }) {
  return (
    <ul className="nav nav-tabs">
      {rubric.template.sections.map((section, index) => (
        <li key={index} className={step === (index + 1) ? "active" : null}>
          <a
            className="nav-link"
            onClick={() => setStep(index + 1)}
          >{section.label}
          </a>
        </li>
      ))}
      {rubric.workflow_state === "submitted"
      && (
      <li className={step === 9 ? "active" : null}>
        <a
          className="nav-link"
          onClick={() => setStep(9)}
        >Report
        </a>
      </li>
      )}
    </ul>
  )
}

export default SustainabilityRubricTabs
