import React from "react"
import { Doughnut } from "react-chartjs-2"
import { uaGold, uaTeal } from "../../enums"

function DoughnutChart({ progress, onClick }) {
  const parsedProgress = parseInt(progress, 10)
  const data = {
    datasets: [{
      data: [parsedProgress, 100 - parsedProgress],
      backgroundColor: [
        `rgba(${uaTeal}, 0.75)`,
        `rgba(${uaGold}, 0.75)`,
      ],
      borderWidth: [0, 0],
    }],
  }

  const options = {
    cutoutPercentage: 50,
    onClick: (event, element) => {
      if (element.length && onClick) {
        onClick(element[0].index)
      }
    },
    onHover: (event, element) => {
      if (onClick) {
        event.native.target.style.cursor = element[0] ? "pointer" : "default"
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => ` ${tooltipItem.formattedValue}%`,
        },
      },
    },
  }

  return (
    <div className="mb-3">
      <Doughnut data={data} options={options} />
    </div>
  )
}

export default DoughnutChart
