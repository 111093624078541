import React, { useState } from "react"
import _ from "underscore"
import ProgramMatrixFilters from "./Filters"

function ActivityDetails({ matrix }) {
  const [filters, setFilters] = useState({
    categories: [], tiers_of_support: [], standards: [], dessa_competencies: [],
  })

  const standards = (key) => {
    const hash = {}
    _.pluck(window.gon.SEL_STANDARDS, "standards").forEach((array) => { _.extend(hash, array) })
    return hash[key]
  }

  const itemStandard = (key, type, standard) => (
    <li key={key}>
      <p>{key}: {type === "student" ? standards(key).student_indicator_language : standards(key).adult_indicator_language}</p>
      <p>{standard.connection_explanation}</p>
    </li>
  )

  const renderItem = (item) => {
    if (_.isNull(item.name)) return null

    return (
      <div key={item.id} className="report-item">
        <h6><span>{item.category}</span></h6>
        <h5>{item.activity}</h5>
        <p>{item.description}</p>
        <p>Tier of support: {item.tier_of_support && <span className={item.tier_of_support.toLowerCase()}>{item.tier_of_support}</span>}</p>
        <p>Length of time: {item.length_of_time && <span className={item.length_of_time.toLowerCase()}>{item.length_of_time}</span>}</p>
        <p>Data is collected by: {item.data_collection}</p>
        <ul>
          {_.keys(item.sel_standards).map((key) => itemStandard(key, item.adult ? "adult" : "student", item.sel_standards[key]))}
        </ul>
      </div>
    )
  }

  const filteredItems = () => {
    let items = matrix.completed_items.filter((i) => i.activity)
    if (filters.categories.length) {
      items = _.filter(items, (item) => _.contains(filters.categories, item.category))
    }
    if (filters.tiers_of_support.length) {
      items = _.filter(items, (item) => _.contains(filters.tiers_of_support, item.tier_of_support))
    }
    if (filters.standards.length) {
      items = _.filter(items, (item) => _.intersection(filters.standards, _.keys(item.sel_standards).map((k) => [k, item.adult ? "adult" : "student"].join("-"))).length)
    }
    if (filters.dessa_competencies.length) {
      const dessaCompetencies = _.uniq(_.flatten(filters.dessa_competencies))
      items = _.filter(items, (item) => _.intersection(dessaCompetencies, _.keys(item.sel_standards)).length)
    }
    return items
  }

  const indicators = () => _.sortBy(_.uniq(_.flatten(matrix.completed_items.map(
    (item) => Object.keys(item.sel_standards).map(
      (key) => ({
        label: `${key}: ${item.adult ? standards(key).adult_indicator_language : standards(key).student_indicator_language}`,
        value: [key, item.adult ? "adult" : "student"].join("-"),
      }),
    ),
  )), false, (i) => i.value), (i) => i.value)

  return (
    <div>
      <div className="report program-matrix">
        <ProgramMatrixFilters
          filters={filters}
          setFilters={setFilters}
          indicators={indicators()}
        />
        {filteredItems().map((item) => renderItem(item))}
      </div>
    </div>
  )
}

export default ActivityDetails
