import React from "react"
import { Doughnut } from "react-chartjs-2"
import commonStrings from "../commonStrings"

const Gauge = ({
  id = "gauge",
  title,
  obtained,
  total,
  generalColor,
  textSubmission,
  textWithoutSubmission,
}) => {
  const data = {
    datasets: [
      {
        data: [obtained, total - obtained],
        backgroundColor: [
          (title === "Matrix" && "#0CA4A5")
          || (title === "Implementation" && "#E7A922")
          || (title === "Sustainability" && "#33485D"),
          (title === "Matrix" && "#6DCACA")
          || (title === "Implementation" && "#F1CB7C")
          || (title === "Sustainability" && "#85919D"),
        ],
        display: true,
        borderColor: "#D1D6DC",
      },
    ],
  }
  const plugins = [
    {
      beforeDraw(chart) {
        const { width, height, ctx } = chart
        ctx.restore()
        ctx.height = "100px"
        const fontSize = (height / 160).toFixed(2)
        ctx.font = `${fontSize}em sans-serif`
        ctx.textBaseline = "top"
        const text = obtained
        const textX = Math.round((width - ctx.measureText(text).width) / 2) - 5
        const textY = height / 2 + 21
        ctx.fillStyle = generalColor
        ctx.font = "bold 25px serif"

        ctx.fillText(text, textX, textY)
        ctx.save()
      },
      datalabels: {
        display: false,
      },
    },
  ]

  const options = {
    rotation: 270,
    circumference: 180,
    // legend: {
    //   display: false,
    // },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },

      tooltip: {
        callbacks: {
          title() {
            return ""
          },
          label() {
            return ""
          },
          afterLabel() {
            return [
              `${commonStrings.totalSchools}: ${total}`,
              `${textSubmission}: ${obtained}`,
              `${textWithoutSubmission}: ${total - obtained}`,
            ]
          },
        },
        displayColors: false,
        // backgroundColor: "rgb(234, 234, 234)",
        // bodyColor:
        //   (title === "Matrix" && "#0ca4a5") ||
        //   (title === "Implementation" && "#e7a922") ||
        //   (title === "Sustainability" && "#33485d"),
      },
    },
  }

  return (
    <div style={{ width: "200px" }}>
      <Doughnut
        plugins={plugins}
        id={id}
        data={data}
        options={{
          responsive: true,
          onClick: (evt, element) => {
            if (element.length > 0) {
              // const ind = element[0].index;
            }
          },

          ...options,
        }}
      />
    </div>
  )
}

export default Gauge
