import React from "react"

function SuccessAlert({ type, diff, rubricType }) {
  // const pluralize = (count, singular) => (count === 1 ? singular : `${singular}s`)
  // {pluralize(diff, "point")}
  return (
    <div
      style={{ fontSize: "17px" }}
      className="alert alert-success text-center my-4"
    >
      🎉&nbsp;&nbsp;Congratulations! Your {type}&apos;s {rubricType} rating
      improved by <b>{diff}%</b>. 🎉
    </div>
  )
}

export default SuccessAlert
