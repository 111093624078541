import React, { useEffect, useState } from "react"
import {
  Collapse, Col, Space, Card, Tooltip,
} from "antd"
import { UpOutlined, RetweetOutlined } from "@ant-design/icons"
import TextareaAutosize from "react-textarea-autosize"
import _ from "underscore"
import { useDebounceEffect } from "ahooks"
import SustainabilityRubricIndicator from "./Indicator"
import Collapsible from "./Components/Collapsible"
import generateResponse from "../chatgpt/gpt"

import {
  Textcolors,
  subHeaders,
  sustainabilityCollapse,
} from "./Components/SustainabilityObjects"
import commonStrings from "../../commonStrings"

const { Panel } = Collapse

function NestedCollapsibleforRubric({
  title,
  description,
  color,
  rubric: initialRubric,
  parentIndex,
}) {
  const [rubric, setRubric] = useState(initialRubric)
  const [generationStates, setGenerationStates] = useState({})
  const [generatedResponses, setGeneratedResponses] = useState({})
  const [hasPasted, setHasPasted] = useState({})

  const updateTextarea = (key, group, value) => {
    const newRubric = { ...rubric }
    newRubric[key][group] = value
    setRubric(newRubric)
  }

  const handleGenerateResponse = async (group, key) => {
    const inputText = rubric[key][group]

    if (inputText && inputText.trim().length > 0) {
      setGenerationStates((prev) => ({
        ...prev,
        [`${group}_${key}`]: "generating",
      }))

      try {
        const response = await generateResponse(inputText, 1)

        setGeneratedResponses((prev) => ({
          ...prev,
          [`${group}_${key}`]: response,
        }))

        setGenerationStates((prev) => ({
          ...prev,
          [`${group}_${key}`]: "generated",
        }))
      } catch (error) {
        setGenerationStates((prev) => ({
          ...prev,
          [`${group}_${key}`]: "error",
        }))
      }
    }
  }

  const pasteGeneratedResponse = (group, key) => {
    const uniqueKey = `${group}_${key}`
    if (
      generatedResponses[uniqueKey]
      && generatedResponses[uniqueKey].trim() !== ""
    ) {
      updateTextarea(key, group, generatedResponses[uniqueKey])
      setHasPasted((prev) => ({ ...prev, [uniqueKey]: true }))
    }
  }

  const resetGenerationState = (group, key) => {
    const uniqueKey = `${group}_${key}`

    setGenerationStates((prev) => ({ ...prev, [uniqueKey]: "idle" }))

    setGeneratedResponses((prev) => ({ ...prev, [uniqueKey]: "" }))

    setHasPasted((prev) => ({ ...prev, [uniqueKey]: false }))
  }
  const saveRubric = () => {
    showSaving()
    APIClient.put(
      `/sustainability_rubrics/${rubric.id}`,
      {
        areas_of_strength: rubric.areas_of_strength,
        opportunities_for_growth: rubric.opportunities_for_growth,
      },
      () => {
        hideSaving()
      },
    )
  }

  useDebounceEffect(
    () => {
      if (rubric !== initialRubric) {
        saveRubric()
      }
    },
    [rubric],
    { wait: 250 },
  )
  const indicatorsForSubsection = (indices) => _.filter(rubric.indicators, (indicator) => _.contains(indices, indicator.index.toString()))

  const renderSubsection = (subsection, group, i, index) => {
    const isProgramObservability = rubric.template.sections[index].label === "Program Observability"

    return (
      <div key={index}>
        <div
          className="row top"
          style={{
            borderTopLeftRadius: "0.4rem",
            borderTopRightRadius: "0.4rem",
          }}
        >
          <div className="pl-4 pt-3 pb-2 new-margin-coordination" />
          <div className="col-9">
            {subsection.label && (
              <span
                className="sustainability-subSection"
                style={{ color: Textcolors[index].color }}
              >
                {subsection.label}
              </span>
            )}
            <span className="subSection-body color-black">
              <span className="label-text" style={{ color }}>
                {!isProgramObservability
                  && rubric.template.sections[index].label}
              </span>
              {subsection.body}
            </span>
            <br />
            <div className="form-group new-form-group mt-3">
              <TextareaAutosize
                minRows={5}
                className="form-control areas-of-strength auto-text-area"
                placeholder={`Areas of strength across ${group}…`}
                onChange={(e) => {
                  updateTextarea("areas_of_strength", group, e.target.value)
                  resetGenerationState(group, "areas_of_strength")
                }}
                value={rubric.areas_of_strength[group] || ""}
                disabled={rubric.workflow_state === "submitted"}
              />

              <Card
                bordered={false}
                className="implementation-rubric-indicator"
              >
                <div>
                  <button
                    className={`button-3d custom-button ${
                      generationStates[`${group}_areas_of_strength`]
                      === "generating"
                        ? "generating"
                        : ""
                    }`}
                    style={{ margin: "14px" }}
                    onClick={() => handleGenerateResponse(group, "areas_of_strength")}
                    disabled={
                      generationStates[`${group}_areas_of_strength`]
                        === "generating"
                      || generationStates[`${group}_areas_of_strength`]
                        === "generated"
                    }
                  >
                    {generationStates[`${group}_areas_of_strength`]
                    === "generated"
                      ? "Response Generated"
                      : generationStates[`${group}_areas_of_strength`]
                        === "generating"
                        ? "Generating..."
                        : "AI Text Enhancement"}
                  </button>

                  {generationStates[`${group}_areas_of_strength`]
                    === "generated"
                    && !hasPasted[`${group}_areas_of_strength`] && (
                      <Tooltip title="Replace text ">
                        <RetweetOutlined
                          onClick={() => pasteGeneratedResponse(group, "areas_of_strength")}
                          style={{
                            fontSize: "24px",
                            cursor: "pointer",
                            color: "inherit",
                            marginLeft: "10px",
                          }}
                        />
                      </Tooltip>
                  )}
                </div>

                {generatedResponses[`${group}_areas_of_strength`] && (
                  <Card
                    type="inner"
                    title={`${commonStrings.improvisedTextforNeed} ${group}`}
                    className="mt-1"
                    style={{
                      display: !hasPasted[`${group}_areas_of_strength`]
                        ? "block"
                        : "none",
                    }}
                  >
                    <div style={{ padding: "10px" }}>
                      {generatedResponses[`${group}_areas_of_strength`]}
                    </div>
                  </Card>
                )}
              </Card>
            </div>

            <TextareaAutosize
              minRows={5}
              className="form-control auto-text-area"
              placeholder={`Opportunities for growth across ${group}…`}
              onChange={(e) => {
                updateTextarea(
                  "opportunities_for_growth",
                  group,
                  e.target.value,
                )
                resetGenerationState(group, "opportunities_for_growth")
              }}
              value={rubric.opportunities_for_growth[group] || ""}
              disabled={rubric.workflow_state === "submitted"}
            />

            <button
              className={`button-3d custom-button ${
                generationStates[`${group}_opportunities_for_growth`]
                === "generating"
                  ? "generating"
                  : ""
              }`}
              style={{ margin: "14px" }}
              onClick={() => handleGenerateResponse(group, "opportunities_for_growth")}
              disabled={
                generationStates[`${group}_opportunities_for_growth`]
                  === "generating"
                || generationStates[`${group}_opportunities_for_growth`]
                  === "generated"
              }
            >
              {generationStates[`${group}_opportunities_for_growth`]
              === "generated"
                ? "Response Generated"
                : generationStates[`${group}_opportunities_for_growth`]
                  === "generating"
                  ? "Generating..."
                  : "AI Text Enhancement"}
            </button>

            {generationStates[`${group}_opportunities_for_growth`]
              === "generated"
              && !hasPasted[`${group}_opportunities_for_growth`] && (
                <Tooltip title="Replace text ">
                  <RetweetOutlined
                    onClick={() => pasteGeneratedResponse(group, "opportunities_for_growth")}
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      color: "inherit",
                      marginLeft: "10px",
                    }}
                  />
                </Tooltip>
            )}
            {generatedResponses[`${group}_opportunities_for_growth`] && (
              <Card
                type="inner"
                title={`${commonStrings.improvisedTextforopportunity} ${group}`}
                className="mt-1"
                style={{
                  display: !hasPasted[`${group}_opportunities_for_growth`]
                    ? "block"
                    : "none",
                }}
              >
                <div style={{ padding: "10px" }}>
                  {generatedResponses[`${group}_opportunities_for_growth`]}
                </div>
              </Card>
            )}
          </div>
        </div>

        {indicatorsForSubsection(_.keys(subsection.indicators)).map(
          (indicator) => (
            <SustainabilityRubricIndicator
              key={indicator.index}
              indicator={indicator}
              rubricId={rubric.id}
              disabled={rubric.workflow_state === "submitted"}
            />
          ),
        )}
      </div>
    )
  }
  const renderSection = (section, index) => (
    <div key={index}>
      {section.subsections.map((subsection, i) => renderSubsection(
        subsection,
        subsection.label || section.label,
        i,
        index,
      ))}
    </div>
  )
  const tabContent = (index) => {
    if (rubric && rubric.template) {
      return (
        <div className="tab-content">
          {rubric.template.sections[index]
            && renderSection(rubric.template.sections[index], index)}
        </div>
      )
    }
    return null
  }
  const predefinedOrder = {
    "Program Observability": 1,
    Data: 2,
    "Program Review": 3,
    Communication: 4,
    Leadership: 5,
    Range: 6,
    Coordination: 7,
    "Program Fit/Reinvention": 8,
  }
  const filterLabelsByTitle = (title) => {
    if (title) {
      const titleMappings = {
        innovation: ["Program Fit/Reinvention"],
        integration: ["Range", "Coordination"],
        commitment: [
          "Program Observability",
          "Data",
          "Program Review",
          "Communication",
          "Leadership",
        ],
      }
      const labels = titleMappings[title.toLowerCase()]
      const filteredLabels = rubric.template.sections
        .map((section) => section[Object.keys(section)[0]])
        .filter((label) => labels.includes(label))

      return filteredLabels
    }
    return null
  }

  const sortSectionsByLabel = (sections) => sections.sort((a, b) => {
    const labelA = a.label
    const labelB = b.label
    const orderA = predefinedOrder[labelA] || 0
    const orderB = predefinedOrder[labelB] || 0
    return orderA - orderB
  })

  useEffect(() => {
    sortSectionsByLabel(initialRubric.template.sections)
  }, [initialRubric])

  return (
    <div className="collapsee">
      <Collapse
        style={{ backgroundColor: color, color: "white" }}
        expandIcon={({ isActive }) => (
          <UpOutlined
            style={{
              fontSize: "25px",
              color: "white",
              cursor: "pointer",
            }}
            rotate={isActive ? 0 : 180}
          />
        )}
        expandIconPosition="end"
        collapsible="header"
        bordered={false}
      >
        <Panel
          header={(
            <div className="new-collapsible-div bug-fix">
              <div className="c-ttile-new">
                <div className="new-collapsible-title">
                  <span>{title}</span>
                </div>
              </div>

              <div className="collapsible-content-new">
                <span>{description}</span>
              </div>
            </div>
          )}
          key="1"
        >
          <Col xs={24} sm={24} md={24} lg={24} className="mb-0 mt-0">
            <Space direction="vertical" size={0} style={{ display: "flex" }}>
              {sustainabilityCollapse.map((props, index) => {
                const section = rubric.template.sections[index]
                if (
                  section
                  && filterLabelsByTitle(title).includes(section.label)
                ) {
                  return (
                    <Collapsible
                      title={section.label}
                      id={props.id}
                      generalColor={props.generalColor}
                      tabContent={tabContent(index)}
                      index={index}
                      rubric={rubric}
                      color={props.color}
                      subHeaderColor={subHeaders[parentIndex].generalColor}
                    />
                  )
                }
                return null
              })}
            </Space>
          </Col>
        </Panel>
      </Collapse>
    </div>
  )
}

export default NestedCollapsibleforRubric
