import React, { useState, useEffect } from "react"
import {
  Button, Card, Row, Col, Form, notification,
} from "antd"
import { EditOutlined } from "@ant-design/icons"
import _ from "underscore"
import FormComponent from "./FormComponent"
import commonStrings from "../../commonStrings"
import "../../../assets/stylesheets/partials/system_admins.scss"

const EditUser = ({
  userData,
  adminUser,
  adminResources,
  userResources,
  userPrograms,
  userSchools,
  adminSchools,
  lastSignInAt,
}) => {
  const [submitForm, setSubmitForm] = useState({})
  const [form] = Form.useForm()
  const [userProgramsOptions, setUserProgramsOptions] = useState([])
  const [userSchoolsOptions, setUserSchoolsOptions] = useState([])
  const [adminResourcesValue, setAdminResourcesValue] = useState([])
  const [adminSchoolsValue, setAdminSchoolsValue] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (response) => {
    let status = null
    let reponseMessage = null
    let error = null
    let errorName = null

    if (!_.isEmpty(response.errors)) {
      error = response.errors
      reponseMessage = _.first(Object.values(error))
      errorName = _.first(Object.keys(error))
    } else {
      reponseMessage = response.message
    }
    if (!_.isEmpty(response.status)) {
      status = response.status
    }
    if (!_.isEmpty(response.errors)) {
      api.error({
        message: `Notification: ${status}`,
        description: <span>{`${errorName} ${reponseMessage}`}</span>,
        placement: "topRight",
        closeIcon: false,
        className: "custom-class",
      })
    } else {
      api.success({
        message: `Notification: ${status}`,
        description: <spann>{reponseMessage}</spann>,
        placement: "topRight",
        closeIcon: false,
        className: "custom-class",
      })
      setTimeout(() => {
        window.location.replace("/system_admins")
      }, 2000)
    }
  }
  const handleSubmit = () => {
    APIClient.put(
      `/system_admins_items/${userData.id}`,
      { submitForm },
      (result) => {
        openNotification(result)
      },
      (result) => {
        openNotification(result)
      },
    )
  }

  const optionValueAdmins = (admin) => {
    const opt = []
    if (!_.isEmpty(admin)) {
      admin.map((value) => opt.push({ value: value.id, label: value.name }))
    }

    return opt
  }

  const optionValueSchools = () => {
    const opt = []
    if (!_.isEmpty(userSchools)) {
      userSchools.map((value) => {
        opt.push({
          value: value.id,
          label: value.name,
        })
        return 0
      })
    }
    setUserSchoolsOptions(opt)
  }
  const optionValuePrograms = () => {
    const opt = []
    if (!_.isEmpty(userPrograms)) {
      userPrograms.map((value) => {
        opt.push({
          value: value.id,
          label: value.name,
        })

        return 0
      })
    }
    setUserProgramsOptions(opt)
  }

  useEffect(() => {
    if (adminSchools) {
      setAdminSchoolsValue(optionValueAdmins(adminSchools))
    }
    if (adminResources) {
      setAdminResourcesValue(optionValueAdmins(adminResources))
    }
  }, [adminSchools, adminResources])

  useEffect(() => {
    if (userPrograms) {
      optionValuePrograms()
    }
  }, [userPrograms])

  useEffect(() => {
    if (userSchools) {
      optionValueSchools()
    }
  }, [userSchools])

  return (
    <>
      <Card
        title={
          <span className="form-heading">{commonStrings.viewDetails}</span>
        }
        extra={(
          <div className="displayFlexSpaceAround">
            <Button className="width150FormBtnBack">
              <a href="/system_admins">{commonStrings.back}</a>
            </Button>

            <Button
              className="width150FormButtons  px-2"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    handleSubmit()
                  })
                  .catch(() => {})
              }}
            >
              {commonStrings.save}
              <EditOutlined />
            </Button>
          </div>
        )}
        className="width100per"
      >
        <Row justify="center" className="my-3">
          {contextHolder}
          <Col xs={24} sm={24} md={24} lg={24}>
            <FormComponent
              isUserNewForm={false}
              setSubmitForm={setSubmitForm}
              form={form}
              userData={userData}
              adminUser={adminUser}
              adminResources={adminResourcesValue}
              adminSchools={adminSchoolsValue}
              userResources={userResources}
              userPrograms={userProgramsOptions}
              userSchools={userSchoolsOptions}
              lastSignInAt={lastSignInAt}
            />
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default EditUser
