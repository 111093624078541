import React, { useEffect } from "react"
import RubricHeaderButtons from "./HeaderButtons"

function RubricHeader({ rubric, type, instructions }) {
  useEffect(() => {
    $("[data-toggle=\"popover\"]").popover({ container: "body", trigger: "hover" })
  }, [])

  return (
    <div className="header d-flex justify-content-between">
      <div className="text-ua-dark-blue">
        <h1 className="font-weight-bold mr-2">{type} Rubric #{rubric.number}</h1>
        <i
          data-toggle="popover"
          data-content={instructions}
          className="fa fa-info-circle align-top mr-3"
        />
        <span>{rubric.workflow_state}</span>
      </div>
      <div>
        <RubricHeaderButtons
          rubric={rubric}
          type={type.toLowerCase()}
        />
      </div>
    </div>
  )
}

export default RubricHeader
