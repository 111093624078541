import React from "react"
import { Bar } from "react-chartjs-2"
import _ from "underscore"

function BarChart({
  title,
  labels,
  datasets,
  onClick,
  max = null,
  mb = "0",
  stepSize = null,
  formatPercentage = false,
  legend = false,
  graphUrlBase64 = null,
}) {
  const data = {
    labels,
    datasets,
  }
  const options = {
    onClick: (event, element) => {
      if (element.length && onClick) {
        onClick(element[0].index)
      }
    },
    onHover: (event, chartElement) => {
      if (onClick) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default"
      }
    },
    plugins: {
      legend: {
        display: legend,
      },
      tooltip: {
        enabled: _.isNull(graphUrlBase64) ? true : graphUrlBase64.length > 0,
        callbacks: {
          label: (tooltipItem) => ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}${
            formatPercentage ? "%" : ""
          }`,
        },
      },
    },
    scales: {
      y: {
        max,
        beginAtZero: true,
        ticks: {
          stepSize,
        },
      },
    },
  }
  if (title) {
    options.plugins.title = {
      display: true,
      text: title,
    }
  }
  return (
    <div className={`mb-${mb}`}>
      <Bar data={data} options={options} />
    </div>
  )
}
export default BarChart
