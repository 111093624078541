import React, { useState } from "react"
import _ from "underscore"
import { useDebounceEffect } from "ahooks"
import EvidenceListItem from "./EvidenceListItem"
import { areYouSure, errorAlert } from "../../utilities"

function SustainabilityRubricIndicator({
  rubricId,
  indicator: initialIndicator,
  disabled,
}) {
  const [indicator, setIndicator] = useState(initialIndicator)
  const [isFileLoading, setIsFileLoading] = useState(true)
  const getColorForIndex = (index) => {
    if (index >= 1 && index <= 24) {
      return "#A6192E"
    }
    if (index === 25) {
      return "#E7A922"
    }
    if (index === 26) {
      return "rgb(231, 169, 34)"
    }
    if (index === 27) {
      return "rgb(12, 164, 165)"
    }
    return "#000000"
  }
  const color = getColorForIndex(indicator.index)
  const saveIndicator = () => {
    showSaving()
    APIClient.put(
      `/sustainability_rubrics/${rubricId}/sustainability_rubric_indicators/${indicator.id}`,
      {
        rating: indicator.rating,
        explanation: indicator.explanation,
      },
      () => {
        hideSaving()
      },
    )
  }

  useDebounceEffect(
    () => {
      if (indicator !== initialIndicator) {
        saveIndicator()
      }
    },
    [indicator],
    { wait: 250 },
  )

  const uploadEvidence = (files) => {
    _.each(files, (file) => {
      APIClient.upload(
        `/sustainability_rubrics/${rubricId}/sustainability_rubric_indicators/${indicator.id}/evidence`,
        file,
        {},
        (response) => {
          setIsFileLoading(true)
          setIndicator(response)
        },
        () => {
          setIsFileLoading(true)
          errorAlert({
            title: "Invalid file type",
            html: "Please upload one of the following: PDF, JPG, PNG, or GIF",
          })
        },
      )
    })
  }

  const deleteEvidence = (id) => {
    areYouSure("warning", () => {
      APIClient.delete(
        `/sustainability_rubrics/${rubricId}/sustainability_rubric_indicators/${indicator.id}/evidence/${id}`,
        (response) => {
          setIndicator(response)
        },
      )
    })
  }

  const optionClicked = (rating) => {
    setIndicator({
      ...indicator,
      rating: indicator.rating === rating ? null : rating,
    })
  }

  const explanationChanged = (e) => {
    setIndicator({ ...indicator, explanation: e.target.value })
  }

  const onUploadEvidence = (e) => {
    setIsFileLoading(false)
    uploadEvidence(e.target.files)
    $(e.target).val("")
  }

  const renderExplanation = () => {
    if (_.isNumber(indicator.rating) || indicator.explanation) {
      return (
        <div className="form-group">
          <textarea
            style={{ height: "148px" }}
            className="form-control auto-text-area"
            placeholder="Please provide justification for the rating…"
            value={indicator.explanation || ""}
            onChange={explanationChanged}
            disabled={disabled}
          />
        </div>
      )
    }
    return null
  }

  const renderFileInput = () => {
    if (_.isNumber(indicator.rating)) {
      return isFileLoading ? (
        <div className="form-group no-tooltip">
          <input
            className="form-control-file mb-1"
            type="file"
            multiple
            disabled={disabled}
            onChange={onUploadEvidence}
            title=""
          />
          <em className="small new-font-style">
            * Accepted file types: PDF, JPG, PNG, and GIF
          </em>
        </div>
      ) : (
        <div className="d-flex align-items-center mb-3">
          <div style={{ color }} className="spinner-border mr-2 size-loader" />
          <div>
            <span style={{ color }} className="uploading-text">
              Uploading...
            </span>
          </div>
        </div>
      )
    }
    return null
  }

  const renderEvidence = (color) => {
    if (_.isNumber(indicator.rating) || indicator.evidence) {
      return (
        <span className="evidence">
          {indicator.evidence.map((evidence) => (
            <EvidenceListItem
              key={evidence.id}
              rubricId={rubricId}
              indicatorId={indicator.id}
              evidence={evidence}
              disabled={disabled}
              deleteEvidence={(id) => deleteEvidence(id)}
              color={color}
            />
          ))}
        </span>
      )
    }
    return null
  }

  const rating = (index) => ["INITIATING", "Developing", "Proficient", "Well Developed"][index]

  return (
    <>
      <div className="marginTop35 mb-4" />
      <div className="indicator">
        <div className="row mb-4">
          <div className="col-12 pl-4">
            <h5 style={{ color }} className="font-weight-bold mb-3 fontSize27">
              {indicator.index}. {window.gon.CORE_LANGUAGE[indicator.index - 1]}
              :
            </h5>
            <div className="d-flex">
              {indicator.options.map((option, index) => (
                <div key={index} className="form-check mb-3 form-flex">
                  <label className="form-check-label marginTop20">
                    <input
                      type="checkbox"
                      className="form-check-input marginTop7"
                      onChange={() => optionClicked(index)}
                      disabled={disabled}
                      checked={indicator.rating === index}
                    />

                    <p
                      style={{ color }}
                      className="text-uppercase mb-1 fontSize18 fontWeigth600 "
                    >
                      {rating(index)}
                    </p>
                    <div className="text-align-justify text-div200">
                      <span>{option}</span>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pl-4">
          {renderExplanation()}
          {renderFileInput()}
          {renderEvidence()}
        </div>
      </div>
    </>
  )
}

export default SustainabilityRubricIndicator
