import React from "react"

function ResourceSearch({ setQuery }) {
  return (
    <div className="mb-4">
      <input
        type="search"
        className="form-control"
        placeholder="Search…"
        onChange={(e) => setQuery(e.target.value)}
      />
    </div>
  )
}

export default ResourceSearch
