import React, { useState, useEffect } from "react"
import {
  Row, Col, Form, Card,
} from "antd"
import moment from "moment"
import SystemAdminInputCell from "./SystemAdminInputCell"
import SystemAdmSelectCell from "./SystemAdminSelectCell"
import SystemAdminMultiSelect from "./SystemAdminMultiSelect"
import commonStrings from "../../commonStrings"
import componentStyles from "../../styles"
import rules from "../../validations"

const FormComponent = ({
  userData,
  adminUser,
  adminResources,
  userSchools,
  userPrograms,
  adminSchools,
  form,
  isUserNewForm,
  setSubmitForm,
  lastSignInAt,
}) => {
  const [userType, setUserType] = useState(
    userData
      ? userData.role
      : adminUser.role === "program admin"
        ? "program user"
        : "school user",
  )
  const [inputUserValues, setInputUserValues] = useState({})

  const handleChange = ({ target: { name, value } }) => {
    setInputUserValues({
      ...inputUserValues,
      [name]: value,
    })
  }

  const handleChangeSelect = (name, value) => {
    if (name === "role") {
      setUserType(value)
    }
    setInputUserValues({
      ...inputUserValues,
      [name]: value,
    })
  }
  useEffect(() => {
    setSubmitForm(inputUserValues)
  }, [inputUserValues])

  useEffect(() => {
    setInputUserValues({
      ...inputUserValues,
      first_name: userData ? userData.first_name : "",
      last_name: userData ? userData.last_name : "",
      email: userData ? userData.email : "",
      [userType === "program user" ? "program" : "schools"]: userData
        ? userData.programs
        : [],
      role: userType,
      certified: userData ? userData.certified : false,
      active: userData ? userData.active : true,
    })
  }, [userData])

  return (
    <Row justify="center">
      <Col xs={24} sm={24} md={14} lg={14}>
        <Card style={componentStyles.cardStyle}>
          <Form layout="vertical" form={form}>
            <Row justify="space-between">
              <Col xs={24} sm={24} md={24} lg={11}>
                <SystemAdminInputCell
                  colDimensions={{ md: 8, lg: 8 }}
                  name="first_name"
                  label={commonStrings.firstName}
                  defaultValues={userData && userData.first_name}
                  type="text"
                  rules={rules.inputRequired}
                  handleChange={(value) => {
                    handleChange(value)
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={11}>
                <SystemAdminInputCell
                  colDimensions={{ md: 8, lg: 8 }}
                  name="last_name"
                  label={commonStrings.lastName}
                  defaultValues={userData && userData.last_name}
                  type="text"
                  rules={rules.inputRequired}
                  handleChange={(value) => {
                    handleChange(value)
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <SystemAdminInputCell
                  colDimensions={{ md: 8, lg: 8 }}
                  name="email"
                  label={commonStrings.email}
                  defaultValues={userData && userData.email}
                  type="text"
                  rules={rules.checkValidEmail}
                  handleChange={(value) => {
                    handleChange(value)
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <SystemAdmSelectCell
                  colDimensions={{ md: 24, lg: 24 }}
                  name="role"
                  label={commonStrings.role}
                  defaultValues={userType}
                  values={adminUser.role === "school admin" ? commonStrings.SchoolAdminRoles : commonStrings.ProgramAdminRoles}
                  type="text"
                  isDetail
                  handleChange={(value) => handleChangeSelect("role", value)}
                // disabled={}
                />
              </Col>
              {userType === "program user" || userType === "program admin" ? (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <SystemAdminMultiSelect
                    colDimensions={{ md: 24, lg: 24 }}
                    name="programs"
                    label={commonStrings.programs}
                    key={`programs:${userPrograms}`}
                    defaultValues={userPrograms || []}
                    options={adminResources}
                    values={userPrograms || []}
                    type="text"
                    isDetail
                    showArrow
                    mode="multiple"
                    handleChange={(value) => handleChangeSelect("programs", value)}
                  />
                </Col>
              ) : (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <SystemAdminMultiSelect
                    colDimensions={{ md: 24, lg: 24 }}
                    name="schools"
                    label={commonStrings.schools}
                    key={`schools:${userSchools}`}
                    defaultValues={userSchools || []}
                    options={adminSchools}
                    values={userSchools || []}
                    type="text"
                    isDetail
                    showArrow
                    mode="multiple"
                    handleChange={(value) => handleChangeSelect("schools", value)}
                  />
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={11}>
                <SystemAdmSelectCell
                  colDimensions={{ md: 24, lg: 24 }}
                  name="certified"
                  label={commonStrings.certified}
                  defaultValues={userData ? userData.certified : false}
                  type="text"
                  values={commonStrings.statusTypes}
                  handleChange={(value) => handleChangeSelect("certified", value)}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={11}>
                <SystemAdmSelectCell
                  colDimensions={{ md: 24, lg: 24 }}
                  name="active"
                  label={commonStrings.active}
                  defaultValues={userData ? userData.active : true}
                  type="text"
                  values={commonStrings.statusTypes}
                  handleChange={(value) => handleChangeSelect("active", value)}
                />
              </Col>
              {!isUserNewForm && (
                <>
                  <Col xs={24} sm={24} md={24} lg={7}>
                    <SystemAdminInputCell
                      colDimensions={{ md: 8, lg: 8 }}
                      name="last_signed_in"
                      label={commonStrings.lastSignedIn}
                      type="text"
                      disabled
                      defaultValues={lastSignInAt && moment(lastSignInAt).format("MM/DD/YYYY")}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={7}>
                    <SystemAdminInputCell
                      colDimensions={{ md: 8, lg: 8 }}
                      name="last_updated"
                      label={commonStrings.lastUpdated}
                      defaultValues={
                        userData
                        && moment(userData.updated_at).format("MM/DD/YYYY")
                      }
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={7}>
                    <SystemAdminInputCell
                      colDimensions={{ md: 8, lg: 8 }}
                      name="created_at"
                      label={commonStrings.createdOn}
                      defaultValues={
                        userData
                        && moment(userData.created_at).format("MM/DD/YYYY")
                      }
                      type="text"
                      disabled
                    />
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default FormComponent
