import React from "react"
import { Form, InputNumber } from "antd"
import _ from "underscore"
import componentStyles from "../../../styles"

function InputCellTypeNumber(props) {
  const handleParser = (value) => {
    const parsedValue = Math.abs(parseFloat(value))
    return _.isNaN(parsedValue) ? null : parsedValue
  }
  const handleFormatter = (value) => (Math.abs(value) === 0 ? null : Math.abs(value))

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name={props.name}
      label={props.label}
      rules={props.rules}
      key={props.keys}
      min={props.min}
    >
      <InputNumber
        formatter={handleFormatter}
        parser={handleParser}
        type="number"
        defaultValue={props.defaultValue}
        style={componentStyles.inputStyleProgramMatrix}
        name={props.name}
        disabled={props.disabled}
        maxLength={props.maxLength}
        prefix={props.prefix}
        min={props.min || "0"}
        onBlur={(value) => props.handleBlur(value)}
        placeholder={props.placeholder}
        controls={false}
      />
    </Form.Item>
  )
}

export default InputCellTypeNumber
