import React from "react"
// import { Col } from "antd"
import commonStrings from "../../commonStrings"

const renderArea = (area, reportName) => {
  const headingColor = () => {
    const lowerReportName = reportName.toLowerCase()

    if (lowerReportName === "range" || lowerReportName === "coordination") {
      return "#E7A922"
    }
    if (lowerReportName === "program fit/reinvention") {
      return "#0CA4A5"
    }
    return null
  }
  const style = {
    display: "contents",
  }
  return (
    <div className="main-evidence" style={style}>
      <div className="evidence-inner-container">
        {area.explanations.length > 0 && (
          <div className="evidence" style={{ color: headingColor() }}>
            <span>{commonStrings.evidence}</span>
          </div>
        )}
        <div className="evidence-content">
          <ul>
            {area.explanations.map((explanation, index) => (explanation.__html ? (
              <li key={index} dangerouslySetInnerHTML={explanation} />
            ) : null))}
          </ul>
        </div>
      </div>
    </div>
  )
}

function Evidence({ area, reportName }) {
  return (
    <div className="main-evidence-container">
      {renderArea(area, reportName)}
    </div>
  )
}
export default Evidence
