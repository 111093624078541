import React from "react"
import _ from "underscore"
import Fuse from "fuse.js"
import ResourceFilterer from "./Filterer"
import ResourceSearch from "./Search"
import ResourceGroup from "./Group"
import commonStrings from "../../commonStrings"

export default class Resources extends React.Component {
  constructor(props) {
    super(props)
    const fuse = new Fuse(props.resources, {
      threshold: 1,
      keys: ["name", "description"],
    })
    this.state = {
      resources: props.resources,
      fuse,
      filters: [],
      query: "",
    }
  }

  setFilters(filters) {
    this.setState({ filters })
  }

  setQuery(query) {
    this.setState({ query })
  }

  filteredResources() {
    const {
      resources, filters, query, fuse,
    } = this.state
    let scopedResources = resources

    if (query.length) {
      const results = fuse.search(query)

      scopedResources = results.map((result) => result.item)
    }

    return scopedResources.filter(
      (resource) => filters.length === 0 || filters.includes(resource.category),
    )
  }

  groupedResources() {
    const { query } = this.state
    const normalizedQuery = query.toLowerCase().trim()

    let filteredResources = this.filteredResources()
    if (normalizedQuery) {
      filteredResources = filteredResources.filter(
        (resource) => resource.name.toLowerCase().includes(normalizedQuery)
          || resource.description.toLowerCase().includes(normalizedQuery),
      )
    }

    return _.groupBy(filteredResources, "category")
  }

  render() {
    const groupedResources = this.groupedResources()
    const anyResourcesAvailable = Object.keys(groupedResources).some(
      (key) => groupedResources[key].length > 0,
    )
    return (
      <div>
        <h1>Resources</h1>
        <div className="resources">
          <ResourceSearch setQuery={this.setQuery.bind(this)} />

          <ResourceFilterer
            resources={this.state.resources}
            filters={this.state.filters}
            setFilters={this.setFilters.bind(this)}
            groupedResources={this.groupedResources()}
          />

          {anyResourcesAvailable ? (
            _.map(groupedResources, (resources, index) => (
              <ResourceGroup key={index} resources={resources} />
            ))
          ) : (
            <div className="noData">
              <span>{commonStrings.nodataAvailable}</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}
