import React from "react"
import { TreeSelect } from "antd"

const GenericTreeSelect = ({
  treeData,
  onChange,
  placeholder,
  maxTagCount,
  name,
}) => (
  <div className="tree-select-filters">
    <TreeSelect
      name={name}
      key={name}
      allowClear
      treeCheckable
      popupMatchSelectWidth={false}
      virtual={false}
      onChange={onChange}
      treeData={treeData}
      placeholder={placeholder}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={(remainingValues) => (
        <div>
          <div>
            {remainingValues.length === 1
              ? "1 selected"
              : `${remainingValues.length} selected`}
          </div>
        </div>
      )}
      style={{ width: "100%" }}
      showCheckedStrategy={TreeSelect.SHOW_ALL}
      size="medium"
    />
  </div>
)

export default GenericTreeSelect
