import React, { useState, useEffect } from "react"
import {
  Divider,
  Table,
  Button,
  Card,
  Col,
  Row,
  Select,
  Tooltip,
  Modal,
  Form,
  Input,
  notification,
  Switch,
} from "antd"
import {
  MailOutlined,
  EditOutlined,
  SearchOutlined,
  UserDeleteOutlined,
  UserAddOutlined,
} from "@ant-design/icons"
import _ from "underscore"
import commonStrings from "../../commonStrings"
import componentStyles from "../../styles"
import {
  TruncateText,
  defaultSort,
  areYouSure,
  errorAlert,
  filterBySchoolId,
} from "../../utilities"
import rules from "../../validations"
import "../../../assets/stylesheets/partials/system_admins.scss"
import Attachment from "./Attachment"

const Index = ({
  adminResources,
  adminSchools,
  adminUser,
  currentUserPrograms,
}) => {
  const { TextArea } = Input
  const { Option } = Select
  const [form] = Form.useForm()
  const [formModal] = Form.useForm()
  const [formAssign] = Form.useForm()
  const [attachmentForm] = Form.useForm()

  const [uniqueFirstName, setUniqueFirstName] = useState([])
  const [uniqueLastName, setUniqueLastName] = useState([])
  const [responseMessage, setResponseMessage] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [valuesForFilters, setValuesForFilters] = useState([])
  const [parentTableData, setParentTableData] = useState([])
  const [userListValue, setUserListValue] = useState([])
  const [allUserEmailList, setAllUsersEmailList] = useState([])
  const [emails, setEmails] = useState({})
  const [userIdToDelete, setUserIdToDelete] = useState({})
  const [inputUserEmailValues, setInputUserEmailValues] = useState()
  const [linkObject, setLinkObject] = useState({})
  const [linkPreview, setLinkPreview] = useState([])

  const [buttonBatchAction, setButtonBatchAction] = useState(true)
  const [handleSearch, setHandleSearch] = useState(false)
  const [hideFilters, setHideFilters] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [checkChange, setCheckChange] = useState(false)
  const [isEmailSendToAllUsers, setIsEmailSendToAllUsers] = useState(false)
  const [isBatchActionDisabled, setIsBatchActionDisabled] = useState(false)
  const [userIdPasswordReset, setUserIdPasswordReset] = useState([])
  const [schoolFilterValues, setSchoolFilterValues] = useState([])

  const [filtersData, setFiltersData] = useState([])
  const [isToggled, setIsToggled] = useState(true)

  const [flag, setFlag] = useState(false)
  const [keyTable] = useState(false)
  const [sendEmailDisabled, setEmailDisabled] = useState(false)

  const [api, contextHolder] = notification.useNotification()
  const [mdCol] = useState(3)
  const [search, setSearch] = useState({
    first_name: "",
    last_name: "",
    active: "",
    school: "",
    program: "",
    role: "",
  })
  const [searchNew, setSearchNew] = useState({
    school: 0,
    program: 0,
    role: "",
  })

  const [isAssignRemoveAction, setIsAssignRemoveAction] = useState(false)
  const [idAssignRemovedProgram, setIdAssignRemovedProgram] = useState([])
  const [isProgram, setIsProgram] = useState(true)
  const [assignedRemovedValues, setAssignedRemovedValues] = useState({
    users: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const [loadingFilterData, setLoadingFilterData] = useState(false)
  const [userRoles, setUserRoles] = useState([])
  const [okButtonText, setOkButtonText] = useState("")
  const [modalTitle, setModalTitle] = useState("")
  const [isUserSelected, setIsUserSelected] = useState(false)
  const isBulkEmailDisabled = !isUserSelected || buttonBatchAction
  const sortByStatus = (value) => {
    const sortedData = [...value].sort((a, b) => {
      if (a.active < b.active) {
        return 1
      }
      if (a.active > b.active) {
        return -1
      }
      return 0
    })
    return sortedData
  }

  const clearFilters = () => {
    setSearch({
      first_name: "",
      last_name: "",
      active: "",
      school: "",
      program: "",
      role: "",
    })
    form.resetFields()
  }

  const resetTableSelection = () => {
    setSelectedRowKeys([])
    setEmails([])
    setButtonBatchAction(true)
  }
  const updateSelectedState = (selectedRows) => {
    setIsUserSelected(selectedRows.length > 0)
  }
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    const emailArray = []
    const userIds = []
    const roleType = []
    selectedRows.map((value) => {
      emailArray.push(value.email)
      userIds.push(value.id)
      roleType.push(value.role)
      return 0
    })
    setEmails(emailArray)
    setUserIdToDelete(userIds)
    setUserIdPasswordReset(userIds)
    setIdAssignRemovedProgram(userIds)
    setUserRoles(roleType)

    if (selectedRows.length) {
      setButtonBatchAction(false)
    } else {
      setButtonBatchAction(true)
    }
    updateSelectedState(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const openNotification = (response) => {
    setResponseMessage(response)
    let status = null
    let responseMessage = null

    if (response.error) {
      error = error.response
    } else {
      responseMessage = response.message
    }
    if (response.status) {
      status = response.status
    }
    api.success({
      message: `Notification: ${status || "Success"}`,
      description: <span>{responseMessage}</span>,
      placement: "topRight",
      closeIcon: false,
      className: "custom-class",
    })
  }

  const openNotificationForEmail = (response) => {
    let status = null
    let responseMessage = null

    if (response.error) {
      error = error.response
    } else {
      responseMessage = response.message
    }
    if (response.status) {
      status = response.status
    }

    if (response.errors) {
      api.error({
        message: `Notification: ${status.replaceAll("_", " ")}`,
        description: <span>{responseMessage}</span>,
        placement: "topRight",
        closeIcon: false,
        className: "custom-class",
      })
    } else {
      api.success({
        message: `Notification: ${status}`,
        description: <span>{responseMessage}</span>,
        placement: "topRight",
        closeIcon: false,
        className: "custom-class",
      })
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const sendSingleEmail = (userRecord) => {
    const emailArray = []
    emailArray.push(userRecord.email)
    setEmails(emailArray)
    setOkButtonText(commonStrings.sendButtonText)
  }

  const resetModalForms = () => {
    form.resetFields()
    formAssign.resetFields()
    formModal.resetFields()
    attachmentForm.resetFields()
  }

  const satesSetToDefault = () => {
    setIsModalOpen(false)
    setIsAssignRemoveAction(false)
    setIsEmailSendToAllUsers(false)
  }
  const initialApiCallForList = () => {
    APIClient.get("/system_admins_items/", (response) => {
      let i = 1
      const finalArray = response
      finalArray.forEach((object) => {
        object.key = i
        i += 1
      })
      finalArray.map((value, index) => {
        finalArray[index].active = value.active.toString()
        return 0
      })

      setAllUsersEmailList(finalArray)
      setUserListValue(sortByStatus(finalArray))
      setValuesForFilters(sortByStatus(response))
      setParentTableData(sortByStatus(finalArray))
    })
  }

  const handleCancel = () => {
    setSearch({
      first_name: "",
      last_name: "",
      active: "",
      school: "",
      program: "",
      role: "",
    })
    setSearchNew({ school: 0, program: 0, role: "" })
    resetModalForms()
    resetTableSelection()
    satesSetToDefault()
    setLinkPreview([])
    setLinkObject({})
    initialApiCallForList()
    setFiltersData([])
  }

  const handleChangeEmail = ({ target: { name, value } }) => {
    setInputUserEmailValues({
      ...inputUserEmailValues,
      [name]: value,
    })
  }

  const CreateLinkObject = ({ target: { name, value } }) => {
    if (value.trim() !== "") {
      setLinkObject({
        ...linkObject,
        [name]: value,
      })
    } else {
      // Remove the property from the link object
      const updatedLinkObject = { ...linkObject }
      delete updatedLinkObject[name]
      setLinkObject(updatedLinkObject)
    }
  }
  const getThirdAssociationUsersData = () => {
    const { program, role, school } = searchNew

    if (program) {
      setLoadingFilterData(true)
      APIClient.get(
        `/system_admins_items/filters_data/?program_id=${program}`,
        (response) => {
          let i = 1
          let filtersResults = response.users

          filtersResults.forEach((object) => {
            object.key = i
            i += 1
          })

          if (role) {
            filtersResults = filtersResults.filter(
              (record) => record.role === role,
            )
          }

          if (school) {
            filtersResults = filterBySchoolId(school, filtersResults)
          }

          setFiltersData(filtersResults)
          setLoadingFilterData(false)
        },
      )
    }
  }

  const getSchoolsOnProgram = (program) => {
    if (program) {
      APIClient.get(
        `/system_admins_items/program_schools/?program_id=${program}`,
        (response) => {
          setSchoolFilterValues(response)
        },
      )
    }
  }

  const activateDeactivateSingleUser = (id, type) => {
    areYouSure("warning", () => {
      APIClient.put(
        `/system_admins_items/${id}/${type}`,
        { id },
        (response) => {
          openNotification(response)
          getThirdAssociationUsersData()
        },
      )
    })
  }

  const activateDeactivateMultipleUsers = (type) => {
    areYouSure("warning", () => {
      const object = { id: userIdToDelete }
      APIClient.put(
        `/system_admins_items/${adminUser.id}/${type}`,
        {
          object,
        },
        (success) => {
          getThirdAssociationUsersData()
          openNotification(success)
          resetTableSelection()
        },
        (error) => {
          console.log(error)
        },
      )
    })
  }

  const handleChange = (type, value) => {
    if (typeof value === "undefined" || !value) {
      setSearch({
        ...search,
        [type]: 0,
      })
      setFlag(true)
      setCheckChange(false)
    } else {
      setSearch({
        ...search,
        [type]: value,
      })
    }
  }

  const resetFormFields = (fields) => {
    fields.forEach((field) => form.resetFields([field]))
  }

  const updateSearchNew = (updatedFields) => {
    setSearchNew({
      ...searchNew,
      ...updatedFields,
    })
  }

  const handleChangeNew = (type, value) => {
    if (value === undefined) {
      switch (type) {
        case "program":
          updateSearchNew({ school: 0, role: "", program: 0 })
          form.resetFields()
          setFiltersData([])
          break
        case "role":
          updateSearchNew({ role: "" })
          resetFormFields(["role_new"])
          break
        case "school":
          updateSearchNew({ school: 0 })
          resetFormFields(["school_new"])
          break
        default:
          break
      }
    } else if (type === "program") {
      resetFormFields(["school_new", "role_new"])
      updateSearchNew({ role: "", school: 0, [type]: value })
      getSchoolsOnProgram(value)
    } else {
      updateSearchNew({ [type]: value })
    }
  }

  const handleAssignRemoveValue = (type, value) => {
    if (value) {
      setAssignedRemovedValues({
        ...assignedRemovedValues,
        users: idAssignRemovedProgram,
        [type]: value,
      })
    }
  }

  const keyHash = [
    {
      value: search.first_name,
      change: checkChange,
    },
    {
      value: search.last_name,
      change: checkChange,
    },
    {
      value: search.active,
      change: checkChange,
    },
    {
      value: search.program,
      change: checkChange,
    },
    {
      value: search.school,
      change: checkChange,
    },
    {
      value: search.role,
      change: checkChange,
    },
  ]

  const filterHash = [
    {
      value: search.first_name,
      name: "first_name",
    },
    {
      value: search.last_name,
      name: "last_name",
    },
    {
      value: search.active,
      name: "active",
    },
    {
      value: search.program,
      name: "program",
    },
    {
      value: search.school,
      name: "school",
    },
    {
      value: search.role,
      name: "role",
    },
  ]

  const ApiCallForBulkEmails = (val) => {
    const apiObject = {
      email: val.email,
      subject: val.subject,
      message: val.message,
      attachments: linkPreview,
    }

    setEmailDisabled(true)
    APIClient.post(
      `/system_admins_items/${adminUser.id}/send_email`,
      { inputUserEmailValues: apiObject },
      (response) => {
        openNotificationForEmail(response)
        resetTableSelection()
        formModal.resetFields()

        setTimeout(() => {
          setIsLoading(false)
          setIsModalOpen(false)
          setEmailDisabled(false)
          setLinkPreview([])
          setLinkObject({})
        }, 1000)
      },
      (error) => {
        console.log(error)
      },
    )
  }
  const onSubmitModal = () => {
    formModal
      .validateFields()
      .then(() => {
        setIsLoading(true)
        ApiCallForBulkEmails(inputUserEmailValues)
        attachmentForm.resetFields()
      })
      .catch(() => {})
    setIsEmailSendToAllUsers(false)
  }
  const isIdenticalRoles = (arr) => {
    const firstWord = arr[0].split(" ")[0]
    for (let i = 1; i < arr.length; i += 1) {
      const word = arr[i].split(" ")[0]
      if (word !== firstWord) {
        return false
      }
    }
    return firstWord
  }

  const apiCallForAssignRemoveAction = () => {
    const endpoint = okButtonText === "Add"
      ? `/system_admins_items/${adminUser.id}/update_user_resources`
      : `/system_admins_items/${adminUser.id}/remove_user_resources`

    APIClient.put(
      endpoint,
      assignedRemovedValues,
      (response) => {
        setIsLoading(true)
        openNotification(response)
        setTimeout(() => {
          formAssign.resetFields()
          setIsModalOpen(false)
          setIsLoading(false)
          resetTableSelection()
          setIsAssignRemoveAction(false)
          setAssignedRemovedValues({})
        }, 1000)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  const onSubmitAssignRemoveAction = () => {
    formAssign
      .validateFields()
      .then(() => {
        setIsLoading(true)
        apiCallForAssignRemoveAction()
      })
      .catch(() => {})
  }

  const filterLogicFunction = () => {
    const value = keyHash.some((e) => e.value)
    if (handleSearch || value) {
      let filter = []
      filter = parentTableData
      setCheckChange(true)
      filterHash.map((e) => {
        if (e.value) {
          if (e.name === "first_name") {
            filter = filter.filter((x) => x.first_name === e.value)
          }
          if (e.name === "last_name") {
            filter = filter.filter((x) => x.last_name === e.value)
          }
          if (e.name === "active") {
            filter = filter.filter((x) => x.active === e.value)
          }
          if (e.name === "program") {
            const final = []
            filter = filter.filter((x) => x.programs.length > 0)
            filter.map((x) => x.programs.filter((v) => {
              if (v.name === e.value) {
                final.push(x)
              }
              return 0
            }))
            filter = final
            return 0
          }

          if (e.name === "school") {
            const final = []
            filter = filter.filter((x) => x.schools.length > 0)
            filter.map((x) => x.schools.filter((v) => {
              if (v.name === e.value) {
                final.push(x)
              }
              return 0
            }))
            filter = final
            return 0
          }

          if (e.name === "role") {
            filter = filter.filter((x) => x.role === e.value)
          }
        }

        return 0
      })
      setUserListValue(filter)
    }
  }

  const emailSetFunction = () => {
    if (!_.isEmpty(emails)) {
      setInputUserEmailValues({
        ...inputUserEmailValues,
        email: emails,
      })
    }
  }

  const resendInvitation = (id) => {
    areYouSure("warning", () => {
      APIClient.post(
        `/system_admins_items/${id}/resend_invitation`,
        { user_id: userIdPasswordReset },
        (response) => {
          openNotification(response)
          setSelectedRowKeys([])
        },
        (error) => {
          console.log(error)
        },
      )
    })
  }

  const firstLastNameSetFunction = () => {
    const firstName = []
    const lastName = []
    valuesForFilters.map((element) => {
      firstName.push(element.first_name)
      return 0
    })
    valuesForFilters.map((element) => {
      lastName.push(element.last_name)
      return 0
    })
    const uniqFirstName = [...new Set(firstName)]
    const uniqLastName = [...new Set(lastName)]
    setUniqueFirstName(uniqFirstName)
    setUniqueLastName(uniqLastName)
  }

  const getAllEmailsFromListingObject = () => {
    const finalArray = []
    allUserEmailList.map((item) => {
      finalArray.push(item.email)

      return 0
    })
    setInputUserEmailValues({
      ...inputUserEmailValues,
      email: finalArray,
    })
  }

  useEffect(() => {
    if (!adminUser) {
      window.location.href = "/users/sign_in"
    }
  }, [adminUser])

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      render: (text) => <a>{text}</a>,
      width: 90,
      sorter: {
        compare: (a, b) => defaultSort(a.first_name, b.first_name),
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      width: 90,
      sorter: {
        compare: (a, b) => defaultSort(a.last_name, b.last_name),
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 140,
      render: (value) => (
        <Tooltip placement="top" title={value}>
          <span>{TruncateText(value, 25)}</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => defaultSort(a.email, b.email),
      },
    },

    {
      title: "Role",
      dataIndex: "role",
      width: 110,
      render: (value) => (
        <Tooltip placement="top" title={value}>
          <span>{TruncateText(value, 25)}</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => defaultSort(a.role, b.role),
      },
    },

    adminUser && adminUser.role === "program admin"
      ? {
        title: "Programs",
        dataIndex: "programs",
        width: 125,
        render: (value) => value.map((ele, index) => (
          <Tooltip placement="top" title={ele.name}>
            <span key={index}>{TruncateText(ele.name, 25)}</span>
            <br />
          </Tooltip>
        )),
        sorter: {
          compare: (a, b) => defaultSort(a.programs, b.programs),
        },
      }
      : { width: 0 },

    {
      title: "Schools",
      dataIndex: "schools",
      width: 125,
      render: (value) => value.map((ele, index) => (
        <Tooltip key={index} placement="top" title={ele.name}>
          <span key={index}>{TruncateText(ele.name, 20)}</span>
          <br />
        </Tooltip>
      )),
      sorter: {
        compare: (a, b) => defaultSort(a.schools, b.schools),
      },
    },

    {
      title: "Certified",
      dataIndex: "certified",
      width: 70,
      align: "center",
      render: (value) => (
        <span>{value.toString().toLowerCase() === "true" ? "Yes" : "No"}</span>
      ),
      sorter: {
        compare: (a, b) => defaultSort(a.certified, b.certified),
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      width: 60,
      align: "center",
      render: (value) => (
        <span>{value.toString().toLowerCase() === "true" ? "Yes" : "No"}</span>
      ),
      sorter: {
        compare: (a, b) => defaultSort(a.active, b.active),
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      render: (_, record) => (
        <>
          <span className="paddingLeftRight">
            <Tooltip placement="top" title={commonStrings.edit}>
              <a href={`/system_admins/${record.id}`}>
                <EditOutlined
                  className="tableIconsSize"
                  style={componentStyles.editIcon}
                />
              </a>
            </Tooltip>
          </span>
          <span className="paddingLeftRight">
            <Tooltip placement="top" title={commonStrings.email}>
              <MailOutlined
                onClick={() => {
                  sendSingleEmail(record)
                  showModal()
                }}
                className="tableIconsSize m5"
                style={componentStyles.mailIcon}
              />
            </Tooltip>
          </span>
          <span className="paddingLeftRight">
            {record.active === "true" ? (
              <Tooltip placement="top" title={commonStrings.inActive}>
                <UserDeleteOutlined
                  onClick={() => {
                    activateDeactivateSingleUser(record.id, "deactivate")
                  }}
                  className="tableIconsSize"
                  style={componentStyles.deleteIcon}
                />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={commonStrings.active}>
                <UserAddOutlined
                  onClick={() => {
                    activateDeactivateSingleUser(record.id, "activate")
                  }}
                  className="tableIconsSize"
                  style={componentStyles.activeIcon}
                />
              </Tooltip>
            )}
          </span>
        </>
      ),
      width: 75,
    },
  ]

  const columnsNew = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "firstName",
      sorter: {
        compare: (a, b) => defaultSort(a.first_name, b.first_name),
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "lastName",
      sorter: {
        compare: (a, b) => defaultSort(a.last_name, b.last_name),
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: {
        compare: (a, b) => defaultSort(a.email, b.email),
      },
    },

    {
      title: "Program",
      dataIndex: "programs",
      key: "programName",
      render: (value) => value.map((ele, index) => (
        <Tooltip key={index} placement="top" title={ele.name}>
          <span key={index}>{TruncateText(ele.name, 20)}</span>
          <br />
        </Tooltip>
      )),
      sorter: {
        compare: (a, b) => defaultSort(a.programs, b.programs),
      },
    },
    {
      title: "School",
      dataIndex: "schools",
      key: "schools",
      render: (value) => value.map((ele, index) => (
        <Tooltip key={index} placement="top" title={ele.name}>
          <span key={index}>{TruncateText(ele.name, 20)}</span>
          <br />
        </Tooltip>
      )),
      sorter: {
        compare: (a, b) => defaultSort(a.schools, b.schools),
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: {
        compare: (a, b) => defaultSort(a.role, b.role),
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      render: (_, record) => (
        <>
          <span className="paddingLeftRight">
            <Tooltip placement="top" title={commonStrings.edit}>
              <a href={`/system_admins/${record.id}`}>
                <EditOutlined
                  className="tableIconsSize"
                  style={componentStyles.editIcon}
                />
              </a>
            </Tooltip>
          </span>
          <span className="paddingLeftRight">
            <Tooltip placement="top" title={commonStrings.email}>
              <MailOutlined
                onClick={() => {
                  sendSingleEmail(record)
                  showModal()
                }}
                className="tableIconsSize m5"
                style={componentStyles.mailIcon}
              />
            </Tooltip>
          </span>
          <span className="paddingLeftRight">
            {record.active === true ? (
              <Tooltip placement="top" title={commonStrings.inActive}>
                <UserDeleteOutlined
                  onClick={() => {
                    activateDeactivateSingleUser(record.id, "deactivate")
                  }}
                  className="tableIconsSize"
                  style={componentStyles.deleteIcon}
                />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={commonStrings.active}>
                <UserAddOutlined
                  onClick={() => {
                    activateDeactivateSingleUser(record.id, "activate")
                  }}
                  className="tableIconsSize"
                  style={componentStyles.activeIcon}
                />
              </Tooltip>
            )}
          </span>
        </>
      ),
      width: 100,
    },
  ]

  const tableScrollAxis = { x: 500, y: 600 }
  const commonClassNames = `dropdown-item onHoverCursor ${
    (!isUserSelected || buttonBatchAction) && "disabled"
  }`
  const handleAction = (role, isProgram, isRemove) => {
    if (isIdenticalRoles(userRoles) === (isProgram ? "program" : "school")) {
      setOkButtonText(
        isRemove ? commonStrings.removeButtonText : commonStrings.addButtonText,
      )
      setModalTitle(isProgram ? "Programs" : "Schools")
      setIsAssignRemoveAction(true)
      setIsProgram(isProgram)
      showModal()
    } else {
      errorAlert({
        text: commonStrings.messageBatchActionRole,
      })
    }
  }

  const isUserListNull = (list) => {
    if (_.isEmpty(list)) {
      setIsBatchActionDisabled(true)
    } else {
      setIsBatchActionDisabled(false)
    }
  }

  useEffect(() => {
    isUserListNull(userListValue)
  }, [userListValue])

  useEffect(() => {
    if (isEmailSendToAllUsers) {
      getAllEmailsFromListingObject()
    }
  }, [isEmailSendToAllUsers])

  useEffect(() => {
    clearFilters()
    initialApiCallForList()
  }, [hideFilters])

  useEffect(() => {
    const value = keyHash.some((e) => e.value)
    if (!handleSearch && !value) {
      initialApiCallForList()
    }
    return () => {
      setFlag(false)
    }
  }, [flag, handleSearch])

  useEffect(() => {
    initialApiCallForList()
  }, [responseMessage])

  useEffect(() => {
    filterLogicFunction()
    return () => {
      setHandleSearch(false)
    }
  }, [handleSearch, flag, parentTableData])

  useEffect(() => {
    emailSetFunction()
  }, [emails])

  useEffect(() => {
    firstLastNameSetFunction()
  }, [valuesForFilters])
  useEffect(() => {
    updateSelectedState(selectedRowKeys)
  }, [selectedRowKeys])

  useEffect(() => {
    form.resetFields()
    setFiltersData([])
  }, [isToggled])

  useEffect(() => {
    getThirdAssociationUsersData()
  }, [searchNew])

  return (
    <div className="email-form-container">
      {contextHolder}
      <Modal
        confirmLoading={sendEmailDisabled || isLoading}
        centered
        width={670}
        destroyOnClose
        title={<span style={{ fontWeight: "600" }}>{modalTitle}</span>}
        open={isModalOpen}
        onCancel={handleCancel}
        closable={false}
        okButtonProps={{
          style: componentStyles.okButton,
        }}
        cancelButtonProps={{
          style: componentStyles.cancelButton,
        }}
        okText={okButtonText}
        onOk={() => {
          if (isAssignRemoveAction && !isLoading) {
            onSubmitAssignRemoveAction()
          } else if (!sendEmailDisabled) {
            onSubmitModal()
          }
        }}
      >
        {isAssignRemoveAction ? (
          <>
            <Form layout="horizontal" form={formAssign}>
              {isProgram ? (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <span>Select {commonStrings.programs}</span>
                  <Form.Item
                    rules={rules.selectModeMultipleProgram}
                    name="program"
                  >
                    <Select
                      disabled={isLoading}
                      allowClear
                      showSearch
                      style={componentStyles.selectStyleSystemAdmins}
                      bordered={false}
                      placeholder={commonStrings.programs}
                      optionFilterProp="children"
                      mode="multiple"
                      onChange={(val) => handleAssignRemoveValue("programs", val)}
                    >
                      {adminResources.map((element) => (
                        <Option value={element.id}>{element.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <span>Select {commonStrings.schools}</span>
                  <Form.Item
                    rules={rules.selectModeMultipleSchool}
                    name="school"
                  >
                    <Select
                      disabled={isLoading}
                      allowClear
                      showSearch
                      style={componentStyles.selectStyleSystemAdmins}
                      bordered={false}
                      placeholder={commonStrings.schools}
                      optionFilterProp="children"
                      mode="multiple"
                      onChange={(val) => handleAssignRemoveValue("schools", val)}
                    >
                      {adminSchools.map((element) => (
                        <Option value={element.id}>{element.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Form>
          </>
        ) : (
          <Form
            className="form-email-send"
            layout="horizontal"
            form={formModal}
          >
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              name="to"
              label={commonStrings.to}
            >
              <Select
                mode="multiple"
                name="email"
                defaultValue={!isEmailSendToAllUsers ? emails : "All Users"}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              name="subject"
              label={commonStrings.subject}
              rules={rules.inputRequired}
              className="text-area"
            >
              <Input
                disabled={sendEmailDisabled}
                style={componentStyles.inputStyleNew}
                name="subject"
                type="text"
                onChange={(value) => handleChangeEmail(value)}
              />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              name="message"
              label={commonStrings.message}
              className="text-area"
              rules={rules.inputRequired}
            >
              <TextArea
                disabled={sendEmailDisabled}
                name="message"
                showCount
                onChange={(value) => handleChangeEmail(value)}
                style={{
                  height: 120,
                  resize: "none",
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
            <Attachment
              CreateLinkObject={CreateLinkObject}
              linkObject={linkObject}
              linkPreview={linkPreview}
              setLinkPreview={setLinkPreview}
              setLinkObject={setLinkObject}
              attachmentForm={attachmentForm}
            />
          </Form>
        )}
      </Modal>
      <Card>
        <div style={componentStyles.flexEnd}>
          <div className="d-flex align-items-center">
            <div className="dropdown ">
              <button
                className={`btn btn-secondary dropdown-toggle btnUpload ${
                  isBatchActionDisabled && "disabledButton"
                }`}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={isBatchActionDisabled}
              >
                {commonStrings.batchAction}
              </button>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <span
                  onClick={() => {
                    if (isUserSelected) {
                      activateDeactivateMultipleUsers("activate_users")
                    }
                  }}
                  className={`dropdown-item onHoverCursor activeInactiveIcons paddingY0 ${
                    (!isUserSelected || buttonBatchAction) && "disabled"
                  }`}
                >
                  <span className="iconStyles ">
                    <i className="fa-solid fa-toggle-on" />
                  </span>
                  {commonStrings.activateUsers}
                </span>

                <span
                  onClick={() => {
                    if (isUserSelected) {
                      activateDeactivateMultipleUsers("deactivate_users")
                    }
                  }}
                  className={`dropdown-item onHoverCursor activeInactiveIcons paddingY0 ${
                    (!isUserSelected || buttonBatchAction) && "disabled"
                  }`}
                >
                  <span className="iconStyles ">
                    <i className="fa-solid fa-toggle-off" />
                  </span>
                  {commonStrings.deactivateUsers}
                </span>

                <span
                  onClick={() => {
                    if (isUserSelected) {
                      resendInvitation(
                        adminUser && adminUser.id ? adminUser.id : "",
                      )
                    }
                  }}
                  className={`dropdown-item onHoverCursor activeInactiveIcons paddingY0 ${
                    (!isUserSelected || buttonBatchAction) && "disabled"
                  }`}
                >
                  <span className="iconStyles ">
                    <i className="fa-solid fa-envelope" />
                  </span>
                  {commonStrings.resendInvitation}
                </span>

                {isToggled
                  && (adminUser && adminUser.role === "program admin" ? (
                    <>
                      <span
                        onClick={() => handleAction(
                          adminUser && adminUser.role ? adminUser.role : "",
                          true,
                          false,
                        )}
                        className={commonClassNames}
                      >
                        <i className="fa-solid fa-plus mx-2" />
                        {commonStrings.addProgramUser}
                      </span>

                      <span
                        onClick={() => handleAction(
                          adminUser && adminUser.role ? adminUser.role : "",
                          false,
                          false,
                        )}
                        className={commonClassNames}
                      >
                        <i className="fa-solid fa-plus mx-2" />
                        {commonStrings.addSchoolUser}
                      </span>

                      <span
                        onClick={() => handleAction(
                          adminUser && adminUser.role ? adminUser.role : "",
                          true,
                          true,
                        )}
                        className={commonClassNames}
                      >
                        <i className="fa-solid fa-minus mx-2" />
                        {commonStrings.removeProgramUser}
                      </span>

                      <span
                        onClick={() => handleAction(
                          adminUser && adminUser.role ? adminUser.role : "",
                          false,
                          true,
                        )}
                        className={commonClassNames}
                      >
                        <i className="fa-solid fa-minus mx-2" />
                        {commonStrings.removeSchoolUser}
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        onClick={() => handleAction(
                          adminUser && adminUser.role ? adminUser.role : "",
                          false,
                          true,
                        )}
                        className={commonClassNames}
                      >
                        <i className="fa-solid fa-minus mx-2" />
                        {commonStrings.removeSchoolUser}
                      </span>
                      <span
                        onClick={() => handleAction(
                          adminUser && adminUser.role ? adminUser.role : "",
                          false,
                          false,
                        )}
                        className={commonClassNames}
                      >
                        <i className="fa-solid fa-plus mx-2" />
                        {commonStrings.addSchoolUser}
                      </span>
                    </>
                  ))}

                <span
                  onClick={() => {
                    if (!isBulkEmailDisabled) {
                      setModalTitle("Send Email")
                      setOkButtonText(commonStrings.sendButtonText)
                      setIsEmailSendToAllUsers(false)
                      showModal()
                    }
                  }}
                  className={`dropdown-item onHoverCursor ${
                    isBulkEmailDisabled && "disabled"
                  }`}
                >
                  <i className="fa-sharp fa-solid fa-envelopes-bulk mx-2" />
                  {commonStrings.bulkEmail}
                </span>
                {isToggled && (
                  <span
                    onClick={() => {
                      setModalTitle("Send Email")
                      setOkButtonText(commonStrings.sendButtonText)
                      setInputUserEmailValues()
                      showModal()
                      setIsEmailSendToAllUsers(true)
                    }}
                    className="dropdown-item onHoverCursor "
                  >
                    <i className="fa-sharp fa-solid fa-envelopes-bulk mx-2" />
                    {commonStrings.emailAllUsers}
                  </span>
                )}
              </div>
            </div>
            {adminUser && adminUser.role === "program admin" && hideFilters && (
              <div className="d-flex mx-2 switch">
                <div>
                  <span className="toggleFilters">
                    {commonStrings.changeFilterType}:&nbsp;
                  </span>
                </div>
                <div>
                  <Switch
                    defaultChecked={false}
                    size="small"
                    handleBg="#3FA4A5"
                    onChange={() => {
                      setIsToggled(!isToggled)
                      if (!isToggled) {
                        setSearchNew({ school: 0, program: 0, role: "" })
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="displayFlex">
            <span className="marginRight">
              <a href="/system_admins/new">
                <Button className="btnAddNewUser">
                  {commonStrings.addNewUser}
                </Button>
              </a>
            </span>
            <span>
              <Button
                onClick={() => {
                  if (hideFilters) {
                    setIsToggled(true)
                  }
                  setHideFilters(!hideFilters)
                }}
                className="btnSearch"
              >
                {commonStrings.search} <SearchOutlined />
              </Button>
            </span>
          </div>
        </div>

        <Divider />
        {hideFilters
          && (isToggled ? (
            <>
              <Form form={form}>
                <Row gutter={16}>
                  <div className="d-flex justify-content-between width100">
                    <Col xs={24} sm={24} md={mdCol} lg={mdCol}>
                      <Form.Item name="first_name">
                        <Select
                          allowClear
                          showSearch
                          style={componentStyles.selectStyleSystemAdmins}
                          bordered={false}
                          placeholder={commonStrings.firstName}
                          optionFilterProp="children"
                          onChange={(val) => handleChange("first_name", val)}
                        >
                          {uniqueFirstName
                            && uniqueFirstName.map((element) => (
                              <Option value={element}>{element}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={mdCol} lg={mdCol}>
                      <Form.Item name="last_name">
                        <Select
                          allowClear
                          showSearch
                          style={componentStyles.selectStyleSystemAdmins}
                          bordered={false}
                          placeholder={commonStrings.lastName}
                          optionFilterProp="children"
                          onChange={(val) => handleChange("last_name", val)}
                        >
                          {uniqueLastName
                            && uniqueLastName.map((element) => (
                              <Option value={element}>{element}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={mdCol} lg={mdCol}>
                      <Form.Item name="school">
                        <Select
                          allowClear
                          showSearch
                          style={componentStyles.selectStyleSystemAdmins}
                          bordered={false}
                          placeholder={commonStrings.schools}
                          optionFilterProp="children"
                          onChange={(val) => handleChange("school", val)}
                        >
                          {adminSchools.map((element) => (
                            <Option value={element.name}>{element.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={mdCol} lg={mdCol}>
                      <Form.Item name="active">
                        <Select
                          allowClear
                          showSearch
                          style={componentStyles.selectStyleSystemAdmins}
                          bordered={false}
                          placeholder={commonStrings.status}
                          optionFilterProp="children"
                          onChange={(val) => handleChange("active", val)}
                        >
                          <Option value="true">{commonStrings.yes}</Option>
                          <Option value="false">{commonStrings.no}</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={mdCol} lg={mdCol}>
                      <Form.Item name="role">
                        <Select
                          allowClear
                          showSearch
                          style={componentStyles.selectStyleSystemAdmins}
                          bordered={false}
                          placeholder={commonStrings.role}
                          optionFilterProp="children"
                          onChange={(val) => handleChange("role", val)}
                        >
                          {adminUser && adminUser.role === "program admin" ? (
                            <>
                              <Option value="program user">
                                {commonStrings.programUser}
                              </Option>
                              <Option value="school user">
                                {commonStrings.schoolUser}
                              </Option>
                              <Option value="program admin">
                                {commonStrings.programAdmin}
                              </Option>
                              <Option value="school admin">
                                {commonStrings.schoolAdmin}
                              </Option>
                            </>
                          ) : (
                            <>
                              <Option value="school admin">
                                {commonStrings.schoolAdmin}
                              </Option>
                              <Option value="school user">
                                {commonStrings.schoolUser}
                              </Option>
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    {adminUser && adminUser.role === "program admin" && (
                      <>
                        <Col xs={24} sm={24} md={mdCol} lg={mdCol}>
                          <Form.Item name="program">
                            <Select
                              allowClear
                              showSearch
                              style={componentStyles.selectStyleSystemAdmins}
                              bordered={false}
                              placeholder={commonStrings.programs}
                              optionFilterProp="children"
                              onChange={(val) => handleChange("program", val)}
                            >
                              {adminResources.map((element) => (
                                <Option value={element.name}>
                                  {element.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col xs={24} sm={24} md={4} lg={5}>
                      <Button
                        style={
                          !(
                            search.first_name
                            || search.last_name
                            || search.active
                            || search.school
                            || search.program
                            || search.role
                          )
                            ? componentStyles.searchEnabledButton
                            : componentStyles.searchButton
                        }
                        onClick={() => {
                          setHandleSearch(true)
                        }}
                        block
                      >
                        {commonStrings.search}
                      </Button>
                    </Col>
                  </div>
                </Row>
              </Form>
              <Divider />
            </>
          ) : (
            <>
              <Form form={form}>
                <Row gutter={16}>
                  {adminUser && adminUser.role === "program admin" && (
                    <>
                      <Col xs={24} sm={24} md={9} lg={9}>
                        <Form.Item name="program_new">
                          <Select
                            key={searchNew.program}
                            allowClear
                            showSearch
                            style={componentStyles.selectStyleSystemAdmins}
                            bordered={false}
                            placeholder={commonStrings.programs}
                            optionFilterProp="children"
                            onChange={(val) => {
                              handleChangeNew("program", val)
                            }}
                          >
                            {currentUserPrograms.map((element) => (
                              <Option value={element.id}>{element.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  <Col xs={24} sm={24} md={9} lg={9}>
                    <Form.Item name="school_new">
                      <Select
                        disabled={!searchNew.program}
                        allowClear
                        showSearch
                        style={componentStyles.selectStyleSystemAdmins}
                        bordered={false}
                        placeholder={commonStrings.schools}
                        optionFilterProp="children"
                        onChange={(val) => handleChangeNew("school", val)}
                      >
                        {schoolFilterValues.map((element) => (
                          <Option value={element.id}>{element.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={4}>
                    <Form.Item name="role_new">
                      <Select
                        disabled={!searchNew.program}
                        key={searchNew.role}
                        allowClear
                        showSearch
                        style={componentStyles.selectStyleSystemAdmins}
                        bordered={false}
                        placeholder={commonStrings.role}
                        optionFilterProp="children"
                        onChange={(val) => handleChangeNew("role", val)}
                      >
                        {adminUser && adminUser.role === "program admin" ? (
                          <>
                            <Option value="program user">
                              {commonStrings.programUser}
                            </Option>
                            <Option value="school user">
                              {commonStrings.schoolUser}
                            </Option>
                            <Option value="program admin">
                              {commonStrings.programAdmin}
                            </Option>
                            <Option value="school admin">
                              {commonStrings.schoolAdmin}
                            </Option>
                          </>
                        ) : (
                          <>
                            <Option value="school admin">
                              {commonStrings.schoolAdmin}
                            </Option>
                            <Option value="school user">
                              {commonStrings.schoolUser}
                            </Option>
                          </>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Divider />
            </>
          ))}
        <span className="table-design">
          <Table
            key={keyTable}
            className="table-striped-rows"
            rowSelection={rowSelection}
            columns={isToggled ? columns : columnsNew}
            dataSource={isToggled ? userListValue : filtersData}
            bordered
            size="middle"
            scroll={tableScrollAxis}
            loading={loadingFilterData}
          />
        </span>
      </Card>
    </div>
  )
}

export default Index
