import React from "react"

import videoSvgGrey from "../../assets/images/video-square-grey.svg"
import videoSvgBlack from "../../assets/images/video-square-black.svg"
import CommonColors from "../commonColors"
import componentStyles from "../styles"

const LatestReleaseTab = ({
  title, count, isSelected, setIsSelected, id,
}) => {
  const selectedButtonColor = {
    backgroundColor: CommonColors.UA_RED,
  }

  const unSelectedColor = {
    backgroundColor: CommonColors.UA_GREY,
  }

  return (
    <div
      style={{
        ...componentStyles.buttonsStyles,
        ...(isSelected === id ? selectedButtonColor : unSelectedColor),
      }}
      className="mx-1"
      onClick={() => {
        setIsSelected(id)
      }}
    >
      <div className="d-flex align-items-center">
        <div>
          <img
            className="mr-1 video-play-size"
            src={isSelected === id ? videoSvgGrey : videoSvgBlack}
            alt="Video Icon"
          />
        </div>
        <div
          style={{
            color:
              isSelected === id
                ? CommonColors.UA_WHITE
                : CommonColors.UA_BLUE_DARK,
            marginTop: "2px",
          }}
        >
          {`${title} (${count})`}
        </div>
      </div>
    </div>
  )
}

export default LatestReleaseTab
