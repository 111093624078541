import React from "react"
import { Line } from "react-chartjs-2"
import "chartjs-adapter-moment"

function LineChart({ labels = [], datasets = [] }) {
  const data = {
    labels,
    datasets,
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: "time",
        ticks: {
          source: "data",
        },
      },
    },
  }

  return (
    <div className="mb-3">
      <Line data={data} options={options} />
    </div>
  )
}

export default LineChart
