import React from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer"
import _ from "underscore"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import uaLogo from "../../../assets/images/rsp-logo.png"
import commonStrings from "../../commonStrings"

const ActivityDetailsPDF = ({
  items,
  categories,
  currentschoolLogo,
  filters,
  filtersApplied,
  selectedDessaFilters,
}) => {
  Font.register({
    family: "Archivo Narrow",
    fonts: [
      {
        src: archivoNarrow,
      },
      {
        src: archivoNarrowBold,
        fontWeight: 700,
      },
    ],
  })
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Archivo Narrow",
      padding: 30,
    },
    logo: {
      width: 100,
      height: 50,
    },
    evenPage: {
      backgroundColor: "#f5f5f5",
    },
    oddPage: {
      backgroundColor: "#e0e2e3",
    },
    uaLogo: {
      width: 115,
      height: 64,
    },
    uaLogo1: {
      width: 115,
      height: 64,
    },
    reportContainer: {
      display: "flex",
      flexDirection: "column",
      border: "1px solid #e0e2e3",
      marginBottom: 10,
      marginTop: 30,
      backgroundColor: "#f5f5f5",
      // fontWeight: "bold",
    },
    reportHeader: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
      alignItems: "stretch",
      color: "#000000",
      fontWeight: "bold",
    },
    activityName: {
      fontSize: 17,
      // fontWeight: "bold",
      marginBottom: 2,
    },

    innerItemStandards: {
      display: "flex",
      flexDirection: "row",
      fontSize: 13,
      // fontWeight: "bold",
    },
    innerListOfItemStandards: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: 25,
      fontSize: 11,
    },
    activityDescriptionParentDiv: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    activityDescription: {
      fontSize: 12,
      // fontWeight: "bold",
      width: "75%",
    },
    reportCategories: {
      fontSize: 11,
      // fontWeight: "bold",
      width: "20%",
      textAlign: "left",
    },
    boldText: {
      display: "flex",
      flexDirection: "row",
      fontSize: 11,
      // fontWeight: "bold",
      textAlign: "left",
    },
    reportDetails: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      paddingLeft: 6,
      color: "#000000",
      backgroundColor: "#f5f5f5",
      width: "100%",
      // fontWeight: "bold",
    },

    tierDomainTime: {
      display: "flex",
      width: "50%",
      flexDirection: "row",
      justifyContent: "space-evenly",
      // fontWeight: "bold",
    },
    reportSection: {
      borderRight: "1px solid black",
      width: "33.33%",
      display: "flex",
      justifyContent: "flex-start",
      // fontWeight: "bold",
    },
    tierTypeTitle: {
      fontSize: 13,
      textDecoration: "underline",
      textAlign: "left",
      // fontWeight: "bold",
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
    },
    tierTypeTitleTime: {
      fontSize: 13,
      textDecoration: "underline",
      textAlign: "left",
      // fontWeight: "bold",
      paddingLeft: 8,
      paddingTop: 4,
    },
    tierTypeTitleData: {
      fontSize: 13,
      textDecoration: "underline",
      textAlign: "left",
      // fontWeight: "bold",
      paddingLeft: 8,
      paddingRight: 8,
      width: "100%",
    },
    tierOfSupportType: {
      fontSize: 10,
      // fontWeight: "bold",
      paddingTop: 10,
      textAlign: "left",
      paddingLeft: 8,
    },
    reportSectionDataCollected: {
      width: "50%",
      padding: 4,
    },

    activityDetailsReportContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      // fontWeight: "bold",
      fontFamily: "Archivo Narrow",
    },
    promotesSelBody: {
      width: "100%",
      color: "#000000",
      // fontWeight: "bold",
    },

    selStandardsBody: {
      width: "100%",
      fontSize: 11,
      // fontWeight: "bold",
      paddingBottom: 2,
      paddingTop: 5,
      paddingLeft: 21,
      paddingRight: 21,
    },
    reportReinforces: {
      width: "99%",
      borderLeft: "1px dotted black",
      color: "#000000",
      // fontWeight: "bold",
    },
    reportPromotesSel: {
      fontSize: 14,
      // fontWeight: "bold",
      textDecoration: "underline",
      paddingLeft: 20,
      paddingBottom: 2,
      paddingTop: 5,
      paddingRight: 5,
    },
    reportSelReinforces: {
      fontSize: 14,
      // fontWeight: "bold",
      paddingLeft: 20,
      textDecoration: "underline",
      paddingBottom: 2,
      paddingTop: 5,
      paddingRight: 5,
    },
    reinforcesList: {
      width: "100%",
      fontSize: 11,
      // fontWeight: "bold",
      paddingLeft: 21,
      paddingRight: 21,
      paddingBottom: 2,
    },
    noData: {
      fontSize: 18,
      textAlign: "center",
    },
    imageContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // justifyContent: "space-between",
    },
    mainGradeLevel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderTop: "1px solid #7e7c7d",
      padding: "6px",
      color: "#000000",
      marginBottom: "6px",
    },
    gradeLevelTitle: {
      fontSize: 13,
      fontWeight: 800,
      display: "flex",
      textDecoration: "underline",
      paddingLeft: "5px",
    },

    grades: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingTop: 5,
      paddingBottom: 5,
      maxWidth: "100%",
      paddingLeft: "15px",
    },
    gradeItem: {
      fontSize: 9,
      padding: "3px 10px",
      borderRadius: 6,
      marginRight: 8,
    },
    filterStyle: {
      fontSize: 12,
      fontWeight: 400,
    },
    filterSelected: {
      fontSize: 14,
    },
    filterSelectedtitle: {
      fontSize: 15,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontWeight: "bold",
    },
    reportContainerss: {
      display: "flex",
      flexDirection: "column",
    },
  })

  const filteredItems = items.filter(
    (item) => item.activity && item.description,
  )
  const standards = (key) => {
    const hash = {}
    _.pluck(window.gon.SEL_STANDARDS, "standards").forEach((array) => {
      _.extend(hash, array)
    })
    return hash[key]
  }

  const ItemStandard = ({ keyName, type, standard }) => (
    <View key={keyName} style={styles.selStandardItem}>
      <View style={styles.innerItemStandards}>
        <Text style={styles.boldText}>
          {keyName}:{" "}
          {type === "student"
            ? standards(keyName).student_indicator_language
            : standards(keyName).adult_indicator_language}
        </Text>
      </View>
      <View style={styles.innerListOfItemStandards}>
        <Text>• {standard.connection_explanation}</Text>
      </View>
    </View>
  )
  const nonEmptyCategories = categories.filter(
    (categoryArray) => categoryArray.length > 0,
  )
  const combinedData = filteredItems.map((item, index) => ({
    ...item,
    categories: nonEmptyCategories[index] || [],
  }))

  const renderSelectedFilters = () => {
    const selectedFilters = []

    if (filters.categories.length > 0) {
      selectedFilters.push({
        label: "Domains:",
        value: filters.categories.join(", "),
      })
    }
    if (filters.tiers_of_support.length > 0) {
      selectedFilters.push({
        label: "Tiers of Support:",
        value: filters.tiers_of_support.join(", "),
      })
    }
    if (filters.standards.length > 0) {
      selectedFilters.push({
        label: "Indicators:",
        value: filters.standards.join(", "),
      })
    }

    if (filters.internal_selected_grades.length > 0) {
      selectedFilters.push({
        label: "Grade Levels:",
        value: filters.internal_selected_grades.join(", "),
      })
    }
    if (filters.dessa_competencies.length > 0) {
      selectedFilters.push({
        label: "DESSA Competencies:",
        value: selectedDessaFilters.join(", "),
      })
    }
    if (selectedFilters.length === 0) {
      return null
    }
    return selectedFilters.map((filter, index) => (
      <Text key={index}>
        <Text style={{ fontWeight: "bold" }}>{filter.label}</Text>{" "}
        <Text style={styles.filterStyle}> {filter.value}</Text>{" "}
      </Text>
    ))
  }

  return (
    <Document>
      {combinedData.map((item, index) => {
        const isEvenPage = index % 2 === 0
        const pageStyle = isEvenPage ? styles.evenPage : styles.oddPage
        const gradestyle = isEvenPage ? styles.oddPage : styles.evenPage

        return (
          <Page key={item.id} size="A4" style={[styles.page]}>
            {/* <Page size="A4" style={styles.page}> */}
            <View style={styles.imageContainer} fixed>
              <Image style={styles.uaLogo} src={uaLogo} />
              {currentschoolLogo && (
                <Image style={styles.uaLogo1} src={currentschoolLogo} />
              )}
            </View>
            {index === 0 && filtersApplied && (
              <View style={styles.reportContainer}>
                <View>
                  <Text style={styles.filterSelectedtitle}>
                    {commonStrings.pdfFiltertitle}
                  </Text>
                </View>
                <View style={styles.filterSelected}>
                  {renderSelectedFilters()}
                </View>
              </View>
            )}

            {/* </Page> */}
            <View style={[styles.reportContainer, pageStyle]}>
              <View style={styles.reportHeader}>
                <Text style={styles.activityName}>{item.activity}</Text>
                <View style={styles.activityDescriptionParentDiv}>
                  <Text style={styles.activityDescription}>
                    {item.description}
                  </Text>
                  <View style={styles.activityDescriptionParentDiv}>
                    <View style={styles.reportCategories}>
                      {item.categories.length > 0 ? (
                        item.categories.map((category, catIndex) => (
                          <Text
                            key={catIndex}
                            style={{ color: category.color }}
                          >
                            {category.name}
                          </Text>
                        ))
                      ) : (
                        <Text>{commonStrings.noCategory}</Text>
                      )}
                    </View>
                  </View>
                </View>
              </View>
              {item.category !== commonStrings.adultSELQuality
                && item.category !== "Adult SEL"
                && item.internal_selected_grades
                && Array.isArray(item.internal_selected_grades)
                && item.internal_selected_grades.length > 0 && (
                  <View style={styles.mainGradeLevel}>
                    <Text style={styles.gradeLevelTitle}>
                      {commonStrings.gradeLevelTitle}
                    </Text>
                    <View style={styles.grades}>
                      {/* {item.internal_selected_grades
                        && Array.isArray(item.internal_selected_grades)
                        && item.internal_selected_grades.length > 0
                        && item.internal_selected_grades.map((grade, idx) => (
                          <Text
                            key={idx}
                            style={[styles.gradeItem, gradestyle]}
                          >
                            {grade.grade}
                          </Text>
                        ))} */}
                      {item.internal_selected_grades.map((grade, idx) => (grade && grade.grade ? (
                        <Text
                          key={idx}
                          style={[styles.gradeItem, gradestyle]}
                        >
                          {grade.grade}
                        </Text>
                      ) : null))}
                    </View>
                  </View>
              )}

              <View style={[styles.reportDetails, pageStyle]}>
                <View style={styles.tierDomainTime}>
                  <View style={styles.reportSection}>
                    <Text style={styles.tierTypeTitle}>
                      {commonStrings.tierType}
                    </Text>
                    <Text style={styles.tierOfSupportType}>
                      {item.tier_of_support}
                    </Text>
                  </View>
                  <View style={styles.reportSection}>
                    <Text style={styles.tierTypeTitle}>
                      {commonStrings.domain}
                    </Text>
                    <Text style={styles.tierOfSupportType}>
                      {item.category}
                    </Text>
                  </View>
                  <View style={styles.reportSection}>
                    <Text style={styles.tierTypeTitleTime}>
                      {commonStrings.LengthofTime}
                    </Text>
                    <Text style={styles.tierOfSupportType}>
                      {item.length_of_time}
                    </Text>
                  </View>
                </View>
                <View style={styles.reportSectionDataCollected}>
                  <Text style={styles.tierTypeTitleData}>
                    {commonStrings.DataCollected}
                  </Text>
                  <Text
                    style={[
                      styles.tierOfSupportType,
                      { color: item.data_collection ? "#000000" : "#B9B9B9" },
                    ]}
                  >
                    {item.data_collection || commonStrings.noDataAdded}
                  </Text>
                </View>
              </View>
              <View style={styles.activityDetailsReportContent}>
                <View style={styles.promotesSelBody}>
                  <Text style={styles.reportPromotesSel}>
                    How {item.activity} Promotes SEL
                  </Text>
                  <View style={styles.selStandardsBody}>
                    {Object.keys(item.sel_standards)
                      .filter(
                        (key) => item.sel_standards[key].connection_type === "promotes",
                      )
                      .map((key) => (
                        <ItemStandard
                          keyName={key}
                          type={item.adult ? "adult" : "student"}
                          standard={item.sel_standards[key]}
                        />
                      ))}
                  </View>
                </View>
                <View style={styles.reportReinforces}>
                  <Text style={styles.reportSelReinforces}>
                    How {item.activity} Reinforces SEL
                  </Text>
                  <View style={styles.reinforcesList}>
                    {Object.keys(item.sel_standards)
                      .filter(
                        (key) => item.sel_standards[key].connection_type
                          === "reinforces",
                      )
                      .map((key) => (
                        <ItemStandard
                          keyName={key}
                          type={item.adult ? "adult" : "student"}
                          standard={item.sel_standards[key]}
                        />
                      ))}
                  </View>
                </View>
              </View>
            </View>
          </Page>
        )
      })}

      {filteredItems.length === 0 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.reportContainer}>
            <View>
              <Text style={styles.filterSelectedtitle}>
                {commonStrings.pdfFiltertitle}
              </Text>
            </View>
            <View style={styles.filterSelected}>{renderSelectedFilters()}</View>
          </View>
          <Text style={styles.noData}>{commonStrings.NoActivityExists}</Text>
        </Page>
      )}
    </Document>
  )
}

export default ActivityDetailsPDF
