import { Form, InputNumber } from "antd"
import React from "react"
import _ from "underscore"
import componentStyles from "../../styles"

function FormItemInputCellNoCard(props) {
  const handleParser = (value) => {
    const parsedValue = Math.abs(parseFloat(value))
    return _.isNaN(parsedValue) ? null : parsedValue
  }

  const handleFormatter = (value) => (Math.abs(value) === 0 ? null : Math.abs(value))

  const { border = "1px solid #000000" } = props
  const inputStyleInside = {
    ...componentStyles.inputStyleInside,
    border,
  }
  const handleBlur = (e) => {
    const { value } = e.target
    const parsedValue = handleParser(value)
    props.handleChange(parsedValue)
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name={props.name}
      label={props.label}
      rules={props.rules}
      key={props.keys}
      initialValue={props.defaultValues}
      required
    >
      <InputNumber
        key={props.keys}
        required
        formatter={handleFormatter}
        parser={handleParser}
        style={{
          ...inputStyleInside,
          ...(props.disabled ? componentStyles.disabledStyle : {}),
        }}
        name={props.name}
        disabled={props.disabled}
        maxLength={props.maxLength}
        prefix={props.prefix}
        type="number"
        min={props.min || "0"}
        max={props.max}
        onBlur={handleBlur}
        // onBlur={(e) => {
        //   props.handleChange(e.target.value);
        // }}
        controls={false}
      />
    </Form.Item>
  )
}

export default FormItemInputCellNoCard
