import React from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer"
import uaLogo from "../../../assets/images/rsp-logo.png"
import commonStrings from "../../commonStrings"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"

const SchoolPlanPdf = ({
  rubric,
  sortArrayByRating,
  reportHeadingTop,
  removeHtmlTags,
  currentschoolLogo,
}) => {
  Font.register({
    family: "Archivo Narrow",
    fonts: [
      {
        src: archivoNarrow,
      },
      {
        src: archivoNarrowBold,
        fontWeight: 700,
      },
    ],
  })

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Archivo Narrow",

      paddingTop: 10,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    section: {
      // margin: 10,
      // padding: 10,
    },
    uaLogo: {
      width: 115,
      height: 65,
    },
    uaLogo1: {
      width: 115,
      height: 65,
    },
    headingTop: {
      fontSize: 17,
      marginTop: 10,
      fontFamily: "Archivo Narrow",
      fontWeight: 700,
      marginBottom: 15,
    },
    descriptionPlan: {
      fontSize: 14,
      // marginTop: 10,
      marginBottom: 12,
    },
    label: {
      fontSize: 12,
      fontFamily: "Archivo Narrow",
      fontWeight: 700,
    },
    explanation: {
      fontFamily: "Archivo Narrow",
      fontSize: 13,
      lineHeight: "1.5px",
    },
    backgroundColor0: {
      backgroundColor: "#e7f6f6",

    },
    backgroundColor2: {
      backgroundColor: "#92d6d6",
    },
    backgroundColor4: {
      backgroundColor: "#0ca4a5",
    },
    ratingExplanation: {
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 7,
      paddingRight: 7,
      borderBottom: "0.5px solid black",
    },
    imageContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 20,
      marginTop: 15,
    },
  })

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.imageContainer} fixed>
            <Image style={styles.uaLogo} src={uaLogo} />
            {currentschoolLogo && (
              <Image style={styles.uaLogo1} src={currentschoolLogo} />
            )}
          </View>
          <Text style={styles.headingTop} fixed>
            {reportHeadingTop}
          </Text>
          <Text style={styles.descriptionPlan}>{commonStrings.implPlan}</Text>
          {sortArrayByRating(rubric.plan).map((indicator, n) => (
            <View
              key={n}
              style={{
                ...(indicator.rating === 0
                || indicator.rating === 1
                || indicator.rating === null
                  ? styles.backgroundColor0
                  : indicator.rating === 2 || indicator.rating === 3
                    ? styles.backgroundColor2
                    : indicator.rating === 4
                      ? styles.backgroundColor4
                      : {}),
                ...styles.ratingExplanation,
              }}
            >
              <Text style={styles.label}>
                {indicator.index}. {removeHtmlTags(indicator.label)}
              </Text>
              <Text style={styles.explanation}>
                {indicator.explanation
                  ? indicator.explanation.replace(/\t/g, "  ")
                  : null}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )
}

export default SchoolPlanPdf
