import React from "react"
import {
  Modal, Button, Col, Row, Tag,
} from "antd"
import componentStyles from "../styles"

const VideoModal = ({ openStatus, setOpenStatus, clickedVideoObject }) => {
  const {
    name,
    name_section: nameSection,
    youtube_link: youtubeLink,
    description,
    video,
    created_at: createdAt,
  } = clickedVideoObject

  const date = new Date(createdAt && createdAt)
  const options = { year: "numeric", month: "short", day: "numeric" }

  return (
    <Modal
      keyboard
      width={1000}
      title={<div className="noSchoolModal">Video Details</div>}
      open={openStatus}
      closable
      onCancel={() => {
        setOpenStatus(false)
      }}
      destroyOnClose
      footer={[
        <Button
          className="noSchoolModalButton"
          onClick={() => {
            setOpenStatus(false)
          }}
        >
          Close
        </Button>,
      ]}
      centered
    >
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24}>
          {video && (
            <iframe
              title="video"
              width="950"
              height="500"
              src={
                youtubeLink
                  ? `https://www.youtube.com/embed/${new URL(
                    youtubeLink,
                  ).searchParams.get("v")}`
                  : video.url
              }
              frameBorder="0"
              allowFullScreen
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="mt-3">
            <div>
              {nameSection
                && (nameSection.length > 0
                  ? nameSection.map((record, index) => (
                    <Tag
                      style={componentStyles.tagsStyles}
                      key={index}
                      className="my-1"
                    >
                      {record}
                    </Tag>
                  ))
                  : "N/A")}
            </div>
            <div style={componentStyles.titleSize}>
              <span>{name}</span>
            </div>
            <div style={componentStyles.headingSize}>
              <span>{description}</span>
            </div>
            <div>
              <span style={{ cursor: "default" }}>
                {createdAt && date.toLocaleDateString("en-US", options)}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}
export default VideoModal
