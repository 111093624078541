import React from "react"
import commonStrings from "../../commonStrings"

function Artifacts({ area, reportName }) {
  const headingColor = () => {
    const lowerReportName = reportName.toLowerCase()
    if (lowerReportName === "range" || lowerReportName === "coordination") {
      return "#E7A922"
    }
    if (lowerReportName === "program fit/reinvention") {
      return "#0CA4A5"
    }
    return "#A6192E"
  }
  const styleImage = {
    position: "relative",
    left: "8%",
    bottom: "19px",
  }
  const fileList = (files) => {
    const groupedFiles = []
    for (let i = 0; i < files.length; i += 2) {
      groupedFiles.push(files.slice(i, i + 2))
    }
    return (
      <div className="image-list" style={styleImage}>
        {groupedFiles.map((group, groupIndex) => (
          <div
            className="example-artifacts-row"
            key={groupIndex}
          >
            {group.map((file, index) => (
              <div className="column" key={index}>
                <div className="dott">
                  <span className="dot">•</span>
                </div>
                <a
                  href={file.url}
                  target="_blank"
                  className="mb-2 images-description"
                  rel="noreferrer"
                >
                  {file.description || file.filename}
                </a>
                {file.previewable && (
                  <a href={file.url} target="_blank" rel="noreferrer" className="images">
                    <object
                      data={`${file.url}#scrollbar=0&toolbar=0&navpanes=0`}
                      type={file.content_type}
                      width="250px"
                    />
                  </a>
                )}
              </div>

            ))}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="main-container-artifacts">
      <div className="main-artifacts">
        {area.evidence.length > 0 && (
          <div className="heading">
            <span style={{ color: headingColor() }}>
              {commonStrings.artifacts}
            </span>
          </div>
        )}
        {fileList(area.evidence)}
      </div>
    </div>
  )
}

export default Artifacts
