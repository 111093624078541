import React from "react"
import _ from "underscore"

function ImplementationPlan({ rubric }) {
  const generatePDF = () => {
    window.open(`/implementation_rubrics/${rubric.id}/plan.pdf`)
  }

  return (
    <div>
      <i className="fa fa-file-pdf-o" onClick={generatePDF} />
      <div className="plan p-4 mb-4">
        {rubric.plan.map((stage, i) => (
          <div key={i} className="narrative">
            <h4>{stage.label}</h4>
            <ul>
              {stage.indicators.map((indicator, n) => (
                <li key={n} className="mb-5">
                  <h5
                    className={(!_.contains([3, 4], indicator.rating) ? "highlight-yellow" : indicator.rating === 4 ? "highlight-green" : null)}
                  >{indicator.index}. <span
                    dangerouslySetInnerHTML={{ __html: indicator.label }}
                  />
                  </h5>
                  <p className="font-italic ml-5">{indicator.explanation}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImplementationPlan
