import React from "react"
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Text,
} from "@react-pdf/renderer"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import uaLogo from "../../../assets/images/rsp-logo.png"
import commonStrings from "../../commonStrings"

Font.register({
  family: "Archivo Narrow",
  fonts: [{ src: archivoNarrow }, { src: archivoNarrowBold, fontWeight: 700 }],
})

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Archivo Narrow",
    paddingTop: 14,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  uaLogo: {
    width: 115,
    height: 64,
  },
  uaLogo1: {
    width: 115,
    height: 64,
  },
  imageContainers: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  image: {
    maxWidth: "100%",
    height: 250,
  },
  filterStyle: {
    fontSize: 12,
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  filterSelected: {
    fontSize: 14,
  },
  filterSelectedtitle: {
    fontSize: 15,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
  },
  reportContainerss: {
    display: "flex",
    flexDirection: "column",
  },
  filterLabel: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
  },
})
const SELQualitypdf = ({
  graphUrls,
  currentschoolLogo,
  filterSelectedValue,
  filtersApplied,
}) => {
  const isValidImage = (src) => src && src.startsWith("data:image/png;base64,")

  const renderSelectedFilters = () => {
    if (filterSelectedValue) {
      return (
        <Text style={pdfStyles.filterStyle}>
          <Text style={pdfStyles.filterLabel}>
            {commonStrings.gradeLevelTitleFilter}{" "}
          </Text>
          {filterSelectedValue}
        </Text>
      )
    }
    return null
  }

  const renderImages = (imageUrls) => imageUrls
    .filter(isValidImage)
    .map((url, index) => (
      <Image key={index} src={url} style={pdfStyles.image} />
    ))

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.imageContainers} fixed>
          <Image style={pdfStyles.uaLogo} src={uaLogo} />
          {currentschoolLogo && (
            <Image style={pdfStyles.uaLogo1} src={currentschoolLogo} />
          )}
        </View>
        {filtersApplied && (
          <View>
            <Text style={pdfStyles.filterSelectedtitle}>
              {commonStrings.pdfFiltertitle}
            </Text>
            <View style={pdfStyles.filterSelected}>
              {renderSelectedFilters()}
            </View>
          </View>
        )}
        <View style={pdfStyles.imageContainer}>
          {renderImages([graphUrls.graphUrlBase72, graphUrls.graphUrlBase64])}
        </View>
      </Page>

      {/* Additional pages for other graphs */}
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.imageContainer}>
          {renderImages([graphUrls.graphUrlBase65, graphUrls.graphUrlBase66])}
        </View>
      </Page>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.imageContainer}>
          {renderImages([graphUrls.graphUrlBase68, graphUrls.graphUrlBase69])}
        </View>
      </Page>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.imageContainer}>
          {renderImages([graphUrls.graphUrlBase70, graphUrls.graphUrlBase71])}
        </View>
      </Page>
    </Document>
  )
}

export default SELQualitypdf
