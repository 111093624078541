import React from "react"
import { UpCircleOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import ScrollToTop from "react-scroll-to-top"
import { areYouSure } from "../../utilities"
// import BackToTop from "../BackToTop"
import commonStrings from "../../commonStrings"

function RubricFooterButtons({ rubric, type, showListingSubmitted }) {
  const deleteClicked = () => {
    areYouSure("warning", () => {
      APIClient.delete(`/${type.toLowerCase()}_rubrics/${rubric.id}`, () => {
        window.location.href = "/"
      })
    })
  }

  return (
    <div className="d-flex justify-content-between mb-4">
      {rubric.workflow_state === "draft" && !showListingSubmitted && (
        <button className="btn btn-danger btn-sm" onClick={deleteClicked}>
          Delete Rubric
        </button>
      )}

      <ScrollToTop
        style={{ border: "1px solid #7E7C7D", marginBottom: "50px" }}
        component={(
          <Tooltip
            placement="top"
            title={(
              <span style={{ fontWeight: "bolder" }}>
                {commonStrings.scrollerText}
              </span>
            )}
          >
            <div className="animate__animated animate__bounceIn scrollerToTop">
              <UpCircleOutlined />
            </div>
          </Tooltip>
        )}
        smooth
      />
    </div>
  )
}

export default RubricFooterButtons
