import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Button,
  Select,
  Drawer,
  Tooltip,
  Table,
  Space,
  Form,
} from "antd"
import { CSVLink } from "react-csv"
import _ from "underscore"
import Loader from "./Loader"
import commonStrings from "../../commonStrings"
import Gauge from "../Gauge"
import BarCharts from "./Charts/BarCharts"
import "../../../assets/stylesheets/partials/matrixcollaspe.scss"
import { findHighestValue } from "./ProgramDashboardFunctions"

import {
  idIntoSchoolId,
  addMissingProperty,
  removeDuplicates,
  sortArray,
  TruncateText,
} from "../../utilities"

import {
  columnsGaugeAdultSel,
  columnsGaugeStudentSel,
} from "./MatrixGraph/MatrixTableHashes"

import {
  SelCompetency,
  SelMatrix,
  SelDessaGraph,
} from "./MatrixGraph/GraphGenerators"

import {
  convertArrayToObject,
  // convertObjectToArray,
  convertObjectToArrayWithHover,
  transformData,
  makeCsvHeaders,
} from "./MatrixGraph/MatrixFunctions"

const MatrixCollaspe = (props) => {
  const {
    countMatrixHash,
    totalProgramSchool,
    countMatrixHashSelAdult,
    schoolsWithAdultActivity,
    obtained,
    gagugeWith5Activities,
    studentSelmatrixCompetency,
    studentDessaCompetencyCount,
    rawStudentSelMatrixCompetency,
    rawAdultSelActivitiesCount,
    rawstudentDessAcitivities,
    setSchoolSelectedAdultSelActivitiesCount,
    currentProgram,
    programSchoolsForDropDown,
    setSelectedSchool,
    adultSelActivitiesCount,
    dessaCompetencyCount,
    rawDessaGraph,
    DessaStandards,
    Indicators,
    setSelectedIndicatorType,
    indicatorTypes,
    loading,
    studentMatrixTable,
    adultMatrixTable,
    selectedIndicatorType,
    generalColor,
    dashBoardObject,
  } = props
  const [form] = Form.useForm()
  const [createTableList, setCreateTableList] = useState([])
  const [createTableColums, setCreateTableColums] = useState([])
  const [getClick, setGetClick] = useState("")
  const [open, setOpen] = useState(false)
  const [headersCsv, setHeadersCsv] = useState([])
  const [dataCsv, setDataCsv] = useState([])

  const indicatorSelectValues = convertArrayToObject(indicatorTypes)
  const [selectIndicatorType, setSelectIndicatorType] = useState(
    _.first(indicatorSelectValues).value,
  )
  const [indicatorData, setIndicatorData] = useState([])

  useEffect(() => {
    APIClient.get(
      `/indicator_files/find_by_name?name=${selectIndicatorType}`,
      (response) => {
        setIndicatorData(response)
      },
    )
  }, [selectIndicatorType])

  useEffect(() => {
    const isStudentMatrix = getClick === commonStrings.studentSelMatrix
      || getClick === commonStrings.studetMartixIndicator

    const isAdultMatrix = getClick === commonStrings.adultSelMatrix
      || getClick === commonStrings.adultMatrixIndicator

    if (indicatorData.length > 0) {
      if (isStudentMatrix || isAdultMatrix) {
        const indicatorLanguageKey = isStudentMatrix
          ? "student_indicator_language"
          : "adult_indicator_language"

        setHeadersCsv(makeCsvHeaders(Indicators))

        setCreateTableColums(
          convertObjectToArrayWithHover(
            Indicators,
            indicatorData,
            indicatorLanguageKey,
          ),
        )
      }

      if (getClick === commonStrings.dessaGraph) {
        setHeadersCsv(makeCsvHeaders(DessaStandards))
        setCreateTableColums(
          convertObjectToArrayWithHover(
            DessaStandards,
            indicatorData,
            "adult_indicator_language",
          ),
        )
      }

      if (getClick === commonStrings.studentDessaGraph) {
        setHeadersCsv(makeCsvHeaders(DessaStandards))
        setCreateTableColums(
          convertObjectToArrayWithHover(
            DessaStandards,
            indicatorData,
            "student_indicator_language",
          ),
        )
      }
    }

    if (getClick === commonStrings.adultSelMatrix) {
      setDataCsv(
        transformData(
          sortArray(
            removeDuplicates(
              addMissingProperty(idIntoSchoolId(adultMatrixTable)),
            ),
          ),
        ),
      )
      setCreateTableList(
        sortArray(
          removeDuplicates(addMissingProperty(idIntoSchoolId(adultMatrixTable))),
        ),
      )
    }
    if (getClick === commonStrings.studentSelMatrix) {
      setDataCsv(
        transformData(
          sortArray(
            removeDuplicates(
              addMissingProperty(idIntoSchoolId(studentMatrixTable)),
            ),
          ),
        ),
      )

      setCreateTableList(
        sortArray(
          removeDuplicates(
            addMissingProperty(idIntoSchoolId(studentMatrixTable)),
          ),
        ),
      )
    }

    if (getClick === commonStrings.studetMartixIndicator) {
      setDataCsv(
        transformData(
          sortArray(
            removeDuplicates(
              addMissingProperty(idIntoSchoolId(rawStudentSelMatrixCompetency)),
            ),
          ),
          1,
        ),
      )

      setCreateTableList(
        sortArray(
          removeDuplicates(
            addMissingProperty(idIntoSchoolId(rawStudentSelMatrixCompetency)),
          ),
        ),
      )
    }
    if (getClick === commonStrings.dessaGraph) {
      setDataCsv(
        transformData(
          sortArray(
            removeDuplicates(addMissingProperty(idIntoSchoolId(rawDessaGraph))),
          ),
          1,
        ),
      )
      setCreateTableList(
        sortArray(
          removeDuplicates(addMissingProperty(idIntoSchoolId(rawDessaGraph))),
        ),
      )
    }
    if (getClick === commonStrings.studentDessaGraph) {
      setDataCsv(
        transformData(
          sortArray(
            removeDuplicates(
              addMissingProperty(idIntoSchoolId(rawstudentDessAcitivities)),
            ),
          ),
          1,
        ),
      )
      setCreateTableList(
        sortArray(
          removeDuplicates(
            addMissingProperty(idIntoSchoolId(rawstudentDessAcitivities)),
          ),
        ),
      )
    }

    if (getClick === commonStrings.adultMatrixIndicator) {
      setDataCsv(
        transformData(
          sortArray(
            removeDuplicates(
              addMissingProperty(idIntoSchoolId(rawAdultSelActivitiesCount)),
            ),
          ),
          1,
        ),
      )

      setCreateTableList(
        sortArray(
          removeDuplicates(
            addMissingProperty(idIntoSchoolId(rawAdultSelActivitiesCount)),
          ),
        ),
      )
    }
    if (getClick === commonStrings.gaugeInside1) {
      const data = gagugeWith5Activities.map((item) => ({
        "School Name": item.program_matrix.name,
        "Extracurricular Activities":
          item.matrix_hash.activity_counts["Extracurricular Activities"],
        "Social Emotional Supports":
          item.matrix_hash.activity_counts["Social Emotional Supports"],
        "Instructional Practices":
          item.matrix_hash.activity_counts["Instructional Practices"],
        "Behavior Supports":
          item.matrix_hash.activity_counts["Behavior Supports"],
        "Adult SEL": item.matrix_hash.activity_counts["Adult SEL"],
      }))

      setCreateTableColums(columnsGaugeStudentSel)
      setHeadersCsv(columnsGaugeStudentSel.map((col) => col.title))
      setDataCsv(data)
      setCreateTableList(gagugeWith5Activities)
    }

    if (getClick === commonStrings.gaugeInside2) {
      const data = schoolsWithAdultActivity.map((record) => [
        record.program_matrix.name,
        record.matrix_hash.activity_counts["Adult SEL"],
      ])

      setCreateTableColums(columnsGaugeAdultSel)
      setCreateTableList(schoolsWithAdultActivity)
      setHeadersCsv(columnsGaugeAdultSel.map((col) => col.title))
      setDataCsv([...data])
    }
  }, [getClick, open, indicatorData])

  const onClose = () => {
    setOpen(false)
    setCreateTableList([])
    setCreateTableColums([])
    setGetClick("")
  }

  const handleChange = (value) => {
    setSelectedSchool(value)
  }

  const handleChangeAdultCount = (value) => {
    setSchoolSelectedAdultSelActivitiesCount(value)
  }

  const getIndicatorTypeSelection = (value) => {
    form.resetFields()
    setSelectedSchool(0)
    setSchoolSelectedAdultSelActivitiesCount(0)
    setSelectedIndicatorType(value)
    setSelectIndicatorType(value)
  }

  return (
    <Form form={form}>
      <Row justify="space-between" style={{ fontFamily: "Archivo Narrow" }}>
        {open && (
          <div className="schoolSubmissionScores">
            <Drawer
              title={(
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "600",
                    color: "#009F9F",
                  }}
                >
                  {commonStrings.schoolsSubmissionsScores}
                </span>
              )}
              placement="top"
              height="80%"
              closable
              onClose={onClose}
              destroyOnClose
              open={open}
              mask
              maskClosable
              className="schoolSubmissionScores"
              extra={(
                <Space>
                  <CSVLink
                    data={dataCsv}
                    headers={headersCsv}
                    style={{ backgroundColor: "#22AAAC" }}
                    className="csvDownloadBtn"
                    filename="matrix_submissions.csv"
                  >
                    {commonStrings.exportToCsv}
                  </CSVLink>
                </Space>
              )}
            >
              {createTableColums.length > 0 ? (
                <div>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <Table
                        className="table-design table-striped-rows drawer-table"
                        columns={createTableColums}
                        dataSource={createTableList}
                        size="middle"
                        scroll={{ y: 840 }}
                        bordered
                      />
                    </Col>
                  </Row>

                  <div className="closeButtonDiv">
                    <Button
                      className="btnClose"
                      onClick={() => {
                        onClose()
                      }}
                    >
                      {commonStrings.close}
                    </Button>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </Drawer>
          </div>
        )}

        {indicatorSelectValues.length > 1 && (
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="mx-2">
              <span className="indicator-type" style={{ color: generalColor }}>
                {commonStrings.indicatorType}
              </span>
            </div>

            <div className="mx-1 schools matrixSelect justify-content-start d-flex mb-2">
              <Select
                defaultValue={_.first(indicatorSelectValues)}
                placeholder="Select Indicator Type"
                style={{
                  width: 180,
                  borderRadius: "10px",
                }}
                onChange={getIndicatorTypeSelection}
                options={indicatorSelectValues}
              />
            </div>
            <div className="mx-2 mb-4">
              <span className="indicator-instruction">
                {commonStrings.indicatorInstructions}
              </span>
            </div>
          </Col>
        )}
        <Col xs={24} sm={24} md={24} lg={24}>
          <span className="textSel">{commonStrings.studentSEL}</span>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={11}
          lg={11}
          className="displayFlexDirectionColumn boxShadowOnHover marginTopBottom"
          onClick={() => {
            setOpen(true)
            setGetClick(commonStrings.gaugeInside1)
          }}
        >
          <div>
            {!loading ? (
              <Gauge
                textWithoutSubmission="School Without Activities"
                textSubmission="Schools With 5 Activities"
                title="Matrix"
                obtained={obtained}
                total={totalProgramSchool}
              />
            ) : (
              <div className="loader-box">
                <Loader />
              </div>
            )}
          </div>
          <div className="dountText">
            <span>{commonStrings.studentSelContent}</span>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={11}
          lg={11}
          className="boxShadowOnHover marginTopBottom "
          onClick={() => {
            setOpen(true)
            setGetClick(commonStrings.studentSelMatrix)
          }}
        >
          <div>
            {!loading ? (
              <BarCharts
                state={SelCompetency(countMatrixHash)}
                // yMax={42}
                stepSize={1}
                yMax={dashBoardObject.programSchools.length}
                yMin={0}
                BarChartDesc={commonStrings.matrixBarChart1}
                labels="schools"
              />
            ) : (
              <div className="loader-box">
                <Loader />
              </div>
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} className="marginTop40">
          <div className="d-flex">
            <div className="mx-1 schools matrixSelect">
              <Button className="schools-dropdown buttonIcon">
                <Tooltip
                  style={{ borderRadius: "8px" }}
                  placement="top"
                  title={currentProgram.name}
                >
                  {TruncateText(currentProgram.name, 10)}
                </Tooltip>
              </Button>
            </div>
            <div className="mx-1 schools matrixSelect">
              <Form.Item key={indicatorSelectValues} name="student-sel-quality">
                <Select
                  name="student-sel-quality"
                  key={programSchoolsForDropDown}
                  allowClear
                  placeholder="Schools"
                  style={{
                    width: 120,
                    borderRadius: "10px",
                  }}
                  onChange={handleChange}
                  options={programSchoolsForDropDown}
                />
              </Form.Item>
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={11}
          lg={11}
          className="marginTop60 boxShadowOnHover marginTopBottom"
          onClick={() => {
            setOpen(true)
            setGetClick(commonStrings.studetMartixIndicator)
          }}
        >
          <div>
            {!loading ? (
              <BarCharts
                state={SelMatrix(studentSelmatrixCompetency)}
                yMax={findHighestValue(studentSelmatrixCompetency) + 100}
                yMin={0}
                stepSize={10}
                BarChartDesc={commonStrings.matrixBarChart2}
                labels="activities"
              />
            ) : (
              <div className="loader-box">
                <Loader />
              </div>
            )}
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={11}
          lg={11}
          className="marginTop60 boxShadowOnHover marginTopBottom"
          onClick={() => {
            setGetClick(commonStrings.studentDessaGraph)
            setOpen(true)
          }}
        >
          <div>
            {!loading ? (
              <BarCharts
                state={SelDessaGraph(studentDessaCompetencyCount)}
                yMax={findHighestValue(studentDessaCompetencyCount) + 100}
                yMin={0}
                stepSize={10}
                BarChartDesc={commonStrings.countByDessa}
                labels="activities"
              />
            ) : (
              <div className="loader-box">
                <Loader />
              </div>
            )}
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} className="marginTop40">
          <span className="textSel">{commonStrings.adultSEL}</span>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={11}
          lg={11}
          className="displayFlexDirectionColumn boxShadowOnHover marginTopBottom"
          onClick={() => {
            setOpen(true)
            setGetClick(commonStrings.gaugeInside2)
          }}
        >
          <div>
            {!loading ? (
              <Gauge
                textWithoutSubmission="School Without Activities"
                textSubmission="Schools With 5 Activities"
                title="Matrix"
                obtained={
                  schoolsWithAdultActivity.filter(
                    (obj) => obj.matrix_hash.adult_category_have_5_or_more_activities,
                  ).length
                }
                total={totalProgramSchool}
              />
            ) : (
              <div className="loader-box">
                <Loader />
              </div>
            )}
          </div>
          <div className="dountText">
            <span>{commonStrings.adultDomain}</span>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={11}
          lg={11}
          className="boxShadowOnHover marginTopBottom"
          onClick={() => {
            setOpen(true)
            setGetClick(commonStrings.adultSelMatrix)
          }}
        >
          <div>
            {!loading ? (
              <BarCharts
                // yMax={42}
                yMax={dashBoardObject.programSchools.length}
                yMin={0}
                stepSize={1}
                state={SelCompetency(countMatrixHashSelAdult)}
                BarChartDesc={commonStrings.matrixBarChart1}
                labels="schools"
              />
            ) : (
              <div className="loader-box">
                <Loader />
              </div>
            )}
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} className="marginTop40">
          <div className="d-flex">
            <div className="mx-1 schools matrixSelect">
              <Button className="schools-dropdown buttonIcon">
                <Tooltip
                  style={{ borderRadius: "8px" }}
                  placement="top"
                  title={currentProgram.name}
                >
                  {TruncateText(currentProgram.name, 10)}
                </Tooltip>
              </Button>
            </div>

            <div className="mx-1 schools matrixSelect">
              <Form.Item key={indicatorSelectValues} name="adult-sel-quality">
                <Select
                  name="adult-sel-quality"
                  key={programSchoolsForDropDown}
                  allowClear
                  placeholder="Schools"
                  style={{
                    width: 120,
                    borderRadius: "10px",
                  }}
                  onChange={handleChangeAdultCount}
                  options={programSchoolsForDropDown}
                />
              </Form.Item>
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={11}
          lg={11}
          className="marginTop60 boxShadowOnHover marginTopBottom"
          onClick={() => {
            setOpen(true)
            setGetClick(commonStrings.adultMatrixIndicator)
          }}
        >
          <div>
            {!loading ? (
              <BarCharts
                state={SelMatrix(adultSelActivitiesCount)}
                yMax={findHighestValue(adultSelActivitiesCount) + 100}
                yMin={0}
                stepSize={10}
                BarChartDesc={commonStrings.matrixBarChart2}
                labels="activities"
              />
            ) : (
              <div className="loader-box">
                <Loader />
              </div>
            )}
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={11}
          lg={11}
          className="marginTop60 boxShadowOnHover marginTopBottom"
          onClick={() => {
            setGetClick(commonStrings.dessaGraph)
            setOpen(true)
          }}
        >
          <div>
            {!loading ? (
              <BarCharts
                height={280}
                state={SelDessaGraph(dessaCompetencyCount)}
                yMax={findHighestValue(dessaCompetencyCount) + 100}
                yMin={0}
                stepSize={10}
                BarChartDesc={commonStrings.countByDessa}
                labels="activities"
              />
            ) : (
              <div className="loader-box">
                <Loader />
              </div>
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="marginTop60 displayFlexEnd">
            <Button
              onClick={() => {
                window.location.href = `/program_matrix/program_export?indicator_type=${selectedIndicatorType}`
              }}
              className="exportToCSVButton "
            >
              {commonStrings.exportToCsv}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default MatrixCollaspe
