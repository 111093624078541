import React from "react"
import _ from "underscore"
import ResourceFileList from "./FileList"

export default class ResourceGroup extends React.Component {
  coreLanguageGroup(language, group) {
    return (
      <li key={language}>
        {language}
        <ResourceFileList resources={group} />
      </li>
    )
  }

  render() {
    const { resources } = this.props
    const nameGroups = _.groupBy(resources, "name")
    return (
      <div className="resource">
        <p className="description category">{resources[0].category}</p>
        {_.map(nameGroups, function (value, key) {
          const coreLanguageGroups = _.groupBy(_.sortBy(value, "core_language"), "core_language")
          const id = `R-${Math.random().toString(36).substr(2, 10)}`
          return (
            <div key={key}>
              <a data-toggle="collapse" className="dropdown-toggle" href={`#${id}`}>{key}</a>
              <ul className="collapse" id={id}>
                {_.map(coreLanguageGroups, function (group, language) {
                  return this.coreLanguageGroup(language, group)
                }, this)}
              </ul>
            </div>
          )
        }, this)}
      </div>
    )
  }
}
